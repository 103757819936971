import React, { Component } from "react";
import env from "../../../../environment.json";
import Itinerary from "./Itinerary";
import {
  faInfo,
  faList,
  faCheck,
  faChild as faChild2,
  faMapMarkerAlt,
  faImage,
  faUser,
  faCalendarAlt,
  faTag,
  faTimes,
  faSnowboarding,
  faRunning,
  faComments,
  faPencil,
  faHeart,
  faUsers,
  faPlus,
  faClock,
  faMapMarkedAlt,
  faFilm,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faPen, faChild } from "@fortawesome/pro-solid-svg-icons";
import WhatsonNavigation from "../WhatsonNavigation";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import "./NewTour.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SimpleMap, SearchBox } from "../../../../assets/GoogleMaps";
import zipCodes from "../../../../assets/zipCodes.json";
import Verify from "../../../../assets/VerifyMethods";
import { allObjTrue, slugify, pushMedia, countryCodeReturn } from "../../../../assets/helperFunctions";
import loadash, { cloneDeep } from "lodash";
import language from "../language.json";
import Dates from "../../../../assets/Dates/Dates";
import Prices from "../../../../assets/Prices/Prices";
import countryCodes from "../../../../assets/countryCodes.json";
import hikingDifficulty from "../../../../assets/hikingDifficulty.json";
import VfiCustomSelect from "../../../../assets/VfiCustomSelect";
import Goose from "../../../../assets/images/Goose";
import InstagramInWhatson from "../../../../assets/InstagramInWhatson";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  contactDetailsPassed,
  datesPassed,
  descriptionPassed,
  imagesPassed,
  placePassed,
  pricesPassed,
  quickFactsPassed,
  mapPassed,
} from "./TourVerify";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import OneMedia from "../../../../assets/PageModules/OneMedia";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import torshavnRegions from "../TorshavnRegions.json";
import UploadMedia from "../common/media/upload-media/UploadMedia";
import { useSitesStore } from "src/Components/ZustandStores/sitesStore";
import MapSummaryRow from "./MapSummaryRow";

class NewTour extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      uploadedFile: undefined,
      extraVideoLink: [],
      // mapMarker: { lat: 0, lng: 0 },
      mapMarker: undefined,
      mapZoom: 8,
      addMediaDroppedDown: false,
      contactDetails: {
        name: "",
        socialLink: "",
        website: "",
        email: "",
        phoneNumber: "",
        countryCode: "",
      },
      beenEdit: {
        textFo: {
          headline: false,
          description: false,
          shortDescription: false,
          mapSummary: false,
          // qf
          goodToKnow: false,
        },
        textEn: {
          headline: false,
          description: false,
          shortDescription: false,
          mapSummary: false,
          // qf
          goodToKnow: false,
        },
        // Quick facts
        duration: false,
        activities: false,
        difficulty: false,
        minimumAge: false,
        maxGuests: false,
        languages: false,
        highlights: false,
        included: false,
        whatToBring: false,
        itinerary: false,
        // ---
        categories: false,
        dates: false,
        isFree: false,
        images: { mainImages: false },
        instagram: false,
        isPlace: false,
        maxCapacity: false,
        contactDetails: {
          name: false,
          company: false,
          socialLink: false,
          website: false,
          email: false,
          phoneNumber: false,
          countryCode: 298,
        },
      },
      placeIsNotInList: false,
    };

    this.dateChange = this.dateChange.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onMarkerChange = this.onMarkerChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.defaultDragStart = this.defaultDragStart.bind(this);
    this.deleteGpx = this.deleteGpx.bind(this);

    this.headerRef = React.createRef();

    this.axios = require("axios");
    this.user = { firstname: "", lastname: "" };
    this.whatsonSiteId = useSitesStore.getState().whatsonSiteId;

    this.zipCodes = [];
    for (let element in zipCodes) {
      this.zipCodes.push({
        value: zipCodes[element].code,
        label: zipCodes[element].code + " " + zipCodes[element].city,
      });
    }

    this.hours = [];
    for (let i = 0; i < 24; i++) {
      if (i !== 1) this.hours.push({ label: i.toString() + " hours", value: i });
      else this.hours.push({ label: i.toString() + " hour", value: i });
    }

    this.minutes = [];
    this.duration = [
      { label: "1 hour", value: "1 hour" },
      { label: "2 hours", value: "2 hours" },
      { label: "3 hours", value: "3 hours" },
      { label: "4 hours+", value: "4 hours+" },
      { label: "8 hours+", value: "8 hours+" },
      { label: "2 days", value: "2 days" },
      { label: "3 days", value: "3 days" },
      { label: "4 days", value: "4 days" },
      { label: "5 days", value: "5 days" },
      { label: "6 days", value: "6 days" },
      { label: "7 days+", value: "7 days+" },
    ];
    for (let i = 0; i < 60; i += 5) {
      this.minutes.push({ label: i.toString() + " minutes", value: i });
    }

    this.language = language[this.props.language].tours.new;

    this.submit = [];
    this.protocolAndHost = env.protocol + env.env;

    this.newTour = {
      textFo: {
        showIt: true,
        headline: "",
        shortDescription: "",
        mapSummary: "",
        description: "",
        urlSlug: "",
        goodToKnow: "",
      },
      textEn: {
        showIt: true,
        headline: "",
        shortDescription: "",
        mapSummary: "",
        description: "",
        urlSlug: "",
        goodToKnow: "",
      },
      activities: [],
      languages: [{ key: 0, valueEn: "", valueFo: "" }],
      highlights: [{ key: 0, valueEn: "", valueFo: "" }],
      included: [{ key: 0, valueEn: "", valueFo: "" }],
      whatToBring: [{ key: 0, valueEn: "", valueFo: "" }],
      dates: { occurence: { value: "single", label: "Single Date" } },
      isFree: true,
      prices: { prices: [{ price: "", foLabel: "", enLabel: "" }], payUrl: "" },
      itinerary: [],
      // categories: [],
      // openingHours: [],
      // placeRestrictions: {},
      images: { mainImages: [] },
      // urls: { extraVideoLinks: [] },
      address: {},
      contactDetails: {
        name: "",
        company: "",
        socialLink: "",
        website: "",
        email: "",
        phoneNumber: "",
        countryCode: 298,
      },
      isPlace: true,
      instagram: {
        active: false,
        profile: "",
        hashtag: "",
        images: [
          // {...instagramImage,key:0},
          // {...instagramImage,key:1},
          // {...instagramImage,key:2},
        ],
      },
      gpxInfo: [],
      reliveMedium: {},
    };

    this.placeId = 0;

    if (props.updateData !== undefined) {
      const data = this.props.updateData;

      this.setState({ beenEdit: allObjTrue(this.state.beenEdit) });

      let textEn = {};
      let textFo = {};

      if (data.text) {
        Object.entries(data.text).forEach((entry) => {
          const [key, value] = entry;
          if (key === "english") {
            textEn = {
              headline: value.headline,
              shortDescription: value.excerpt,
              mapSummary: value.mapSummary,
              description: value.description,
              urlSlug: value.url_slug,
              showIt: value.show_it === 1 ? true : false,
              goodToKnow: value.good_to_know,
            };
          }
          if (key === "faroese") {
            textFo = {
              headline: value.headline,
              shortDescription: value.excerpt,
              mapSummary: value.mapSummary,
              description: value.description,
              urlSlug: value.url_slug,
              showIt: value.show_it === 1 ? true : false,
              goodToKnow: value.good_to_know,
            };
          }
        });
      }

      this.state = {
        ...this.state,
        hours: {
          ...this.hours.find((e) => e.value === parseInt(data.data.duration / 60)),
        },
        minutes: {
          ...this.minutes.find((e) => e.value === data.data.duration % 60),
        },
      };
      const duration = data.data.duration;
      let activities = [];
      data.activities.forEach((element) => {
        activities.push({
          label: element[this.props.language],
          value: element.id,
        });
      });

      const difficulty = hikingDifficulty[this.props.language].find((e) => e.value === data.data.difficulty);
      const minimumAge = data.data.min_age;

      let languages = new Array(data.text.english.language.length);
      let highlights = new Array(data.text.english.highlight.length);
      let included = new Array(data.text.english.included.length);
      let whatToBring = new Array(data.text.english.what_to_bring.length);

      for (let i = 0; i < languages.length; i++)
        languages[i] = {
          key: i,
          valueEn: data.text.english.language[i],
          valueFo: data.text.faroese.language[i],
        };

      for (let i = 0; i < highlights.length; i++)
        highlights[i] = {
          key: i,
          valueEn: data.text.english.highlight[i],
          valueFo: data.text.faroese.highlight[i],
        };

      for (let i = 0; i < included.length; i++)
        included[i] = {
          key: i,
          valueEn: data.text.english.included[i],
          valueFo: data.text.faroese.included[i],
        };

      for (let i = 0; i < whatToBring.length; i++)
        whatToBring[i] = {
          key: i,
          valueEn: data.text.english.what_to_bring[i],
          valueFo: data.text.faroese.what_to_bring[i],
        };

      //languages: [{ key: 0, valueEn: "", valueFo: "" }],

      const dateOccurence = data.date.occurence;
      let dates = {};
      switch (dateOccurence) {
        case "single":
          dates = {
            occurence: { label: "Single Date", value: dateOccurence },
            startDate: new Date(data.date.dates[0].start_date),
            startTime: data.date.dates[0].start_time,
            endTime: data.date.dates[0].end_time,
            dateVisible: new Date(data.date.when_visible),
          };
          break;
        case "many":
          dates = {
            occurence: { label: "Many Dates", value: dateOccurence },
            startDate: new Date(data.date.dates[0].start_date),
            endDate: new Date(data.date.dates[0].end_date),
            startTime: data.date.dates[0].start_time,
            endTime: data.date.dates[0].end_time,
            days: {
              monday: data.date.monday,
              tuesday: data.date.tuesday,
              wednesday: data.date.wednesday,
              thursday: data.date.thursday,
              friday: data.date.friday,
              saturday: data.date.saturday,
              sunday: data.date.sunday,
            },
            dateVisible: new Date(data.date.when_visible),
          };
          break;
        case "specific":
          let specificDates = [];

          data.date.dates.forEach((element, i) => {
            specificDates.push({
              key: i + 1,
              date: new Date(element.start_date),
              startTime: element.start_time,
              endTime: element.end_time,
            });
          });

          dates = {
            occurence: { label: "Multiple Dates", value: dateOccurence },
            dateVisible: new Date(data.date.when_visible),
            specificDates,
          };
          break;
        default:
          break;
      }

      let prices = [];

      data.prices.forEach((element, i) => {
        prices.push({
          key: i + 1,
          price: element.price,
          foLabel: element.fo_label,
          enLabel: element.en_label,
        });
      });

      let mainImages = [];

      Object.entries(data.images).forEach((entry) => {
        const [key, value] = entry;

        let imgKey = 0;
        if (key === "main") {
          value.forEach((e) => {
            mainImages.push({
              key: imgKey,
              id: e.id_media,
              alt: e.alt,
              file_name: e.file_name,
              file_extension: e.file_extension,
              enText: e.english ? e.english.text : "",
              foText: e.faroese ? e.faroese.text : "",
            });
            imgKey++;
          });
        }
      });

      this.placeId = data.data.id_place;

      const company = data.data.contact_company;
      const name = data.data.contact_name;
      const socialLink = data.data.contact_social;
      const website = data.data.contact_website;
      const email = data.data.contact_email;
      const phoneNumber = data.data.contact_phone;
      let countryCode = data.data.contact_zip;
      // Object.entries(zipCodes).forEach((entry) => {
      //   const [key, value] = entry;

      //   if (value.code === parseInt(data.data.contact_zip)) {
      //     countryCode = {
      //       label: value.code + " " + key,
      //       value: value.code,
      //     };
      //   }
      // });

      const contactDetails = {
        company,
        name,
        socialLink,
        website,
        email,
        phoneNumber,
        countryCode,
      };

      let gpxInfo = [];
      data.gpx.forEach((element, i) => {
        gpxInfo.push({
          key: i + 1,
          id: element.id_media,
          name: element.name,
          deleted: false,
        });
      });

      let instagram = {
        active: data.data.instagram_active ? true : false,
        hashtag: data.data.instagram_hashtag,
        profile: data.data.instagram_profile,
        // images: [],
        images: data.instagram.map((element, i) => {
          return {
            instaLink: element.url,
            media:
              element.id_media !== null
                ? {
                    id: element.id_media,
                    alt: element.alt,
                    file_name: element.file_name,
                    extension: element.extension,
                  }
                : {},
            key: i,
          };
        }),
      };

      // Itinerary parts
      const itinerary = data.itinerary;

      this.newTour = {
        id: data.data.id,
        textFo,
        textEn,
        duration,
        difficulty,
        minimumAge,
        activities,
        languages,
        highlights,
        included,
        whatToBring,
        dates,
        itinerary,

        // categories: JSON.parse(data.data.place_categories),
        placeRestrictions: {
          minAge: data.data.min_age,
          capacity: data.data.capacity,
        },
        images: {
          mainImages,
        },
        // address,
        contactDetails,

        isFree: data.data.is_free === 1 ? true : false,
        prices: { prices, payUrl: data.data.pay_url },
        address: {},
        isPlace: true,
        maxGuests: data.data.max_guests,
        instagram,
        gpxInfo,
        reliveMedium: {
          id: data.data.relive_medium,
          src: data.data.relive_url,
        },
      };
    }
    this.newTourSaved = cloneDeep(this.newTour);
  } // END constructor

  componentDidMount() {
    const vfiUser = sessionStorage.getItem("vfiUser");
    this.axios
      .post(env.protocol + env.env + "/api/secured/GetUserInfo", {
        vfiUser,
      })
      .then((response) => {
        if (this._isMounted) {
          this.user = response.data;
        }
      })
      .catch((error) => console.error(error));

    this.places = [];
    this.axios
      .post(env.protocol + env.env + "/api/whatson/places/GetPlacesList?ignore_author")
      .then((response) => {
        if (this._isMounted) {
          response.data.forEach((element) => {
            if (element.current_status !== "deleted")
              this.places.push({
                selectValue: {
                  label: element.headline,
                  value: element.id,
                },
                region: element.address_region !== "null" ? element.address_region : "",
              });
          });

          this.places = this.places.filter((e) => e.selectValue.label !== null);
          this.places.sort((a, b) => {
            return a.selectValue.label.toLowerCase() > b.selectValue.label.toLowerCase();
          });

          if (this.props.updateData !== undefined) {
            this.places.forEach((element) => {
              if (element.selectValue.value === this.placeId)
                this.newTour.place = JSON.parse(JSON.stringify(element.selectValue));
            });
          } else {
            // this.newTour.place = { label: "", value: "" };
            // if (this.places.length > 0) {
            //   const fPlace = this.places[0];
            //   this.newTour.place = fPlace.selectValue;
            // }
          }
          this.newTourSaved = cloneDeep(this.newTour);
          this.setState({});
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.regions = [];
    this.axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        const regions = response.data;
        regions.forEach((element) => {
          this.regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    if (this.props.updateData !== undefined) {
      const data = this.props.updateData;
      let uploadedFile = undefined;

      if (data.data.main_image !== "0") {
        uploadedFile = {
          id: data.data.main_image,
          fileExtension: data.data.file_extension,
        };
      }

      this.setState({ uploadedFile });
    }

    this.activities = [];
    this.axios
      .get(env.protocol + env.env + "/api/whatson/tours/GetActivities")
      .then((response) => {
        const activities = response.data;
        activities.forEach((element) => {
          this.activities.push({
            label: element[this.props.language],
            value: element.id,
          });
        });
        this.activities.sort((a, b) => {
          return a.label > b.label;
        });
      })
      .catch((error) => {
        console.error(error);
      });
    if (!this.props.isFront && this.props.creatingANew) {
      this.saveInterval = setInterval(() => {
        if (!loadash.isEqual(this.newTourSaved, this.newTour)) {
          // Do the auto-save
          const vfiUser = sessionStorage.getItem("vfiUser");

          let link = env.protocol + env.env + "/api/secured/whatson/tours/AddTour";
          let isNewTour;
          if (this.props.updateData !== undefined) {
            isNewTour = false;
          } else {
            isNewTour = true;
          }

          this.axios
            .post(link, {
              ...this.newTour,
              currentStatus: "draft",
              vfiUser,
            })
            .then((response) => {
              let newValue = response.data[0];

              this.props.autoSave(newValue, isNewTour);

              this.newTour.id = newValue.id;
              this.newTourSaved = cloneDeep(this.newTour);
            })
            .catch((error) => console.error(error));
        }
      }, 30000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.saveInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addOne !== this.props.addOne) {
      const media = this.props.addOne;
      if (media.fileExtension === undefined) media.fileExtension = media.file_extension;

      let maxKey = 0;

      switch (this.mediaIs) {
        case "mainImage":
          this.newTour.images.mainImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m, i) => {
            this.newTour.images.mainImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;

        case "instagram":
          this.newTour.instagram.images[this.instaIndex].media = {
            ...media,
            extension: media.fileExtension,
          };
          break;

        case "gpxFile":
          this.newTour.gpxInfo.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newTour.gpxInfo.push({
              ...m,
              key: maxKey + 1,
              name: m.file_name,
              deleted: false,
              alt: m.alt,
              file_name: m.file_name,
            });
            maxKey++;
          });
          break;

        case "relive":
          this.newTour.reliveMedium = {
            id: media.id,
            extension: media.fileExtension,
            src: media.medium.large,
          };
          break;

        default:
          break;
      }
      this.setState({});
    }

    const headerElement = this.headerRef.current;
    const index = this.props.index;
    if (headerElement && index !== undefined) {
      headerElement.scroll({
        left: 73 * index + 51.5 - headerElement.parentNode.scrollWidth / 2,
        behavior: "smooth",
      });
    }
  }

  addContent(destination, value) {
    if (typeof destination === "string") destination = destination.split(".");

    destination.reverse().forEach((element, i) => {
      value = { [element]: value };
    });

    loadash.merge(this.newTour, value);
  }

  dateChange(e) {
    this.newTour.dates = e;
    this.setState({ beenEdit: { ...this.state.beenEdit, dates: true } });
  }

  priceChange(e) {
    this.newTour.prices = e;
  }

  uploadFile(e) {
    this.setState({ uploadFiles: e });

    var formData = new FormData();

    e.forEach((element) => {
      formData.append("fileToUpload[]", element, element.name);
    });
    formData.append("siteId", "0");
    formData.append("userId", sessionStorage.getItem("vfiUser"));

    this.axios
      .post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, this.config)
      .then((res) => {
        this.setState({ uploadedFile: pushMedia(res.data[0]) });
        this.newTour.images.mainImage = res.data[0];
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  saveFile = (e) => {
    //Saving images and adding them to mainimage so they can be shown. But not uploaded.
    //Goyma hana sum temp fíl
    let maxKey = 0;
    this.newTour.images.mainImages.forEach((element) => {
      if (element.key > maxKey) maxKey = element.key;
    });

    var images = this.newTour.images.mainImages;
    e.forEach((element, i) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          images.push({
            key: maxKey + i + 1,
            elm: reader.result,
            element: element,
            name: element.name,
          });
        },
        false
      );
      reader.readAsDataURL(element);
      reader.addEventListener("loadend", () => this.setState({}));
    });
    this.newTour.images.mainImages = images;
    this.setState({ addMediaDroppedDown: false });
  };

  onMarkerChange(values) {
    this.regionAndZipPopulate(values.geocode);

    if (values.geocode?.results[0]) {
      this.newTour.address.name = values.geocode.results[0].formatted_address;
    }

    const buttonEvent = values.mapMouseEvent;

    const lat = buttonEvent.latLng.lat();
    const lng = buttonEvent.latLng.lng();

    this.setState({ showMarker: true, mapMarker: { lat: lat, lng: lng } });
    this.newTour.address = {
      ...this.newTour.address,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
      mapCenter: { lat: lat, lng: lng },
    };
  }

  onAddressChange(data) {
    const lat = data.geometry.location.lat(data.formatted_address);
    const lng = data.geometry.location.lng(data.formatted_address);

    if (data.address_components)
      this.regionAndZipPopulate({
        results: [{ address_components: data.address_components }],
      });

    this.setState({
      address: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    });

    this.newTour.address = {
      ...this.newTour.address,
      name: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    };
  }

  regionAndZipPopulate(data) {
    var zip = -1;
    var region = -1;
    const results = data.results;

    for (var i = 0; i < results.length; i++) {
      //found the address
      var result = results[i];

      for (var j = 0; j < result.address_components.length; j++) {
        var component = result.address_components[j];
        if (
          component.types.filter(function (x) {
            return x === "route";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "street_number";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "postal_code";
          }).length > 0
        ) {
          zip = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "administrative_area_level_1";
          }).length > 0
        ) {
          region = component.long_name;
        }
      }
      break;
    }

    if (region === "Northern Isles") {
      region = "Norðoyggjar";
    }
    if (region === "Suduroy") {
      region = "Suðuroy";
    }
    if (region === "Eysturoyar") {
      region = "Eysturoy";
    }
    if (region === "Streymoyar") {
      region = "Streymoy";
    }

    if (torshavnRegions.includes(parseInt(zip))) {
      region = "Tórshavn";
    }

    this.regions.forEach((element) => {
      if (element.label === region) {
        this.newTour.address.region = this.regions.find((e) => e.label === region);
      }
    });

    Object.keys(zipCodes).forEach((k, v) => {
      if (zipCodes[k].code.toString() === zip) {
        const zipLabel = zip + " " + zipCodes[k].city;
        this.newTour.address.zipCode = { label: zipLabel, value: zip };
      }
    });
  }

  config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      this.props.blurScreen(true, percentCompleted);
    },
  };

  uploadFileAndTour = () => {
    //Gera myndirnar klárar
    var img = this.newTour.images.mainImages;
    if (img.length > 0) {
      var formData = new FormData();
      img.forEach((e) => {
        formData.append("fileToUpload[]", e.element, e.name);
      });
      formData.append("siteId", "0");
      formData.append("userId", sessionStorage.getItem("vfiUser"));

      this.props.blurScreen(true);
      //Uploada media
      this.axios
        .post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, this.config)
        .then((res) => {
          let newImages = [];
          //Goyma id og annað í event áðrenn event verður skrásett
          var images = this.newTour.images.mainImages;
          images.forEach((element, i) => {
            const elm = {
              ...element,
              elm: undefined,
              key: element.key,
              id: res.data[i].id,
              file_name: res.data[i].file_name,
              file_extension: res.data[i].file_extension,
              alt: res.data[i].alt,
            };
            newImages.push(elm);
          });
          this.newTour.images.mainImages = newImages;

          //Stovna event
          let edited = JSON.parse(JSON.stringify(this.state.beenEdit));
          edited = allObjTrue(edited);

          this.setState({ beenEdit: edited }, () => {
            if (this.allPassed) {
              this.publish(false, env.protocol + env.env + "/api/secured/whatson/tours/AddTour");
            }
          });
          this.props.blurScreen(false);
        })
        .catch(function (err) {
          console.error(err);
        });
    } else {
      //Stovna event beinanvegin tá ongar myndir eru
      let edited = JSON.parse(JSON.stringify(this.state.beenEdit));
      edited = allObjTrue(edited);

      this.setState({ beenEdit: edited }, () => {
        if (this.allPassed) {
          this.publish(false, env.protocol + env.env + "/api/secured/whatson/tours/AddTour");
        }
      });
    }
  };

  uniqueSlug(slug, assignDestination, localSlugs, language) {
    this.axios
      .post(env.protocol + env.env + "/api/secured/whatson/tours/UniqueSlug", {
        slug,
        localSlugs,
        tourId: this.newTour.id,
        language,
      })
      .then((response) => {
        this.addContent(assignDestination, response.data);
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  defaultDragStart(e) {
    this.setState({ imageDragging: true });
  }
  defaultDragStop(e) {
    this.setState({ imageDragging: false });
  }

  submitChange = () => {
    this.props.blurScreen(true);
    this.axios
      .post(env.protocol + env.env + "/api/secured/submitChanges", {
        type: "tour",
        data: this.newTour,
        changes: this.submit,
        userId: this.props.userFront,
      })
      .then((res) => {
        this.props.blurScreen(false);
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  deleteGpx(index) {
    this.newTour.gpxInfo[index].deleted = true;
    this.newTour.gpxInfo.splice(index, 1);
    this.setState({});
  }

  addOrUpdateTour = (isDraft = false) => {
    if (this.props.isFront) {
      if (this.props.submitChange === "true") {
        this.submitChange();
      } else {
        this.uploadFileAndTour();
      }
    } else {
      let link = env.protocol + env.env + "/api/secured/whatson/tours/AddTour";
      if (isDraft) {
        this.publish(true, link);
      } else {
        let edited = Object.assign({}, this.state.beenEdit);
        edited = allObjTrue(edited);

        this.setState({ beenEdit: edited }, () => {
          if (this.allPassed) this.publish(false, link);
        });
      }
    }
  };

  publish = (isDraft, link) => {
    this.props.blurScreen(true);
    const vfiUser = sessionStorage.getItem("vfiUser");
    if (this.props.isFront) {
      this.newTour.address.currentStatus = "published";
    }
    clearInterval(this.saveInterval);
    this.axios
      .post(link, {
        ...this.newTour,
        currentStatus: isDraft ? "draft" : "published",
        vfiUser,
        canCreatePlace: true,
      })
      .then((response) => {
        let newValue = response.data[0];

        // Eru vit í backend skulu vit pusha til listan
        if (!this.props.isFront) {
          if (this.props.updateData !== undefined) this.props.updateOneAndGoToList(this.newTour.id, newValue, isDraft);
          else this.props.pushOneAndGoToList(newValue, isDraft);

          this.props.blurScreen(false);
          this.props.indexChange(1);
        } else {
          this.setState({ frontFinnished: true });
        }
      })
      .catch((error) => console.error(error));
  };

  render() {
    const showEn = this.newTour.textEn.showIt;
    const showFo = this.newTour.textFo.showIt;
    const uploadHidden = this.state.addMediaDroppedDown ? "" : "hidden";
    let submitChange = this.props.submitChange === "true";
    const beenEdit = this.state.beenEdit;

    let content = "";

    if (this.state.frontFinnished) {
      //Submitta á frontend. So kemur tillukku tekstur framm
      content = (
        <div className="finnished">
          <h1>{this.language.register.header_register}</h1>
          <div className="new-edit">
            <div className="final-text">
              <FontAwesomeIcon icon={faChild} className="wooh" />
              <p>
                {this.user.firstname.toUpperCase() + " " + this.user.lastname.toUpperCase()},{" "}
                {this.language.register.final_text_registered}
              </p>
            </div>
          </div>{" "}
        </div>
      );
    } else {
      switch (this.props.index) {
        case 0:
          content = (
            <div className="new-content one">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.info.header}</h1>
              <p>{this.language.info.sub_text}</p>

              <div className="fill-form row">
                <div className={"col-lg-6 language-box english" + (showEn ? " active" : "")}>
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showEn}
                    onChange={(e) => {
                      this.newTour.textEn.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.headline") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.headline")) {
                            this.submit.push("textEn.headline");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.headline_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Name the tour section - e.g. Day 1, evening: The Northern isles or Day 3, morning: Kayaking
                            in Tjørnuvík.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      maxLength={50}
                      value={this.newTour.textEn.headline}
                      onChange={(e) => {
                        this.newTour.textEn.headline = e.target.value;
                        this.newTour.textEn.urlSlug = slugify(e.target.value);
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              headline: true,
                            },
                          },
                        });
                      }}
                      disabled={submitChange ? !this.submit.includes("textEn.headline") || !showEn : !showEn}
                      defaultValue={this.newTour.textEn.headline}
                      placeholder={this.language.info.headline_en}
                      errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                      hideError={!this.state.beenEdit.textEn.headline || !showEn}
                      onBlur={(e) => {
                        this.uniqueSlug(
                          slugify(e.target.value),
                          "textEn.urlSlug",
                          [this.newTour.textFo.urlSlug],
                          "english"
                        );
                      }}
                    />
                  </div>
                  {/* <div className="slug-url-wrap row">
                    <p className="col-xl-3">{this.language.info.slug_url_en}</p>
                    <VfiInputText
                      className="col-xl-9"
                      value={this.newTour.textEn.urlSlug}
                      onChange={(e) => {
                        this.newTour.textEn.urlSlug = e.target.value;
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}
                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.shortDescription") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.shortDescription")) {
                            this.submit.push("textEn.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.excerpt_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the introduction preceding the description on the tour page.
                            <br />
                            <br />
                            Give a short introduction with an overview that naturally flows into the description field
                            below
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      disabled={submitChange ? !this.submit.includes("textEn.shortDescription") || !showEn : !showEn}
                      className="col-xl-9"
                      maxLength={80}
                      placeholder={this.language.info.excerpt_en}
                      onChange={(e) => {
                        this.newTour.textEn.shortDescription = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              shortDescription: true,
                            },
                          },
                        });
                      }}
                      value={this.newTour.textEn.shortDescription}
                      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                      hideError={!this.state.beenEdit.textEn.shortDescription || !showEn}
                    />
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.description") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.description")) {
                            this.submit.push("textEn.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.description_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the description on the tour page.
                            <br />
                            <br />
                            It's a good idea to start by explaining what your tour is about in general, followed by
                            details. Keep the description light and engaging, and think about your audience's
                            perspective and what could affect their decision in choosing your tour.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={3000}
                      placeholder={this.language.info.description_en}
                      onChange={(e) => {
                        this.newTour.textEn.description = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textEn: {
                              ...this.state.beenEdit.textEn,
                              description: true,
                            },
                          },
                        });
                      }}
                      disabled={submitChange ? !this.submit.includes("textEn.description") || !showEn : !showEn}
                      value={this.newTour.textEn.description}
                      errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                      hideError={!this.state.beenEdit.textEn.description || !showEn}
                    />
                  </div>
                  <MapSummaryRow parent={this} showLang={showEn} langKey="textEn" />
                </div>
                <div className={"col-lg-6 language-box faroese" + (showFo ? " active" : "")}>
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showFo}
                    onChange={(e) => {
                      this.newTour.textFo.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.headline") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.headline")) {
                            this.submit.push("textFo.headline");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.headline_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Name the tour section - e.g. Day 1, evening: The Northern isles or Day 3, morning: Kayaking
                            in Tjørnuvík.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiInputText
                      className="col-xl-9"
                      maxLength={50}
                      value={this.newTour.textFo.headline}
                      placeholder={this.language.info.headline_fo}
                      onChange={(e) => {
                        this.newTour.textFo.headline = e.target.value;
                        this.newTour.textFo.urlSlug = slugify(e.target.value);
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              headline: true,
                            },
                          },
                        });
                      }}
                      defaultValue={this.newTour.textFo.headline}
                      errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                      hideError={!this.state.beenEdit.textFo.headline || !showFo}
                      disabled={submitChange ? !this.submit.includes("textFo.headline") || !showFo : !showFo}
                      onBlur={(e) => {
                        this.uniqueSlug(
                          slugify(e.target.value),
                          "textFo.urlSlug",
                          [this.newTour.textEn.urlSlug],
                          "faroese"
                        );
                      }}
                    />
                  </div>

                  {/* <div className="slug-url-wrap row">
                    <p className="col-xl-3">{this.language.info.slug_url_fo}</p>
                    <VfiInputText
                      className="col-xl-9"
                      value={this.newTour.textFo.urlSlug}
                      onChange={(e) => {
                        this.newTour.textFo.urlSlug = e.target.value;
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}

                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.shortDescription") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.shortDescription")) {
                            this.submit.push("textFo.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.excerpt_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the introduction preceding the description on the tour page.
                            <br />
                            <br />
                            Give a short introduction with an overview that naturally flows into the description field
                            below
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={80}
                      placeholder={this.language.info.excerpt_fo}
                      onChange={(e) => {
                        this.newTour.textFo.shortDescription = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              shortDescription: true,
                            },
                          },
                        });
                      }}
                      value={this.newTour.textFo.shortDescription}
                      errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                      hideError={!this.state.beenEdit.textFo.shortDescription || !showFo}
                      disabled={submitChange ? !this.submit.includes("textFo.shortDescription") || !showFo : !showFo}
                    />
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.description") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.description")) {
                            this.submit.push("textFo.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.description_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the description on the tour page.
                            <br />
                            <br />
                            It's a good idea to start by explaining what your tour is about in general, followed by
                            details. Keep the description light and engaging, and think about your audience's
                            perspective and what could affect their decision in choosing your tour.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiTextarea
                      className="col-xl-9"
                      maxLength={3000}
                      placeholder={this.language.info.description_fo}
                      onChange={(e) => {
                        this.newTour.textFo.description = e;
                        this.setState({
                          beenEdit: {
                            ...this.state.beenEdit,
                            textFo: {
                              ...this.state.beenEdit.textFo,
                              description: true,
                            },
                          },
                        });
                      }}
                      value={this.newTour.textFo.description}
                      errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                      hideError={!this.state.beenEdit.textFo.description || !showFo}
                      disabled={submitChange ? !this.submit.includes("textFo.description") || !showFo : !showFo}
                    />
                  </div>
                  <MapSummaryRow parent={this} showLang={showFo} langKey="textFo" />
                </div>
              </div>
            </div>
          );
          break;

        case 1:
          content = (
            <div className="new-content two">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.quick_facts.header}</h1>
              <p>{this.language.quick_facts.sub_text}</p>

              {submitChange ? (
                <div
                  className={"enable_editing_noMargin " + (this.submit.includes("duration") && "enabled")}
                  onClick={(e) => {
                    if (
                      !this.submit.includes("duration") &&
                      !this.submit.includes("activities") &&
                      !this.submit.includes("minimumAge") &&
                      !this.submit.includes("difficulty") &&
                      !this.submit.includes("maxGuests")
                    ) {
                      this.submit.push("duration");
                      this.submit.push("activities");
                      this.submit.push("difficulty");
                      this.submit.push("minimumAge");
                      this.submit.push("maxGuests");
                    }
                    this.setState({});
                  }}
                >
                  Enable editing
                  <FontAwesomeIcon icon={faPencil} />
                </div>
              ) : (
                ""
              )}
              <div className="quick-facts-wrap">
                <div className="">
                  <div className="duration-wrap">
                    <p className="text">
                      <FontAwesomeIcon icon={faClock} /> {this.language.quick_facts.duration}
                    </p>
                    <VfiCustomSelect
                      className="hours"
                      // options={this.hours}
                      options={this.duration}
                      value={this.duration.find((e) => e.value === this.newTour.duration)}
                      // value={this.state.hours}
                      // onChange={(e) => {
                      //   this.setState({ hours: e });
                      //   this.newTour.duration =
                      //     e.value * 60 +
                      //     (this.state.minutes ? this.state.minutes.value : 0);
                      // }}
                      onChange={(e) => {
                        this.newTour.duration = e.value;
                        this.setState({
                          duration: e,
                          beenEdit: { ...beenEdit, duration: true },
                        });
                      }}
                      placeholder={this.language.quick_facts.hours_placeholder}
                      isDisabled={submitChange ? !this.submit.includes("duration") : false}
                      error={beenEdit.duration && this.newTour.duration === undefined}
                    />
                    {/* <VfiCustomSelect
                        isDisabled={submitChange ? !(this.submit.includes('duration')) : false}
                        className="minutes col-lg-6"
                        options={this.minutes}
                        value={this.state.minutes}
                        onChange={(e) => {
                          this.setState({ minutes: e });
                          this.newTour.duration =
                            e.value +
                            (this.state.hours ? this.state.hours.value : 0) * 60;
                        }}
                        placeholder={
                          this.language.quick_facts.minutes_placeholder
                        }
                      /> */}
                  </div>

                  <div className="activities-wrap">
                    <div className="with-tooltip-2">
                      <FontAwesomeIcon icon={faRunning} /> Activities
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Choose at least one – and at most three – categories. The categories can guide your audience
                            in finding the right tour.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <VfiCustomSelect
                      className="activities"
                      isDisabled={submitChange ? !this.submit.includes("activities") : false}
                      options={this.activities}
                      isMulti={true}
                      onChange={(e) => {
                        this.newTour.activities = e;
                        this.setState({
                          beenEdit: { ...beenEdit, activities: true },
                        });
                      }}
                      defaultValue={this.newTour.activities}
                      error={beenEdit.activities && this.newTour.activities.length === 0}
                    />
                  </div>
                  <div className="difficulty-wrap">
                    <p>
                      <FontAwesomeIcon icon={faSnowboarding} /> Difficulty
                    </p>
                    <VfiCustomSelect
                      className="difficulty"
                      isDisabled={submitChange ? !this.submit.includes("difficulty") : false}
                      options={hikingDifficulty[this.props.language]}
                      onChange={(e) => {
                        this.newTour.difficulty = e;
                        this.setState({
                          beenEdit: { ...beenEdit, difficulty: true },
                        });
                      }}
                      defaultValue={this.newTour.difficulty}
                      error={beenEdit.difficulty && this.newTour.difficulty === undefined}
                    />
                  </div>
                  <div className="minimum-age-wrap">
                    <p>
                      <FontAwesomeIcon icon={faChild2} /> Minimum Age
                    </p>
                    <VfiInputText
                      className="minimum-age"
                      onChange={(e) => {
                        this.newTour.minimumAge = e.target.value;
                        this.setState({
                          beenEdit: { ...beenEdit, minimumAge: true },
                        });
                      }}
                      disabled={submitChange ? !this.submit.includes("minimumAge") : false}
                      defaultValue={this.newTour.minimumAge}
                      inputType="int"
                      errorCheckSequence={beenEdit.minimumAge ? [Verify.notEmpty] : []}
                    />
                  </div>
                  <div className="max-guests-wrap">
                    <p>
                      <FontAwesomeIcon icon={faUsers} /> Max Guests
                    </p>
                    <VfiInputText
                      className="max-guests"
                      onChange={(e) => {
                        this.newTour.maxGuests = e.target.value;
                        this.setState({
                          beenEdit: { ...beenEdit, maxGuests: true },
                        });
                      }}
                      disabled={submitChange ? !this.submit.includes("maxGuests") : false}
                      defaultValue={this.newTour.maxGuests}
                      inputType="int"
                      errorCheckSequence={beenEdit.maxGuests ? [Verify.notEmpty] : []}
                    />
                  </div>
                  <hr />
                  <div className="languages-wrap">
                    {submitChange ? (
                      <div
                        className={"enable_editing_noMargin " + (this.submit.includes("languages") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("languages")) {
                            this.submit.push("languages");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <FontAwesomeIcon icon={faComments} /> Languages
                    <div className="flags row">
                      <div className="en-flag col-md-6">
                        <img className="flag" src={enFlag} alt="en-flag" />
                      </div>
                      <div className="fo-flag col-md-6">
                        <img className="flag" src={foFlag} alt="fo-flag" />
                      </div>
                    </div>
                    {this.newTour.languages.map((element, i) => {
                      return (
                        <div className="languages-box" key={element.key}>
                          <div className="row">
                            <div className={"language-box english en-wrap col-md-6" + (showEn ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={enFlag}
                                alt="en-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                disabled={submitChange ? !this.submit.includes("languages") : false}
                                type="text"
                                defaultValue={element.valueEn}
                                onChange={(e) => {
                                  this.newTour.languages[i].valueEn = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, languages: true },
                                  });
                                }}
                                placeholder="English label"
                                errorCheckSequence={beenEdit.languages && showEn ? [Verify.notEmpty] : []}
                              />
                            </div>
                            <div className={"language-box faroese fo-wrap col-md-6" + (showFo ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={foFlag}
                                alt="fo-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                disabled={submitChange ? !this.submit.includes("languages") : false}
                                type="text"
                                defaultValue={element.valueFo}
                                onChange={(e) => {
                                  this.newTour.languages[i].valueFo = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, languages: true },
                                  });
                                }}
                                placeholder="Faroese label"
                                errorCheckSequence={beenEdit.languages && showFo ? [Verify.notEmpty] : []}
                              />
                            </div>
                          </div>
                          <FontAwesomeIcon
                            className="remove-one"
                            icon={faTimes}
                            onClick={() => {
                              if (!this.props.isFront || this.submit.includes("languages")) {
                                this.newTour.languages.splice(i, 1);
                                this.setState({});
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                    <div
                      className="add"
                      onClick={() => {
                        if (!this.props.isFront || this.submit.includes("languages")) {
                          let maxKey = 0;
                          this.newTour.languages.forEach((element) => {
                            if (element.key > maxKey) maxKey = element.key;
                          });
                          this.newTour.languages.push({
                            key: maxKey + 1,
                            value: "",
                          });
                          this.setState({});
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="add-more">Add more</span>
                    </div>
                    <hr />
                  </div>
                  <div className="highlights-wrap">
                    {submitChange ? (
                      <div
                        className={"enable_editing_noMargin " + (this.submit.includes("highlights") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("highlights")) {
                            this.submit.push("highlights");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="with-tooltip-2">
                      <FontAwesomeIcon icon={faHeart} /> Highlights
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={<div className={"tooltip-card"}>What makes this tour special?</div>}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <div className="flags row">
                      <div className="en-flag col-md-6">
                        <img className="flag" src={enFlag} alt="en-flag" />
                      </div>
                      <div className="fo-flag col-md-6">
                        <img className="flag" src={foFlag} alt="fo-flag" />
                      </div>
                    </div>
                    {this.newTour.highlights.map((element, i) => {
                      return (
                        <div className="highlights-box" key={element.key}>
                          <div className="row">
                            <div className={"language-box english en-wrap col-md-6" + (showEn ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={enFlag}
                                alt="en-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                disabled={submitChange ? !this.submit.includes("highlights") : false}
                                type="text"
                                defaultValue={element.valueEn}
                                onChange={(e) => {
                                  this.newTour.highlights[i].valueEn = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, highlights: true },
                                  });
                                }}
                                placeholder="English label"
                                errorCheckSequence={beenEdit.highlights && showEn ? [Verify.notEmpty] : []}
                              />
                            </div>
                            <div className={"language-box faroese fo-wrap col-md-6" + (showFo ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={foFlag}
                                alt="fo-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                disabled={submitChange ? !this.submit.includes("highlights") : false}
                                type="text"
                                defaultValue={element.valueFo}
                                onChange={(e) => {
                                  this.newTour.highlights[i].valueFo = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, highlights: true },
                                  });
                                }}
                                placeholder="Faroese label"
                                errorCheckSequence={beenEdit.highlights && showFo ? [Verify.notEmpty] : []}
                              />
                            </div>
                          </div>
                          <FontAwesomeIcon
                            className="remove-one"
                            icon={faTimes}
                            onClick={() => {
                              if (!this.props.isFront || this.submit.includes("highlights")) {
                                this.newTour.highlights.splice(i, 1);
                                this.setState({});
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                    <div
                      className="add"
                      onClick={() => {
                        if (!this.props.isFront || this.submit.includes("highlights")) {
                          let maxKey = 0;
                          this.newTour.highlights.forEach((element) => {
                            if (element.key > maxKey) maxKey = element.key;
                          });
                          this.newTour.highlights.push({
                            key: maxKey + 1,
                            value: "",
                          });
                          this.setState({});
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="add-more">Add more</span>
                    </div>
                    <hr />
                  </div>
                  <div className="included-wrap">
                    {submitChange ? (
                      <div
                        className={"enable_editing_noMargin " + (this.submit.includes("included") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("included")) {
                            this.submit.push("included");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="with-tooltip-2">
                      Included
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Get people excited by telling them what is included. Packed lunch? Free WiFi? A ride back
                            home?
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <div className="flags row">
                      <div className="en-flag col-md-6">
                        <img className="flag" src={enFlag} alt="en-flag" />
                      </div>
                      <div className="fo-flag col-md-6">
                        <img className="flag" src={foFlag} alt="fo-flag" />
                      </div>
                    </div>
                    {this.newTour.included.map((element, i) => {
                      return (
                        <div className="included-box" key={element.key}>
                          <div className="row">
                            <div className={"language-box english en-wrap col-md-6" + (showEn ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={enFlag}
                                alt="en-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                type="text"
                                defaultValue={element.valueEn}
                                disabled={submitChange ? !this.submit.includes("included") : false}
                                onChange={(e) => {
                                  this.newTour.included[i].valueEn = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, included: true },
                                  });
                                }}
                                placeholder="English label"
                                errorCheckSequence={beenEdit.included && showEn ? [Verify.notEmpty] : []}
                              />
                            </div>
                            <div className={"language-box faroese fo-wrap col-md-6" + (showFo ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={foFlag}
                                alt="fo-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                disabled={submitChange ? !this.submit.includes("included") : false}
                                type="text"
                                defaultValue={element.valueFo}
                                onChange={(e) => {
                                  this.newTour.included[i].valueFo = e.target.value;
                                  this.setState({
                                    beenEdit: { ...beenEdit, included: true },
                                  });
                                }}
                                placeholder="Faroese label"
                                errorCheckSequence={beenEdit.included && showFo ? [Verify.notEmpty] : []}
                              />
                            </div>
                          </div>
                          <FontAwesomeIcon
                            className="remove-one"
                            icon={faTimes}
                            onClick={() => {
                              if (!this.props.isFront || this.submit.includes("included")) {
                                this.newTour.included.splice(i, 1);
                                this.setState({});
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                    <div
                      className="add"
                      onClick={() => {
                        if (!this.props.isFront || this.submit.includes("included")) {
                          let maxKey = 0;
                          this.newTour.included.forEach((element) => {
                            if (element.key > maxKey) maxKey = element.key;
                          });
                          this.newTour.included.push({
                            key: maxKey + 1,
                            value: "",
                          });
                          this.setState({});
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="add-more">Add more</span>
                    </div>
                    <hr />
                  </div>

                  <div className="what-to-bring-wrap">
                    {submitChange ? (
                      <div
                        className={"enable_editing_noMargin " + (this.submit.includes("whatToBring") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("whatToBring")) {
                            this.submit.push("whatToBring");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="with-tooltip-2">
                      What to bring
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Prepare your guests for the fun! Should they bring any essentials like good boots or warm
                            clothes?
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <div className="flags row">
                      <div className="en-flag col-md-6">
                        <img className="flag" src={enFlag} alt="en-flag" />
                      </div>
                      <div className="fo-flag col-md-6">
                        <img className="flag" src={foFlag} alt="fo-flag" />
                      </div>
                    </div>
                    {this.newTour.whatToBring.map((element, i) => {
                      return (
                        <div className="whattobring-box" key={element.key}>
                          <div className="row">
                            <div className={"language-box english en-wrap col-md-6" + (showEn ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={enFlag}
                                alt="en-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                type="text"
                                disabled={submitChange ? !this.submit.includes("whatToBring") : false}
                                defaultValue={element.valueEn}
                                onChange={(e) => {
                                  this.newTour.whatToBring[i].valueEn = e.target.value;
                                  this.setState({
                                    beenEdit: {
                                      ...beenEdit,
                                      whatToBring: true,
                                    },
                                  });
                                }}
                                placeholder="English label"
                                errorCheckSequence={beenEdit.whatToBring && showEn ? [Verify.notEmpty] : []}
                              />
                            </div>
                            <div className={"language-box faroese fo-wrap col-md-6" + (showFo ? " active" : "")}>
                              {/* <img
                                className="flag"
                                src={foFlag}
                                alt="fo-flag"
                              /> */}
                              <VfiInputText
                                className="text"
                                type="text"
                                disabled={submitChange ? !this.submit.includes("whatToBring") : false}
                                defaultValue={element.valueFo}
                                onChange={(e) => {
                                  this.newTour.whatToBring[i].valueFo = e.target.value;
                                  this.setState({
                                    beenEdit: {
                                      ...beenEdit,
                                      whatToBring: true,
                                    },
                                  });
                                }}
                                placeholder="Faroese label"
                                errorCheckSequence={beenEdit.whatToBring && showFo ? [Verify.notEmpty] : []}
                              />
                            </div>
                          </div>
                          <FontAwesomeIcon
                            className="remove-one"
                            icon={faTimes}
                            onClick={() => {
                              if (!this.props.isFront || this.submit.includes("whatToBring")) {
                                this.newTour.whatToBring.splice(i, 1);
                                this.setState({});
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                    <div
                      className="add"
                      onClick={() => {
                        if (!this.props.isFront || this.submit.includes("whatToBring")) {
                          let maxKey = 0;
                          this.newTour.whatToBring.forEach((element) => {
                            if (element.key > maxKey) maxKey = element.key;
                          });
                          this.newTour.whatToBring.push({
                            key: maxKey + 1,
                            value: "",
                          });
                          this.setState({});
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="add-more">Add more</span>
                    </div>
                    <hr />
                  </div>
                  <div className="row">
                    <div style={{ width: "100%", padding: "0 15px" }} className="with-tooltip-2">
                      Good to know
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Anything else people should know? Weather reports? Parking advice?
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    <div style={{ with: "0px" }}></div>
                    <div className={"language-box english know-en-wrap col-lg-6" + (showEn ? " active" : "")}>
                      {submitChange ? (
                        <div
                          className={
                            "enable_editing_noMargin " + (this.submit.includes("textEn.goodToKnow") && "enabled")
                          }
                          onClick={(e) => {
                            if (!this.submit.includes("textEn.goodToKnow")) {
                              this.submit.push("textEn.goodToKnow");
                            }
                            this.setState({});
                          }}
                        >
                          Enable editing
                          <FontAwesomeIcon icon={faPencil} />
                        </div>
                      ) : (
                        ""
                      )}
                      <img className="flag" src={enFlag} alt="en-flag" />{" "}
                      <VfiTextarea
                        className="good-to-know"
                        defaultValue={this.newTour.textEn.goodToKnow}
                        onChange={(e) => {
                          this.newTour.textEn.goodToKnow = e;
                          this.setState({
                            beenEdit: {
                              ...beenEdit,
                              textEn: { ...beenEdit.textEn, goodToKnow: true },
                            },
                          });
                        }}
                        disabled={submitChange ? !this.submit.includes("textEn.goodToKnow") || !showEn : !showEn}
                        errorCheckSequence={beenEdit.textEn.goodToKnow && showEn ? [Verify.notEmpty] : []}
                      />
                    </div>
                    <div className={"language-box faroese know-fo-wrap col-lg-6" + (showFo ? " active" : "")}>
                      {submitChange ? (
                        <div
                          className={
                            "enable_editing_noMargin " + (this.submit.includes("textFo.goodToKnow") && "enabled")
                          }
                          onClick={(e) => {
                            if (!this.submit.includes("textFo.goodToKnow")) {
                              this.submit.push("textFo.goodToKnow");
                            }
                            this.setState({});
                          }}
                        >
                          Enable editing
                          <FontAwesomeIcon icon={faPencil} />
                        </div>
                      ) : (
                        ""
                      )}
                      <img className="flag" src={foFlag} alt="fo-flag" />{" "}
                      <VfiTextarea
                        className="good-to-know"
                        defaultValue={this.newTour.textFo.goodToKnow}
                        onChange={(e) => {
                          this.newTour.textFo.goodToKnow = e;
                          this.setState({
                            beenEdit: {
                              ...beenEdit,
                              textFo: { ...beenEdit.textFo, goodToKnow: true },
                            },
                          });
                        }}
                        disabled={submitChange ? !this.submit.includes("textFo.goodToKnow") || !showFo : !showFo}
                        errorCheckSequence={beenEdit.textFo.goodToKnow && showFo ? [Verify.notEmpty] : []}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Itinerary parent={this} />
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case 2:
          content = (
            <div>
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
                // previewListOptions={this.navPreviewList}
              />
              <div className="new-content relive-gpx">
                <div className="edit-contents">
                  <div className="row">
                    <div className="relive-wrap col-lg-12">
                      <p>{this.language.map.relive_url}</p>
                      <OneMedia
                        siteRef={this.whatsonSiteId}
                        setSiteRef={this.props.setSiteRef}
                        openOverlay={this.props.openOverlay}
                        whatMedia={["videos"]}
                        media={this.newTour.reliveMedium}
                        onClick={() => {
                          this.mediaIs = "relive";
                        }}
                        faIcon={faFilm}
                        draggable={false}
                      />
                    </div>
                    <div className="gpx-wrap col-lg-12">
                      <p className="gpx-topper">{this.language.map.gpx_header}</p>
                      <div
                        className="image-upload-block"
                        onClick={() => {
                          this.props.setSiteRef(this.whatsonSiteId);
                          this.props.openOverlay("mediaChoose", {
                            siteId: this.whatsonSiteId,
                            filter: { mediaType: ["gpx"] },
                          });
                          this.mediaIs = "gpxFile";
                        }}
                      >
                        <div className={"upload-box"}>
                          <div className="image-container">
                            <FontAwesomeIcon className="pen" icon={faPen} />
                            <FontAwesomeIcon className="before-image" icon={faFile} />
                            <div className="gpx-label">GPX</div>
                          </div>
                        </div>
                      </div>
                      <ul>
                        {this.newTour.gpxInfo.map((info, i) => {
                          return info.deleted === false ? (
                            <li key={info.key}>
                              <div className="gpx-content">
                                <p>{this.language.map.gpx_file_name}</p>
                                <VfiInputText
                                  onChange={(e) => {
                                    this.newTour.gpxInfo[i].name = e.target.value;
                                  }}
                                  defaultValue={info.name}
                                />
                                <span
                                  className="remove-button"
                                  onClick={(e) => {
                                    this.deleteGpx(i);
                                  }}
                                  value={i}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </span>
                              </div>
                            </li>
                          ) : (
                            ""
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case 3:
          content = (
            <div className="new-content three">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.dates.header}</h1>
              <p>{this.language.dates.sub_text}</p>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("dates") && "enabled")}>
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("dates")}
                    onChange={(e) => {
                      if (!this.submit.includes("dates")) {
                        this.submit.push("dates");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              <div className="top-title with-tooltip with-tooltip-2 no-margin">
                TYPE OF DATE
                <GlobalToolTipController
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      Single date: Your tour occurs only once on a single date.
                      <br />
                      <br />
                      Specific dates: Your tour happens on specific dates on two or more occasions.
                      <br />
                      <br />
                      Recurring days: Your tour takes place on two or more recurring days (i.e. once a week, repeating
                      periodically).
                      <br />
                      <br />
                      Range: The tour is available during parts of the year.
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
              </div>
              <Dates
                disabled={submitChange ? !this.submit.includes("dates") : false}
                defaultDateOccurence={this.newTour.dates.occurence}
                defaultStartDate={this.newTour.dates.startDate}
                defaultEndDate={this.newTour.dates.endDate}
                defaultStartTime={this.newTour.dates.startTime}
                defaultEndTime={this.newTour.dates.endTime}
                defaultDays={this.newTour.dates.days}
                defaultSpecificDates={this.newTour.dates.specificDates}
                defaultDateVisible={this.newTour.dates.dateVisible}
                onChange={this.dateChange}
                language={this.props.language}
              />
            </div>
          );
          break;

        case 4:
          content = (
            <div className="new-content four">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.price.header}</h1>
              <p>{this.language.price.sub_text}</p>
              <label className="is-free-label">
                {submitChange ? (
                  <div className={"enable_editing_checkbox " + (this.submit.includes("prices") && "enabled")}>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("prices") && this.submit.includes("isFree")}
                      onChange={(e) => {
                        if (!(this.submit.includes("prices") && this.submit.includes("isFree"))) {
                          this.submit.push("prices");
                          this.submit.push("isFree");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="is-free">
                  <VfiCheckbox
                    disabled={
                      submitChange ? !(this.submit.includes("isFree") && this.submit.includes("prices")) : false
                    }
                    checked={this.newTour.isFree}
                    onChange={() => {
                      this.newTour.isFree = !this.newTour.isFree;
                      this.setState({});
                    }}
                  />{" "}
                  <span>{this.language.price.price_free_text}</span>
                </div>
              </label>
              <Prices
                disabled={submitChange ? !(this.submit.includes("isFree") && this.submit.includes("prices")) : false}
                className={this.newTour.isFree ? "hidden" : ""}
                onChange={this.priceChange}
                defaultValue={this.newTour.prices}
                language={this.props.language}
              />
            </div>
          );
          break;

        case 5:
          content = (
            <div className="new-content five">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.media.header}</h1>
              <div className="with-tooltip-2">
                {this.language.media.sub_text}
                <GlobalToolTipController
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      The first image will appear in the tours overview. Any additional images will appear on the tour
                      page. You can drag them in the order you prefer.
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
              </div>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("images") && "enabled")}>
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("images")}
                    onChange={(e) => {
                      if (!this.submit.includes("images")) {
                        this.submit.push("images");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              <div
                className={`upload-media-wrapper ${this.submit.includes("images") || !submitChange ? "" : "disabled"}`}
              >
                <UploadMedia
                  default_elements={this.newTour.images.mainImages.map((element) => {
                    return {
                      ...element,
                      // file: null,
                      url: element?.medium?.actual
                        ? element?.medium?.actual
                        : element?.elm
                          ? element.elm
                          : `${env.protocol}${env.env}/uploads/${element?.file_name}.${element?.file_extension}`,
                      text: {
                        en: element.enText,
                        fo: element.foText,
                      },
                    };
                  })}
                  auth={{
                    status: true,
                    call: (addMediaElements) => {
                      /* 
                        I don't have time to completely rewrite the state management
                        and the way we handle the media overlay, so we have to rely on these
                        callbacks for now :/
                      */
                      this.props.setSiteRef(this.whatsonSiteId);
                      this.props.openOverlay("mediaChoose", {
                        siteId: this.whatsonSiteId,
                        filter: { mediaType: ["images"] },
                        callbackArg: (new_media) => {
                          addMediaElements(
                            [...new_media].map((element) => {
                              return {
                                ...element,
                                file: null,
                                url: element?.medium?.actual ? element?.medium?.actual : element.elm,
                                text: {
                                  en: "",
                                  fo: "",
                                },
                              };
                            })
                          );
                        },
                      });
                      this.mediaIs = "mainImage";
                      /**
                       *
                       */
                    },
                  }}
                  onElementsChange={(elements) => {
                    /**
                     * This is not ideal either, but optimizing and cleaning this would require a bigger rewrite
                     */

                    // this.unuploadedMedia.mainImages = elements
                    //   .filter((e) => !e.id)
                    //   .map((e) => e.file);

                    this.newTour.images.mainImages = elements.map((element, index) => {
                      const { file, rest, url, text, id } = element;
                      return {
                        ...rest,
                        element: file,
                        key: index + 1,
                        name: file?.name,
                        elm: url,
                        id: id,
                        ...Object.fromEntries(
                          Object.entries(text).map((entry) => {
                            let [lang, text] = entry;
                            return [`${lang}Text`, text];
                          })
                        ),
                      };
                    });
                  }}
                />
              </div>
              <h2 style={{ marginTop: "40px" }}>
                Instagram <FontAwesomeIcon icon={faInstagram} />
              </h2>
              <label className="instagram-enabled">
                <VfiCheckbox
                  className="instagram-active"
                  checked={this.newTour.instagram.active}
                  onChange={() => {
                    this.newTour.instagram.active = !this.newTour.instagram.active;
                    this.setState({});
                  }}
                />{" "}
                {this.language.media.instagram_enabled}
              </label>
              {this.newTour.instagram.active && (
                <InstagramInWhatson
                  data={this.newTour.instagram}
                  onChange={(e) => {
                    this.newTour.instagram = e;
                    this.setState({
                      beenEdit: { ...beenEdit, instagram: true },
                    });
                  }}
                  onMediaClick={(i) => {
                    this.mediaIs = "instagram";
                    this.instaIndex = i;
                  }}
                  setSiteRef={this.props.setSiteRef}
                  openOverlay={this.props.openOverlay}
                  defaultDragStart={this.defaultDragStart}
                  checkErrors={beenEdit.instagram}
                />
              )}
            </div>
          );
          break;

        case 6:
          let placeValues = [];
          this.places.forEach((element) => {
            placeValues.push(element.selectValue);
          });
          const addressText = this.newTour.address.name;
          const region = this.newTour.address.region;
          const zipCode = this.newTour.address.zipCode;

          content = (
            <div className="new-content six">
              <WhatsonNavigation
                isFront={this.props.isFront}
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
              />
              <h1>{this.language.location.header}</h1>
              <p>{this.language.location.sub_text}</p>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("place") && "enabled")}>
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("address") && this.submit.includes("place")}
                    onChange={(e) => {
                      if (!this.submit.includes("address") && !this.submit.includes("place")) {
                        this.submit.push("address");
                        this.submit.push("place");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              <div className="place-select-bool with-tooltip-2 no-margin">
                <label>
                  <VfiCheckbox
                    disabled={submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false}
                    className="not-located"
                    checked={this.state.placeIsNotInList}
                    onChange={() => {
                      this.setState({ placeIsNotInList: !this.state.placeIsNotInList }, () => {
                        this.newTour.isPlace = !this.state.placeIsNotInList;
                        if (this.newTour.isPlace === false) {
                          this.newTour.placeIsHost = false;
                        }
                      });
                    }}
                  />{" "}
                  <span className="text">{this.language.location.not_in_list}</span>{" "}
                </label>
                <GlobalToolTipController
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      Choose the location of the meeting point on the map. The map should be able to identify it
                      automatically. However, double-check that it is correct.
                      <br />
                      <br />
                      It's also a good idea to include a description, e.g. by the bridge, across the bakery etc.
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
                {/* <QuestionInfo>
                  {this.language.location.not_in_list_info}
                </QuestionInfo> */}
              </div>
              <div className={this.state.placeIsNotInList ? "in-list hidden" : "in-list"}>
                <p>{this.language.location.look_carefully}</p>
                <VfiCustomSelect
                  options={placeValues}
                  onChange={(e) => {
                    this.newTour.place = e;
                    this.setState({});
                  }}
                  value={this.newTour.place}
                  isDisabled={submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false}
                />
              </div>
              <div className={this.state.placeIsNotInList ? "not-in-list" : "not-in-list hidden"}>
                <SearchBox
                  value={this.newTour.address.name}
                  change={this.onAddressChange}
                  onChange={(e) => {
                    this.newTour.address.name = e;
                    this.setState({});
                  }}
                />
                <SimpleMap
                  marker={this.state.mapMarker}
                  markerHidden={!this.state.showMarker}
                  onClick={this.onMarkerChange}
                />
                <div className="more-values row">
                  <div className="region col-xl-6">
                    <p>{this.language.location.region}</p>
                    <VfiCustomSelect
                      onChange={(e) => {
                        this.setState({ region: e.value });
                        this.newTour.address.region = e;
                      }}
                      options={this.regions}
                      value={this.newTour.address.region}
                      isDisabled={
                        submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false
                      }
                      error={region === undefined}
                    />
                  </div>
                  <div className="zip-code col-xl-6">
                    <p>{this.language.location.zip_code}</p>
                    <VfiCustomSelect
                      onChange={(e) => {
                        this.setState({ zipCode: e.value });
                        this.newTour.address.zipCode = e;
                      }}
                      options={this.zipCodes}
                      value={this.newTour.address.zipCode}
                      isDisabled={
                        submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false
                      }
                      error={zipCode === undefined}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 7:
          content = (
            <div className="new-content seven">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateTour}
                customForwardText={
                  this.props.updateData === undefined
                    ? this.language.register.navigation_submit
                    : this.language.register.navigation_update
                }
                customForwardFunc={this.addOrUpdateTour}
              />
              <h1>{this.language.contact.header}</h1>
              <p>{this.language.contact.sub_text}</p>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("contactDetails") && "enabled")}>
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("contactDetails")}
                    onChange={(e) => {
                      if (!this.submit.includes("contactDetails")) {
                        this.submit.push("contactDetails");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              <div className="edit-contents">
                <div className="contact-content">
                  <p className="">{this.language.contact.company_name}:</p>
                  <VfiInputText
                    // key={beenEdit.contactDetails.company ? "1" : "0"}
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(JSON.stringify(this.state.contactDetails));
                      contactDetailsD.company = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            company: true,
                          },
                        },
                      });
                      this.newTour.contactDetails.company = e.target.value;
                    }}
                    defaultValue={this.newTour.contactDetails.company}
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    placeholder={this.language.contact.placeholder.company}
                    errorCheckSequence={beenEdit.contactDetails.company ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.full_name}:</p>
                  <VfiInputText
                    // key={beenEdit.contactDetails.name ? "1" : "0"}
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(JSON.stringify(this.state.contactDetails));
                      contactDetailsD.name = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            name: true,
                          },
                        },
                      });
                      this.newTour.contactDetails.name = e.target.value;
                    }}
                    defaultValue={this.newTour.contactDetails.name}
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    placeholder={this.language.contact.placeholder.name}
                    errorCheckSequence={beenEdit.contactDetails.name ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.social_link}:</p>
                  <VfiInputText
                    // key={beenEdit.contactDetails.socialLink ? "1" : "0"}
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(JSON.stringify(this.state.contactDetails));
                      contactDetailsD.socialLink = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            socialLink: true,
                          },
                        },
                      });
                      this.newTour.contactDetails.socialLink = e.target.value;
                    }}
                    defaultValue={this.newTour.contactDetails.socialLink}
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    placeholder={this.language.contact.placeholder.social}
                    errorCheckSequence={beenEdit.contactDetails.socialLink ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.website}:</p>
                  <VfiInputText
                    // key={beenEdit.contactDetails.website ? "1" : "0"}
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(JSON.stringify(this.state.contactDetails));
                      contactDetailsD.website = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            website: true,
                          },
                        },
                      });
                      this.newTour.contactDetails.website = e.target.value;
                    }}
                    defaultValue={this.newTour.contactDetails.website}
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    placeholder={this.language.contact.placeholder.web}
                    errorCheckSequence={beenEdit.contactDetails.website ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.email}:</p>
                  <VfiInputText
                    // key={beenEdit.contactDetails.email ? "1" : "0"}
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(JSON.stringify(this.state.contactDetails));
                      contactDetailsD.email = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            email: true,
                          },
                        },
                      });
                      this.newTour.contactDetails.email = e.target.value;
                    }}
                    defaultValue={this.newTour.contactDetails.email}
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    placeholder={this.language.contact.placeholder.email}
                    errorCheckSequence={beenEdit.contactDetails.email ? [Verify.notEmpty, Verify.validEmail] : []}
                  />
                </div>
                {/* <div className="contact-content">
                  <p className="">
                    {this.language.contact.country_code}:
                </p>
                  <VfiCustomSelect
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.countryCode = e;
                      this.setState({ contactDetails: contactDetailsD });
                      this.newTour.contactDetails.countryCode = e;
                    }}
                    options={this.zipCodes}
                    defaultValue={this.newTour.contactDetails.countryCode}
                    isDisabled={submitChange ? !(this.submit.includes('contactDetails')) : false}
                  />
                </div> */}
                <div className="contact-content">
                  <p className="">{this.language.contact.phone_number}:</p>
                  <div className="input">
                    <VfiCustomSelect
                      className="input-40"
                      // isDisabled={submitChange ? !(this.submit.includes('contactDetails')) : false}
                      onChange={(e) => {
                        // if (contactSuggested) {
                        //   changes.newValues[changes.title.indexOf("contactDetails")].countryCode = e.value;
                        // } else {
                        this.newTour.contactDetails.countryCode = e.value;
                        // }
                        this.setState({});
                      }}
                      // options={this.zipCodes}
                      // value={contactSuggested ?
                      //   this.zipCodes.find((e)=>e.value === parseInt(changes.newValues[changes.title.indexOf("contactDetails")].countryCode)) :
                      //   this.zipCodes.find((e)=>e.value === parseInt(this.newTour.contactDetails.countryCode))
                      // }
                      options={countryCodes.map(countryCodeReturn)}
                      value={
                        // contactSuggested ?
                        // countryCodes.find((e)=>e.value === parseInt(changes.newValues[changes.title.indexOf("contactDetails")].countryCode)) :
                        countryCodeReturn(
                          countryCodes.find((e) => parseInt(this.newTour.contactDetails.countryCode) === e.dial_code)
                        )
                      }
                    />
                    <VfiInputText
                      // key={beenEdit.contactDetails.phoneNumber ? "1" : "0"}
                      className="input-60"
                      disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                      onChange={(e) => {
                        // if (contactSuggested) {
                        //   changes.newValues[changes.title.indexOf("contactDetails")].phoneNumber = e.target.value;
                        // } else {
                        this.newTour.contactDetails.phoneNumber = e.target.value;
                        // }
                        this.setState({
                          beenEdit: {
                            ...beenEdit,
                            contactDetails: {
                              ...beenEdit.contactDetails,
                              phoneNumber: true,
                            },
                          },
                        });
                      }}
                      value={
                        // contactSuggested ? changes.newValues[changes.title.indexOf("contactDetails")].phoneNumber :
                        this.newTour.contactDetails.phoneNumber
                      }
                      placeholder={this.language.contact.placeholder.phone}
                      errorCheckSequence={beenEdit.contactDetails.phoneNumber ? [Verify.notEmpty] : []}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 8:
          content = (
            <div className="new-content eight">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={true}
                saveDraft={this.addOrUpdateTour}
                saveDraftDisabled={true}
                customForwardText={"To list"}
                customForwardFunc={this.props.closeTour}
              />
              {/* {this.props.updateData === undefined ? (
                <div>
                  <h1>{this.language.register.header_register}</h1>
                  <div className="new-edit">
                    <div className="final-text">
                      <FontAwesomeIcon icon={faChild} className="wooh" />
                      <p>
                        {this.user.firstname.toUpperCase() +
                          " " +
                          this.user.lastname.toUpperCase() +
                          ", "}
                        {this.language.register.final_text_register}
                      </p>
                    </div>
                  </div>{" "}
                </div>
              ) : (
                submitChange ?
                  <div>
                    <h1>{this.language.submit.header_submit}</h1>
                    <div className="new-edit">
                      <div className="final-text">
                        <FontAwesomeIcon icon={faChild} className="wooh" />
                        <p> {this.language.submit.final_text_submit}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                  :
                  <div>
                    <h1>{this.language.register.header_update}</h1>
                    <div className="new-edit">
                      <div className="final-text">
                        <FontAwesomeIcon icon={faChild} className="wooh" />
                        <p>
                          {this.user.firstname.toUpperCase() +
                            " " +
                            this.user.lastname.toUpperCase() +
                            ", "}
                          {this.language.register.final_text_update}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
              )} */}
              <h3>
                <b>Good old chap!</b>
              </h3>
              <p>Your tour is registered.</p>
              <div className="goose-image">
                <img src={Goose.thanksx2} />
              </div>
            </div>
          );
          break;
        default:
          content = "Should not get here...";
          break;
      }
    }
    const params = { showEn, showFo, beenEdit, newTour: this.newTour };
    this.descriptionPassed = descriptionPassed(params);
    this.quickFactsPassed = quickFactsPassed(params);
    this.mapPassed = mapPassed(params);
    this.datesPassed = datesPassed(params);
    this.pricesPassed = pricesPassed(params);
    this.imagesPassed = imagesPassed(params);
    this.placePassed = placePassed(params);
    this.contactDetailsPassed = contactDetailsPassed(params);
    this.allPassed =
      this.descriptionPassed &&
      this.quickFactsPassed &&
      this.datesPassed &&
      this.pricesPassed &&
      this.imagesPassed &&
      this.placePassed &&
      this.contactDetailsPassed;

    return (
      <div className="new-tour">
        <div className="close-tour" onClick={this.props.closeTour}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="header-display-wrap">
          <div className="header-display" ref={this.headerRef}>
            {this.props.stepBubble(
              faInfo,
              this.language.info.bubble_text,
              0,
              this.descriptionPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faList,
              this.language.quick_facts.bubble_text,
              1,
              this.quickFactsPassed,
              false,
              this.props.index === 8
            )}
            {/* {this.props.stepBubble(faClipboardList, this.language.details.bubble_text, 2,,
            false,
            this.props.index === 8 true)} */}
            {this.props.stepBubble(
              faMapMarkedAlt,
              this.language.map.bubble_text,
              2,
              this.mapPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faCalendarAlt,
              this.language.dates.bubble_text,
              3,
              this.datesPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faTag,
              this.language.price.bubble_text,
              4,
              this.pricesPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faImage,
              this.language.media.bubble_text,
              5,
              this.imagesPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faMapMarkerAlt,
              this.language.location.bubble_text,
              6,
              this.placePassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faUser,
              this.language.contact.bubble_text,
              7,
              this.contactDetailsPassed,
              false,
              this.props.index === 8
            )}
            {this.props.stepBubble(
              faCheck,
              this.props.updateData === undefined
                ? this.language.register.bubble_text_register
                : this.language.register.bubble_text_update,
              8,
              true,
              false,
              true
            )}
          </div>
        </div>
        <div className="w-content">{content}</div>
      </div>
    );
  }
}

export default NewTour;
