import React, { Component } from "react";
import env from "../../environment.json";
import "./Content.scss";
import MediaDisplay from "./MediaDisplay/MediaDisplay";
import HomeDisplay from "./HomeDisplay/HomeDisplay";
import Overlay from "./Overlay/Overlay";
import UsersDisplay from "./UsersDisplay/UsersDisplay";
import Shop from "./Shop/Shop";
import WhatsonDisplay from "./WhatsonDisplay/WhatsonDisplay";
import Verify from "./../../assets/VerifyMethods";
import ActivityDisplay from "./ActivityDisplay/ActivityDisplay";
import MainPages from "./Pages/MainPages";
import Analytics from "./Analytics/Analytics";
import MyWhatson from "./MyWhatson";
import globalObject from "../../assets/globalVariables";
import { clone, cloneDeep } from "lodash";
import AdminDisplay from "./AdminDisplay/AdminDisplay";
import ArticlesDisplay from "./ArticlesDisplay/ArticlesDisplay";
import { withRouterStore } from "../Router/routerStore";
import { getUsers } from "src/assets/helperFunctions";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlayDisplay: [],
      data: [], // Overlay data
      addOne: null,
      addContent: null,
      uploadedPhoto: "",
      formData: "",
      companies: [],
      response: "",
      newId: "",
      isBottom: false,
    };
    this.UsersDisplayElement = React.createRef();
    this.userContents = [];
    this.content = [];
    this.axios = require("axios");
    this.choosenUserPost = [];
    this.axios
      .post(env.protocol + env.env + "/api/secured/GetMyUser", {
        vfiUser: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        globalObject.vfiUser = response.data;
      })
      .catch((error) => console.error(error));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.site !== this.props.site || prevProps.show !== this.props.show) {
      this.setState({ overlayDisplay: "" }); //Close overlay if site or nav menu has changed
    }
  }

  componentDidMount() {
    window.onhashchange = (e) => {
      const nav = window.location.hash.split("/").slice(1);
      if (nav.length === 2 && nav[0] === "users") {
        const id = nav[1];
        var data = this.userContents.find((element) => element.id === id);
        if (this.userContents.length === 0 || data === undefined) {
          //If the userlist is not updated
          this.axios
            .post(env.protocol + env.env + "/api/secured/GetUser", {
              vfiUser: id,
            })
            .then((response) => {
              const e = response.data;
              this.openOverlay("userEdit", e);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const id = nav[1];
          const data = this.userContents.find((element) => element.id === id);
          if (this.props.addFilterDroppedDown[0] === true) {
            this.props.openCloseFilter([false, "user"], {});
          }
          this.openOverlay("userEdit", data);
        }
      }
    };
  }

  setChoosenUserPost = (val) => {
    this.choosenUserPost = val;
    this.setState({});
  };

  addOne = (data) => {
    this.setState({ addOne: data });
  };

  addContent = (newContent) => {
    this.setState({ addContent: newContent });
  };

  setSiteRef = (i) => {
    this.setState({ siteRef: i });
  };

  openOverlay = (whatOverlay, data) => {
    const copyData = clone(this.state.data);
    copyData.push(data);
    this.setState({
      overlayDisplay: whatOverlay,
      data: copyData,
    });
  };

  closeOverlay = (e) => {
    if (this.state.data[this.state.data.length - 1]?.afterFunc && e !== undefined) {
      this.state.data[this.state.data.length - 1]?.afterFunc(e);
    }
    //If sidebar clicked we want to close overlay
    const nav = window.location.hash.split("/");
    if (nav.length > 2) {
      const path = nav.slice(0, nav.length - 1).join("/");
      window.location.hash = path;
    }
    const copyData = clone(this.state.data);
    copyData.pop();
    this.setState({ overlayDisplay: "", data: copyData });
  };

  uploadPhoto = (file, imgConfig) => {
    //Before saving image
    const image = file[0];

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const copyData = clone(this.state.data);
        copyData[copyData.length - 1] = { ...copyData[copyData.length - 1], uploadedPhoto: reader.result };
        this.setState({
          data: copyData,
        });
      },
      false
    );
    reader.readAsDataURL(image);

    //IMG to save in database
    var formData = new FormData();
    formData.append("fileToUpload[]", file[0], file[0].name);

    this.setState({ formData: formData }, () => this.uploadPhotoDb(this.state.formData, imgConfig));
  };

  handleUserData = (response) => {
    this.userContents = [];
    response.data.forEach((e) => {
      this.userContents.unshift(e);
    });
    this.setState({});
  };

  uploadPhotoDb = (formData, imgConfig) => {
    //Upload profile picture and get id back

    this.axios.post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, imgConfig).then((res) => {
      const copyData = clone(this.state.data);
      copyData[copyData.length - 1] = {
        ...copyData[copyData.length - 1],
        localImg: res.data[0].id,
        localImg_extension: formData.getAll("fileToUpload[]")[0].name.split(".").slice(-1)[0], //Imgextension
      };
      this.setState({
        data: copyData,
      });
    });
  };

  addUserContent = () => {
    //when user er edited or added we want to add it manually until child renders
    if (this.UsersDisplayElement.current)
      this.UsersDisplayElement.current.addUserBeforeRefresh(this.state.data[this.state.data.length - 1]);
  };

  editUserContent = () => {
    //when user er edited or added we want to add it manually until child renders
    if (this.UsersDisplayElement.current)
      this.UsersDisplayElement.current.editUserBeforeRefresh(this.state.data[this.state.data.length - 1]);
  };

  setResponse = (res) => {
    this.setState({ response: res });
  };

  createUser = (whatOverlay) => {
    var instanceOfUser = {
      id: "",
      profilename: "",
      firstname: "",
      lastname: "",
      bio: "",
      email: "",
      psw: "",
      company: "",
      profession: globalObject.vfiUser.profession === "tour_operator" ? "tour_operator" : null,
      facebook: "",
      img: "",
      localImg: null,
      permission: "",
      permission_info: [],
      permissions: {},
      loginDates: [],
      regions: [],
    };
    this.props.listOptions.map((data, i) => {
      return instanceOfUser.permission_info.push({
        permission: "",
        siteId: data.index,
        roleName: "",
        siteName: data.name,
      });
    });
    this.openOverlay(whatOverlay, instanceOfUser);
  };

  handleScroll = (e) => {
    if (this.props.show !== "analytics" && this.props.show !== "Shop") {
      const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight;
      if (bottom && this.state.isBottom === false) {
        this.setState({ isBottom: true });
      }
      if (!bottom && this.state.isBottom === true) {
        this.setState({ isBottom: false });
      }
    }
  };

  handleOptionChange = (i, define) => {
    var instanceOf = this.state.data[this.state.data.length - 1].permission_info;
    if (define.toString() === instanceOf[i].permission) {
      //Checking box which is already checked
      instanceOf[i].permission = "";
    } else {
      instanceOf[i].permission = define.toString();
    }

    const copyData = clone(this.state.data);
    copyData[copyData.length - 1] = {
      ...copyData[copyData.length - 1],
      permission_info: instanceOf,
    };

    this.setState({
      //Sets state = instanceof
      data: copyData,
    });
  };

  clearAllOptionChange = (afterFunc = () => void 0) => {
    let copyData = cloneDeep(this.state.data);
    for (let i = 0; i < copyData.permission_info.length; i++) {
      copyData[copyData.length - 1].permission_info[i].permission = "";
      copyData[copyData.length - 1].permission_info[i].roleName = "";
    }
    this.setState({ data: copyData }, afterFunc);
  };

  render() {
    this.content = [
      {
        to: "/home",
        component: "",
      },
      {
        to: "/activity",
        component: "",
      },
      {
        to: "/pages",
        component: "",
      },
      {
        to: "/media",
        component: "",
      },
      {
        to: "/whatson",
        component: "",
      },
      {
        to: "/infoscreens",
        component: "",
      },
      {
        to: "/users",
        component: "",
      },
      {
        to: "/analytics",
        component: "",
      },
      {
        to: "/settings",
        component: "",
      },
      {
        to: "/shop",
        component: "",
      },
    ];

    let mainContent;

    let show = this.props?.routerStore?.route[0]?.nav;

    switch (show ? show.toLowerCase() : "") {
      case "home":
        mainContent = (
          <div className="home-content">
            <span className="shadow-maker" />
            <HomeDisplay
              site={this.props.site}
              setSiteRef={this.setSiteRef}
              contentShow={this.props.contentShow}
              openCloseFilter={this.props.openCloseFilter}
              addFilterDroppedDown={this.props.addFilterDroppedDown}
              activityUpdate={this.props.activityUpdate}
              navigateWhatson={this.props.navigateWhatson}
              navigateUser={this.props.navigateUser}
              navigatePages={this.props.navigatePages}
              navigateActivity={this.props.navigateActivity}
              choosenUserPost={this.choosenUserPost}
              setChoosenUserPost={this.setChoosenUserPost}
              notificationAmounts={this.props.notificationAmounts}
              store={this.props.store}
            />
          </div>
        );
        break;
      case "activity":
        mainContent = (
          <div className="activity-content">
            <span className="shadow-maker" />
            <ActivityDisplay
              site={this.props.site}
              openEdit={this.openOverlay}
              addOne={this.state.addOne}
              setSiteRef={this.setSiteRef}
              addFilterDroppedDown={this.props.addFilterDroppedDown}
              openCloseFilter={this.props.openCloseFilter}
              checkboxFiltering={this.props.checkboxFiltering}
              listOptions={this.props.listOptions}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              activityUpdate={this.props.activityUpdate}
              updateActUpt={this.props.updateActUpt}
              SetState={this.props.SetState}
              navigateWhatson={this.props.navigateWhatson}
              openOverlay={this.openOverlay}
            />
          </div>
        );
        break;
      case "pages":
        mainContent = (
          <MainPages
            language={this.props.language}
            setSiteRef={this.setSiteRef}
            openOverlay={this.openOverlay}
            newData={this.state.addOne}
            sessionSet={true}
            setConfirmBeforeNavigate={this.props.setConfirmBeforeNavigate}
            showInSidebar={this.props.showInSidebar}
            site={this.props.site}
          />
        );
        break;
      case "articles":
        mainContent = (
          <ArticlesDisplay
            language={this.props.language}
            adminMenu={"articles"}
            setSiteRef={this.setSiteRef}
            openOverlay={this.openOverlay}
            infoscreenMenu={this.props.infoscreenMenu}
            setConfirmBeforeNavigate={this.props.setConfirmBeforeNavigate}
            addOne={this.state.addOne}
          />
        );
        break;
      case "media":
        if (globalObject.vfiUser)
          mainContent = (
            <div className="media-content">
              <span className="shadow-maker" />
              <MediaDisplay
                site={this.props.site}
                onUpdateClick={() => {}}
                openEdit={this.openOverlay}
                addOne={this.state.addOne}
                setSiteRef={this.setSiteRef}
                filter={[] /*this.state.data.filter*/}
                showMore={this.state.isBottom}
                setBottom={(e) => this.setState({ isBottom: e })}
                sites={this.props.listOptions}
                handleUserData={this.handleUserData}
                users={this.userContents}
              />
            </div>
          );
        break;
      case "whatson":
        mainContent = (
          <div className="whatson-content">
            <span className="shadow-maker" />
            <WhatsonDisplay
              site={this.props.site}
              setSiteRef={this.setSiteRef}
              openOverlay={this.openOverlay}
              addOne={this.state.addOne}
              addContent={this.state.addContent}
              openCloseFilter={this.props.openCloseFilter}
              addFilterDroppedDown={this.props.addFilterDroppedDown}
              language={this.props.language}
              SetFilterData={this.props.SetFilterData}
              SetState={this.props.SetState}
              createNew={this.props.createNew}
              showMore={this.state.isBottom}
              setBottom={(e) => this.setState({ isBottom: e })}
              store={this.props.store}
            />
          </div>
        );
        break;
      case "users":
        mainContent = (
          <div className="user-content">
            <span className="shadow-maker" />
            <UsersDisplay
              ref={this.UsersDisplayElement}
              site={this.props.site}
              createUser={this.createUser}
              openEdit={this.openOverlay}
              listOptions={this.props.listOptions}
              SetState={this.props.SetState}
              handleUserData={this.handleUserData}
              checkboxFiltering={this.props.checkboxFiltering}
              addFilterDroppedDown={this.props.addFilterDroppedDown}
              openCloseFilter={this.props.openCloseFilter}
              userContents={this.userContents}
              createNew={this.props.createNew}
            />
          </div>
        );
        break;
      case "analytics":
        mainContent = (
          <div className="analytics-content">
            <span className="shadow-maker" />
            <Analytics />
          </div>
        );
        break;
      case "myplaces":
        mainContent = (
          <MyWhatson
            openOverlay={this.openOverlay}
            whatWhatson={"places"}
            addOne={this.state.addOne}
            addContent={this.state.addContent}
          />
        );
        break;
      case "mytours":
        mainContent = (
          <MyWhatson
            openOverlay={this.openOverlay}
            whatWhatson={"tours"}
            addOne={this.state.addOne}
            addContent={this.state.addContent}
          />
        );
        break;
      case "shop":
        mainContent = (
          <Shop
            language={this.props.language}
            setSiteRef={this.setSiteRef}
            openOverlay={this.openOverlay}
            newData={this.state.addOne}
            sessionSet={true}
            setConfirmBeforeNavigate={this.props.setConfirmBeforeNavigate}
            showInSidebar={this.props.showInSidebar}
            site={this.props.site}
          />
        );

        break;
      case "admin":
        mainContent = (
          <AdminDisplay
            language={this.props.language}
            adminMenu={"Sites"}
            setSiteRef={this.setSiteRef}
            openOverlay={this.openOverlay}
            infoscreenMenu={this.props.infoscreenMenu}
            setConfirmBeforeNavigate={this.props.setConfirmBeforeNavigate}
            newData={this.state.addOne}
            listOptions={[]}
          />
        );
        break;
      case "help":
        mainContent = <>Help</>;
        break;
      default:
        mainContent = "";
    }
    return (
      <div
        className={"content " + (this.state.overlayDisplay === "" ? "" : "noScroll")}
        onScroll={this.props.show !== "appearances" && this.props.show !== "pages" ? this.handleScroll : () => {}}
      >
        {mainContent}
        <Overlay
          display={this.state.overlayDisplay.length > 0 ? this.state.overlayDisplay : ""}
          data={this.state.data[this.state.data.length - 1]}
          closeOverlay={this.closeOverlay}
          language={this.props.language}
          siteRef={this.state.siteRef}
          addOne={this.addOne}
          addContent={this.addContent}
          // onChange={this.onChange}
          // saveBtn={this.saveData}
          uploadPhoto={this.uploadPhoto}
          handleOptionChange={this.handleOptionChange}
          clearAllOptionChange={this.clearAllOptionChange}
          checkboxFiltering={this.props.checkboxFiltering}
          listOptions={this.props.listOptions}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          SetState={this.props.SetState}
          panel={this.props.panel}
          handleSelect={this.handleSelect}
          openOverlay={this.openOverlay}
          setSiteRef={this.setSiteRef}
          showInSidebar={this.props.showInSidebar}
          addFilterDroppedDown={this.props.addFilterDroppedDown}
          openCloseFilter={this.props.openCloseFilter}
          uploadedPhoto={this.state.uploadedPhoto}
          response={this.state.response}
          setResponse={this.setResponse}
        />
      </div>
    );
  }
}

export default withRouterStore(Content);
