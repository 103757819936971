import styles from "./OvalButton.module.scss";

function OvalButton({
  children,
  onClick,
  isActive,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  isActive: boolean;
}) {
  const buttonClass = isActive ? `${styles.button} ${styles.active}` : styles.button;
  return (
    <button onClick={onClick} className={buttonClass}>
      {children}
    </button>
  );
}

export default OvalButton;
