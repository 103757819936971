import TimeSelectInput from "../input/TimeSelectInput/TimeSelectInput";
import styles from "./TimePicker.module.scss";
import { TTime } from "src/views/WhatsOn/stores/date-store/DateStore";

function TimePicker({
  time,
  errors = { hour: "", minute: "" },
  disabled,
  setTime,
}: {
  time: TTime;
  errors?: {
    hour: string;
    minute: string;
  };
  disabled: boolean;
  setTime: (time: TTime) => void;
}) {
  return (
    <div className={styles.timePickerContainer}>
      <TimeSelectInput
        currentValue={time?.hour ? time.hour : ""}
        setValue={(value) => setTime({ ...time, hour: value, minute: time.minute === null ? "00" : time.minute })}
        disabled={disabled}
        error={errors.hour}
        range={24}
        step={1}
        label="Hour"
      />
      <TimeSelectInput
        currentValue={time?.minute ? time.minute : ""}
        setValue={(value) => setTime({ ...time, minute: value })}
        disabled={disabled}
        error={errors.minute}
        range={60}
        step={5}
        label="Minute"
      />
    </div>
  );
}

export default TimePicker;
