import { language2short } from "src/utils/systemLanguageUtils";
import { TLanguageObj } from "./TLanguage";
import languageObj from "./language.json";
import { TSystemLanguage } from "src/types/types/TSystemLanguage";
import { TWhatsonType } from "src/types/types/Whatson";
import Goose from "../../../assets/images/Goose";
import styles from "./SubmitEntity.module.scss";
import { useUserStore } from "src/Components/ZustandStores/userStore";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";
import { whatsonType2PermissionType } from "src/utils/whatsonUtils";
import { useWhatsonStore } from "src/Components/ZustandStores/whatsonStore";
import OptionsList from "src/assets/OptionsList";
import { WHATSON_LANGUAGE } from "src/types";
import { MouseEventHandler, useState } from "react";
import { IPosition } from "src/assets/OptionsList/OptionsList";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { routerStore, useRouterStore } from "src/Components/Router/routerStore";

interface props {
  type: TWhatsonType;
  language: TSystemLanguage;
  internal?: boolean;
  whatsonReferId?: number;
  hasAutoApproval: boolean;
  hideNavigationButtons?: boolean;
  indexChange: (i: number) => void;
  enableEdit?: () => void;
}
export default function SuccessView({
  type,
  internal = false,
  language,
  whatsonReferId,
  hasAutoApproval,
  hideNavigationButtons = false,
  indexChange,
  enableEdit = () => {},
}: props) {
  const [previewLangListIsOpen, setPreviewLangListIsOpen] = useState(false);
  const [previewLangListPos, setPreviewLangListPos] = useState<IPosition>();

  const { getWhatsonSiteInfo } = useWhatsonStore();

  const languageText: TLanguageObj = languageObj;
  const shortLang = language2short(language);

  const { loggedInUser } = useUserStore();
  const { permCheckFunc } = usePermCheckStore();
  const isLoggedIn = loggedInUser.userId !== undefined;
  // const showEdit = permCheckFunc({
  //   type: whatsonType2PermissionType(type),
  //   minimumRole: "Publisher",
  //   id: whatsonReferId,
  // });

  const previewClick = (lang: WHATSON_LANGUAGE) => {
    getWhatsonSiteInfo().then((response) => {
      const website = (response?.protocol ?? "") + (response?.domain_name ?? "");
      const middlePaths = {
        event: "/events/event/",
        place: "/places/place/",
        tour: "/tours/tour/",
        tour_provider: "/tour_providers/provider/",
        hike: "/hiking/hike/",
      } as const;
      const middlePath = middlePaths[type];
      const slug = "example";
      // TODO: A whatson store that is the entity that we edit should be made. When this is done, get the slug to preview.
      window.open(website + middlePath + slug, "_blank")?.focus();
    });
  };

  const openPreviewOptionsList: MouseEventHandler<HTMLButtonElement> = (event) => {
    setPreviewLangListIsOpen(true);
    setPreviewLangListPos({ x: event.clientX, y: event.clientY });
  };

  return (
    <div className={styles["success-view"]}>
      <h3>
        <b>{languageText[type].success_header[shortLang]}</b>
      </h3>
      <p className="register-sub-header">
        {hasAutoApproval
          ? whatsonReferId
            ? languageText[type].success_description_updated[shortLang]
            : languageText[type].success_description_published[shortLang]
          : languageText[type].success_description_awainting_approval[shortLang]}
      </p>
      <div className={styles["goose-image"]}>
        <img src={Goose.thanksx2} />
      </div>
      {!hideNavigationButtons && (
        <div className={styles["complete-buttons"]}>
          {isLoggedIn && internal ? (
            <>
              {hasAutoApproval && (
                <button
                  className={styles["edit"]}
                  onClick={() => {
                    enableEdit();
                    indexChange(-9999);
                  }}
                >
                  Edit
                </button>
              )}
              <button className={styles["blue"]} onClick={openPreviewOptionsList}>
                Preview {type.replaceAll("_", " ")}
              </button>
            </>
          ) : (
            <>
              <button
                className={styles["gray"]}
                onClick={() => {
                  const get_parameters = useRouterStore.getState().get_parameters;

                  if (get_parameters?.returnUrl) window.location.href = get_parameters.returnUrl;
                }}
              >
                Back to What's On
              </button>
              <button
                className={styles["blue"]}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Submit new {type.replaceAll("_", " ")}
              </button>
            </>
          )}
        </div>
      )}
      <OptionsList
        isOpen={previewLangListIsOpen}
        close={() => setPreviewLangListIsOpen(false)}
        options={[
          { is: "choice", onClick: () => previewClick("en"), text: "EN", icon: faFile },
          { is: "choice", onClick: () => previewClick("fo"), text: "FO", icon: faFile },
        ]}
        position={previewLangListPos}
      />
    </div>
  );
}
