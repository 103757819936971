import React, { Component } from "react";
import env from "../../../../environment.json";
import {
  faInfo,
  faList,
  faCheck,
  faBuilding,
  faMapMarkerAlt,
  faImage,
  faTag,
  faTimes as faTimes2,
  faCalendarAlt,
  faPencil,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faChild, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import WhatsonNavigation from "../WhatsonNavigation";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import "./NewEvent.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import { VfiCheckbox2 as VfiCheckbox, VfiCheckbox2 } from "../../../../assets/VfiCheckbox";
import Dates from "../../../../assets/Dates/Dates";
import Prices from "../../../../assets/Prices/Prices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import zipCodes from "../../../../assets/zipCodes.json";
import { SimpleMap, SearchBox } from "../../../../assets/GoogleMaps";
import Verify from "../../../../assets/VerifyMethods";
import { allObjTrue, removeTags, slugify, countryCodeReturn } from "../../../../assets/helperFunctions";
import loadash, { cloneDeep } from "lodash";
import language from "../language.json";
import VfiCustomSelect from "../../../../assets/VfiCustomSelect";
import goose from "../../../../assets/images/Goose";
import countryCodes from "../../../../assets/countryCodes.json";
import InstagramInWhatson from "../../../../assets/InstagramInWhatson";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  categoriesPassed,
  contactDetailsPassed,
  datesPassed,
  descriptionPassed,
  imagesPassed,
  placePassed,
  pricesPassed,
} from "./EventVerify";
import globalObject from "../../../../assets/globalVariables";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import { withUserStore } from "src/Components/ZustandStores/userStore";
import torshavnRegions from "../TorshavnRegions.json";
import WhatsonSessionEditView from "../WhatsonSessionEditView";
import MyUnloadComponent from "src/assets/MyUnloadComponent";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import DefaultPopup from "src/assets/DefaultPopup";
import { useRouterStore } from "src/Components/Router/routerStore";
import UploadMedia from "../common/media/upload-media/UploadMedia";
import axios from "axios";
import { useSitesStore } from "src/Components/ZustandStores/sitesStore";
import VideoLinks from "src/assets/WhatsonEditComponents/VideoLinks";
import MapSummaryRow from "./MapSummaryRow";

class NewEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frontFinnished: false,
      addMediaDroppedDown: false,
      extraVideoLink: [],
      placeIsNotInList: false,
      mapMarker: undefined,
      showMarker: false,
      mapCenter: { lat: 62, lng: -6.9 },
      mapZoom: 8,
      uploadedFile: undefined,
      beenEdit: {
        textFo: {
          headline: false,
          description: false,
          shortDescription: false,
          mapSummary: false,
        },
        textEn: {
          headline: false,
          description: false,
          shortDescription: false,
          mapSummary: false,
        },
        categories: false,
        dates: false,
        isFree: false,
        images: { mainImages: false },
        urls: { extraVideoLinks: false },
        instagram: false,
        isPlace: false,
        maxCapacity: false,
        contactDetails: {
          name: false,
          company: false,
          socialLink: false,
          website: false,
          email: false,
          phoneNumber: false,
        },
      },
      fromWhatsonFront: false,
      sessionMaxAge: undefined,
      sessionTimestamp: undefined,
      // BEEN EDIT DATA *DO NOT CROSS* - BEEN EDIT DATA *DO NOT CROSS* - BEEN EDIT DATA *DO NOT CROSS* - BEEN EDIT DATA *DO NOT CROSS*
    };

    this.dateChange = this.dateChange.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onMarkerChange = this.onMarkerChange.bind(this);
    this.defaultDragStart = this.defaultDragStart.bind(this);

    this.headerRef = React.createRef();

    this.axios = require("axios");
    this.user = { firstname: "", lastname: "" };

    this.maxCategories = 3;

    this.protocolAndHost = env.protocol + env.env;

    this.zipCodes = [];
    for (let element in zipCodes) {
      this.zipCodes.push({
        value: zipCodes[element].code,
        label: zipCodes[element].code + " " + zipCodes[element].city,
      });
    }

    this.submitDescription = language[this.props.language].suggest_edit_description;
    this.language = language[this.props.language].events.new;

    this.submit = [];
    this.changes = {
      id: "",
      eventId: "",
      title: [],
      currentOrSuggested: [],
      newValues: [],
      categories: [],
      categoriesEdited: [],
    };

    this.newEvent = {
      textEn: {
        showIt: true,
        headline: "",
        shortDescription: "",
        mapSummary: "",
        description: "",
        urlSlug: "",
      },
      textFo: {
        showIt: true,
        headline: "",
        shortDescription: "",
        mapSummary: "",
        description: "",
        urlSlug: "",
      },
      categories: [],
      dates: {
        occurence: undefined,
        // { value: "single", label: "Single Date" }
      },
      isFree: true,
      prices: { prices: [{}], payUrl: "" },
      maxCapacity: 0,
      images: { mainImages: [] },
      isPlace: true,
      place: undefined,
      urls: { extraVideoLinks: [{ key: 1, url: "" }] },
      address: {},
      placeIsHost: true,
      contactDetails: {
        name: "",
        company: "",
        socialLink: "",
        website: "",
        email: "",
        phoneNumber: "",
        countryCode: 298,
      },
      instagram: {
        active: false,
        profile: "",
        hashtag: "",
        images: [],
      },
      nonUser: this.props.nonUser,
    };

    this.unuploadedMedia = {
      mainImages: [],
      instaImages: [],
    };

    this.placeId = 0;
  }

  componentDidMount() {
    const { setRouterStore } = useRouterStore.getState();
    setRouterStore({ route_intercept: true });
    if (this.props.updateData !== undefined) {
      const data = this.props.updateData;

      this.setState({
        beenEdit: allObjTrue(this.state.beenEdit),
        sessionMaxAge: data.editSession?.MAX_AGE,
        sessionTimestamp: data.editSession?.timestamp,
      });

      // If not front then we want to check for updates
      if (!this.props.submitChange && !this.props.isFront && this.props.updateData.submittedChanges) {
        let newValues = this.props.updateData.submittedChanges.newValues.replace(/\n/g, "\\n"); //Somehow it cannot read the JSON object correct and we make it work here
        this.changes = {
          id: this.props.updateData.submittedChanges.id,
          eventId: this.props.updateData.submittedChanges.eventId,
          title: JSON.parse(this.props.updateData.submittedChanges.title),
          titleEdited: JSON.parse(this.props.updateData.submittedChanges.title),
          newValues: JSON.parse(newValues),
          categories: [],
          categoriesEdited: [],
          currentOrSuggested: [],
        };

        this.changes.currentOrSuggested = Array(this.changes.title.length).fill("");

        //Only get unique categories
        this.changes.title.forEach((x) => {
          var cate = x.includes(".") ? x.split(".")[0] : x;

          if (this.changes.categories.indexOf(cate) === -1) {
            this.changes.categories.push(cate);
            this.changes.categoriesEdited.push(cate);
          }
        });
      }

      let textEn = {};
      let textFo = {};

      Object.entries(data.text).forEach((entry) => {
        const [key, value] = entry;
        if (key === "english") {
          textEn = {
            headline: value.headline,
            shortDescription: value.excerpt,
            mapSummary: value.mapSummary,
            description: value.description,
            urlSlug: value.url_slug,
            showIt: value.show_it === 1 ? true : false,
          };
        }
        if (key === "faroese") {
          textFo = {
            headline: value.headline,
            shortDescription: value.excerpt,
            mapSummary: value.mapSummary,
            description: value.description,
            urlSlug: value.url_slug,
            showIt: value.show_it === 1 ? true : false,
          };
        }
      });

      let mainImages = [];

      Object.entries(data.images).forEach((entry) => {
        const [key, value] = entry;

        let imgKey = 0;
        if (key === "main") {
          value.forEach((e) => {
            mainImages.push({
              key: imgKey,
              id: e.id_media,
              file_name: e.file_name,
              alt: e.alt,
              file_extension: e.file_extension,
              enText: e.english.text,
              foText: e.faroese.text,
            });
            imgKey++;
          });
        }
      });

      const dateOccurence = data.date.occurence;
      let dates = {};
      switch (dateOccurence) {
        case "single":
          dates = {
            occurence: { label: "Single Date", value: dateOccurence },
            startDate: new Date(data.date.dates[0].start_date),
            startTime: data.date.dates[0].start_time,
            endTime: data.date.dates[0].end_time,
            dateVisible: new Date(data.date.when_visible),
          };
          break;
        case "many":
          dates = {
            occurence: { label: "Many Dates", value: dateOccurence },
            startDate: new Date(data.date.dates[0].start_date),
            endDate: new Date(data.date.dates[0].end_date),
            startTime: data.date.dates[0].start_time,
            endTime: data.date.dates[0].end_time,
            days: {
              monday: data.date.monday,
              tuesday: data.date.tuesday,
              wednesday: data.date.wednesday,
              thursday: data.date.thursday,
              friday: data.date.friday,
              saturday: data.date.saturday,
              sunday: data.date.sunday,
            },
            dateVisible: new Date(data.date.when_visible),
          };
          break;
        case "specific":
          let specificDates = [];

          data.date.dates.forEach((element, i) => {
            specificDates.push({
              key: i + 1,
              date: new Date(element.start_date),
              startTime: element.start_time,
              endTime: element.end_time,
            });
          });

          dates = {
            occurence: { label: "Multiple Dates", value: dateOccurence },
            dateVisible: new Date(data.date.when_visible),
            specificDates,
          };
          break;
        default:
          break;
      }

      let prices = [];

      data.prices.forEach((element, i) => {
        prices.push({
          key: i + 1,
          price: element.price,
          foLabel: element.fo_label,
          enLabel: element.en_label,
        });
      });

      let extraVideoLinks = [];

      data.video_links.forEach((element, i) => {
        extraVideoLinks.push({
          key: i + 1,
          url: element.video_link,
        });
      });

      this.placeId = data.data.place_id;
      // const unknown_place = JSON.parse(data.data.unknown_place);

      // const name = unknown_place.name;
      // const mapMarker = unknown_place.mapMarker;
      // const mapCenter = unknown_place.mapCenter;
      // //const includeMap = data.data.address_include_map === "1" ? true : false; // NOT MADE IN DB..
      // const mapZoom = 15;
      // const showMarker = true;
      // const region = unknown_place.region;
      // const zipCode = unknown_place.zipCode;

      const address = {
        //   name,
        //   mapMarker,
        //   mapCenter,
        //   //includeMap,
        //   mapZoom,
        //   showMarker,
        //   region,
        //   zipCode,
      };

      // if (mapCenter)
      //   this.state = {
      //     ...this.state,
      //     mapMarker,
      //     showMarker: true,
      //     mapCenter,
      //     mapZoom: 15,
      //   };

      const name = data.data.organizer_name;
      const company = data.data.organizer_company;
      const socialLink = data.data.organizer_social;
      const website = data.data.organizer_website;
      const email = data.data.organizer_email;
      const phoneNumber = data.data.organizer_phone;
      const countryCode = data.data.organizer_zip;

      const contactDetails = {
        name,
        company,
        socialLink,
        website,
        email,
        phoneNumber,
        countryCode,
      };

      let instagram = {
        active: data.data.instagram_active ? true : false,
        hashtag: data.data.instagram_hashtag,
        profile: data.data.instagram_profile,
        // images: [],
        images: data.instagram.map((element, i) => {
          return {
            instaLink: element.url,
            media:
              element.id_media !== null
                ? {
                    id: element.id_media,
                    alt: element.alt,
                    file_name: element.file_name,
                    extension: element.extension,
                  }
                : {},
            key: i,
          };
        }),
      };

      this.newEvent = {
        id: data.data.id,
        textFo,
        textEn,
        categories: data.categories,
        dates,
        isFree: data.data.is_free === 1 ? true : false,
        prices: { prices, payUrl: data.data.pay_url },
        maxCapacity: data.data.capacity,
        images: {
          mainImages,
        },
        isPlace: true,
        place: undefined,
        urls: { extraVideoLinks },
        address,
        placeIsHost: data.data.place_is_host === 1 ? true : false,
        contactDetails,
        instagram,
      };
    }
    this.newEventSaved = loadash.cloneDeep(this.newEvent);

    window.parent.postMessage(
      {
        msg: "registration successful",
        resize: {
          height: 1200,
        },
      },
      "*"
    );

    this.setState({
      fromWhatsonFront: new URLSearchParams(window.location.search).get("fromWhatsonFront") === "true",
    });

    const vfiUser = sessionStorage.getItem("vfiUser");
    this.axios
      .post(env.protocol + env.env + "/api/secured/GetUserInfo", {
        vfiUser,
      })
      .then((response) => {
        this.user = response.data;
      })
      .catch((error) => console.error(error));

    this.places = [];
    this.axios
      .post(env.protocol + env.env + "/api/whatson/places/GetPlacesList?ignore_author")
      .then((response) => {
        response.data.forEach((element) => {
          if (element.current_status !== "deleted")
            this.places.push({
              selectValue: {
                label: element.headline,
                value: element.id,
              },
              region: element.address_region !== "null" ? element.address_region : "",
            });
        });

        this.places = this.places.filter((e) => e.selectValue.label !== null);
        this.places.sort((a, b) => {
          return a.selectValue.label.toLowerCase() > b.selectValue.label.toLowerCase();
        });

        if (this.props.updateData !== undefined) {
          this.places.forEach((element) => {
            if (element.selectValue.value === this.placeId)
              this.newEvent.place = JSON.parse(JSON.stringify(element.selectValue));
          });
        } else {
          // this.newEvent.place = { label: "", value: "" };
          // if (this.places.length > 0) {
          //   const fPlace = this.places[0];
          //   this.newEvent.place = fPlace.selectValue;
          // }
        }
        this.newEventSaved = loadash.cloneDeep(this.newEvent);
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });

    this.regions = [];
    this.axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        const regions = response.data;
        regions.forEach((element) => {
          this.regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.categories = [];
    this.axios
      .get(env.protocol + env.env + "/api/whatson/events/GetEventCategories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => console.error(error));

    if (!this.props.isFront && this.props.creatingANew) {
      this.saveInterval = setInterval(() => {
        if (!loadash.isEqual(this.newEventSaved, this.newEvent)) {
          // Do the auto-save
          const vfiUser = sessionStorage.getItem("vfiUser");

          const link = env.protocol + env.env + "/api/secured/whatson/events/AddEvent";
          let isNewEvent;
          if (this.props.updateData !== undefined) {
            isNewEvent = false;
          } else {
            isNewEvent = true;
          }
          this.axios
            .post(link, {
              ...this.newEvent,
              currentStatus: "draft",
              vfiUser,
            })
            .then((response) => {
              let newValue = response.data;

              this.props.userStore.getLoggedInUser();

              this.props.autoSave(newValue, isNewEvent);

              this.newEvent.id = newValue.id;
              this.newEventSaved = loadash.cloneDeep(this.newEvent);
            })
            .catch((error) => console.error(error));
        }
      }, 30000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
    const { setRouterStore } = useRouterStore.getState();
    setRouterStore({ route_intercept: false });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addOne !== this.props.addOne) {
      if (prevProps.addOne !== this.props.addOne) {
        const media = this.props.addOne;
        if (media.fileExtension === undefined) media.fileExtension = media.file_extension;

        let maxKey = 0;
        switch (this.mediaIs) {
          case "mainImage":
            {
              this.newEvent.images.mainImages.forEach((element) => {
                if (element.key > maxKey) maxKey = element.key;
              });
              media.forEach((m) => {
                this.newEvent.images.mainImages.push({
                  key: maxKey + 1,
                  id: m.id,
                  alt: m.alt,
                  file_name: m.file_name,
                  file_extension: m.fileExtension,
                });
              });
              const beenEdit = this.state.beenEdit;
              this.setState({
                beenEdit: {
                  ...beenEdit,
                  images: { ...beenEdit.images, mainImages: true },
                },
              });
            }
            break;
          case "instagram":
            {
              this.newEvent.instagram.images[this.instaIndex].media = {
                ...media,
                extension: media.fileExtension,
              };
              const beenEdit = this.state.beenEdit;
              this.setState({
                beenEdit: { ...beenEdit, instagram: true },
              });
            }
            break;
          default:
            break;
        }
        this.setState({});
      }
    }

    const headerElement = this.headerRef.current;
    const index = this.props.index;
    if (headerElement && index !== undefined) {
      headerElement.scroll({
        left: 73 * index + 51.5 - headerElement.parentNode.scrollWidth / 2,
        behavior: "smooth",
      });
    }
  }

  returnToSubmit() {
    // This function returns the user to the submit page if they're registering an event through whatson.

    window.parent.postMessage(
      {
        createStatus: "successful",
      },
      "*"
    );
  }

  addContent(destination, value) {
    if (this.changes.currentOrSuggested[this.changes.title.indexOf(destination)] === "suggested") {
      //If suggested changes
      this.changes.newValues[this.changes.title.indexOf(destination)] = value;
    } else {
      if (typeof destination === "string") destination = destination.split(".");

      destination.reverse().forEach((element, i) => {
        value = { [element]: value };
      });

      loadash.merge(this.newEvent, value);
    }
  }

  uniqueSlug(slug, assignDestination, localSlugs, language) {
    this.axios
      .post(env.protocol + env.env + "/api/secured/whatson/events/UniqueSlug", {
        slug,
        localSlugs,
        eventId: this.newEvent.id,
        language,
      })
      .then((response) => {
        this.addContent(assignDestination, response.data);
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  dateChange(e) {
    this.newEvent.dates = e;
    const beenEdit = this.state.beenEdit;
    this.setState({ beenEdit: { ...beenEdit, dates: true } });
  }

  priceChange(e) {
    this.newEvent.prices = e;
    this.setState({});
  }

  uploadFileandSubmit = () => {
    var mainImages = this.newEvent.images.mainImages;
    var img = mainImages.filter((img) => img.elm !== undefined);
    var newImages = mainImages.filter((img) => img.elm === undefined);

    var newInstaImages = this.newEvent.instagram.images
      .map((e, i) => {
        return { ...e, actualIndex: i };
      })
      .filter((e) => e.media.newFile);

    this.props.blurScreen(true, 0);
    if ((img.length > 0 || newInstaImages.length > 0) && this.allPassed) {
      //Uploada media

      this.uploadFrontendImages(img, newImages, newInstaImages, () => {
        this.submitChange();
        this.props.blurScreen(false);
      });
    } else {
      this.submitChange();
      this.props.blurScreen(false);
    }
  };

  multipartConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      this.props.blurScreen(true, percentCompleted);
    },
  };

  uploadFileAndEvent = () => {
    //Gera myndirnar klárar
    if (!this.allPassed) return;
    this.props.blurScreen(true, 0);

    const link =
      env.protocol +
      env.env +
      (this.props.editToken
        ? "/api/public/whatson/events/UpdateEventWithToken"
        : "/api/public/whatson/events/AddEventNonUser");
    this.props.blurScreen(true, 0);

    const formData = new FormData();
    const newEventData = cloneDeep(this.newEvent);
    newEventData.currentStatus = "published";
    newEventData.canCreatePlace = true;
    newEventData.token = this.props.editToken;
    for (let i = 0; i < newEventData.images.mainImages.length; i++) {
      newEventData.images.mainImages[i].elm = "";
    }
    formData.append("main_data", JSON.stringify(newEventData));
    formData.set("main_images[]", []);
    this.unuploadedMedia.mainImages.forEach((e) => {
      formData.append("main_images[]", e, e.name);
    });
    formData.set("insta_images[]", []);
    this.unuploadedMedia.instaImages.forEach((e) => {
      formData.append("insta_images[]", e, e.name);
    });
    this.publish(false, link, formData, this.multipartConfig);
  };

  saveFile = (e) => {
    //Saving images and adding them to mainimage so they can be shown. But not uploaded.
    //Goyma hana sum temp fíl
    let maxKey = 0;
    this.newEvent.images.mainImages.forEach((element) => {
      if (element.key > maxKey) maxKey = element.key;
    });

    var images = this.newEvent.images.mainImages;
    e.forEach((element, i) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          images.push({
            key: maxKey + i + 1,
            elm: reader.result,
            element: element,
            name: element.name,
          });
        },
        false
      );
      reader.readAsDataURL(element);
      reader.addEventListener("loadend", () => this.setState({}));
    });
    this.newEvent.images.mainImages = images;
    this.setState({ addMediaDroppedDown: false });
  };

  onAddressChange(data) {
    const lat = data.geometry.location.lat(data.formatted_address);
    const lng = data.geometry.location.lng(data.formatted_address);

    if (data.address_components)
      this.regionAndZipPopulate({
        results: [{ address_components: data.address_components }],
      });

    this.setState({
      address: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    });
    if (this.changes.currentOrSuggested[this.changes.title.indexOf("address")] === "suggested") {
      //Being able to edit suggested changes
      this.changes.newValues[this.changes.title.indexOf("address")].name = data.formatted_address;
      this.changes.newValues[this.changes.title.indexOf("address")].mapMarker = { lat: lat, lng: lng };
      this.changes.newValues[this.changes.title.indexOf("address")].mapCenter = { lat: lat, lng: lng };
      this.changes.newValues[this.changes.title.indexOf("address")].mapZoom = 15;
      this.changes.newValues[this.changes.title.indexOf("address")].showMarker = true;
      this.setState({});
    } else {
      this.newEvent.address = {
        ...this.newEvent.address,
        name: data.formatted_address,
        mapCenter: { lat: lat, lng: lng },
        mapZoom: 15,
        showMarker: true,
        mapMarker: { lat: lat, lng: lng },
      };
    }
  }

  onMarkerChange(e) {
    if (e.geocode) this.regionAndZipPopulate(e.geocode);

    if (e.geocode?.results[0]) {
      this.newEvent.address.name = e.geocode.results[0].formatted_address;
    }

    const event = e.mapMouseEvent;

    this.setState({
      mapMarker: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      mapCenter: { lat: event.latLng.lat(), lng: event.latLng.lng() },
      showMarker: true,
    });
    if (this.changes.currentOrSuggested[this.changes.title.indexOf("address")] === "suggested") {
      this.changes.newValues[this.changes.title.indexOf("address")].mapMarker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.changes.newValues[this.changes.title.indexOf("address")].mapCenter = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.changes.newValues[this.changes.title.indexOf("address")].showMarker = true;
      this.setState({});
    } else {
      this.newEvent.address = {
        ...this.newEvent.address,
        mapMarker: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        mapCenter: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        showMarker: true,
      };
    }
  }

  regionAndZipPopulate(data) {
    var zip = -1;
    var region = -1;
    const results = data.results;

    for (var i = 0; i < results.length; i++) {
      //found the address
      var result = results[i];

      for (var j = 0; j < result.address_components.length; j++) {
        var component = result.address_components[j];
        if (
          component.types.filter(function (x) {
            return x === "route";
          }).length > 0
        ) {
          //  street = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "street_number";
          }).length > 0
        ) {
          //  number = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "postal_code";
          }).length > 0
        ) {
          zip = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "administrative_area_level_1";
          }).length > 0
        ) {
          region = component.long_name;
        }
      }
      break;
    }

    if (region === "Northern Isles") {
      region = "Norðoyggjar";
    }
    if (region === "Suduroy") {
      region = "Suðuroy";
    }
    if (region === "Eysturoyar") {
      region = "Eysturoy";
    }
    if (region === "Streymoyar") {
      region = "Streymoy";
    }

    if (torshavnRegions.includes(parseInt(zip))) {
      region = "Tórshavn";
    }

    this.regions.forEach((element) => {
      if (element.label === region) {
        this.newEvent.address.region = this.regions.find((e) => e.label === region);
      }
    });

    Object.keys(zipCodes).forEach((k, v) => {
      if (zipCodes[k].code.toString() === zip) {
        const zipLabel = zip + " " + zipCodes[k].city;
        this.newEvent.address.zipCode = { label: zipLabel, value: zip };
      }
    });
  }

  defaultDragStart(e) {
    this.setState({ imageDragging: true });
  }
  defaultDragStop(e) {
    this.setState({ imageDragging: false });
  }

  submitChange = () => {
    this.props.blurScreen(true, 0);
    if (this.allPassed) {
      this.axios
        .post(env.protocol + env.env + "/api/public/whatson/events/UpdateEventWithToken", {
          // data: this.newEvent,
          ...this.newEvent,
          changes: this.submit,
          userId: this.props.userFront,
          token: this.props.editToken,
        })
        .then((res) => {
          this.props.blurScreen(false);
          if (this.props.index < 7) this.props.indexChange(1);
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  };

  addOrUpdateEvent = (isDraft = false) => {
    if (isDraft) {
      const { addOverlay } = overlayStore.getState();

      addOverlay("card", (close) => {
        return (
          <DefaultPopup
            text={
              <>
                Are you sure you want to
                <br />
                save this event as draft?
              </>
            }
            okText="Yes, save as draft"
            // okButtonStyle={{ backgroundColor: "#cf323b" }}
            okClick={() => {
              this.addOrUpdateEventProcess(isDraft);
              close();
            }}
            cancelClick={close}
          />
        );
      });
    } else {
      this.addOrUpdateEventProcess(isDraft);
    }
  };

  addOrUpdateEventProcess = (isDraft = false) => {
    //Uploada mynd um vit eru í front end
    if (this.props.isFront) {
      // DO NOT REMOVE OUT-COMMENT YET.. Submit could maybe be an option later
      // if (this.props.submitChange === "true") {
      //   this.uploadFileandSubmit();
      // } else {
      this.uploadFileAndEvent();
      // }
    } else {
      if (this.changes.title.length > 0) {
        //If there are submitted changes, we want to merge them with this.newEvent
        if (this.changes.categoriesEdited.length === 0) {
          //All submitted changes have been processed or no submitted changes have been made

          for (var i = 0; i < this.changes.currentOrSuggested.length; i++) {
            if (this.changes.currentOrSuggested[i] === "suggested") {
              if (this.changes.title[i].includes(".")) {
                //E.g. textEn.headline
                let path = this.changes.title[i].split(".");
                this.newEvent[path[0]][path[1]] = this.changes.newValues[i];
              } else {
                this.newEvent[this.changes.title[i]] = this.changes.newValues[i];
              }
            }
          }

          this.setState({});
        } else {
          console.error("ERROR, not all changes have been processed");
        }
      }

      if (this.changes.categoriesEdited.length === 0) {
        //If normal update or if all changes have been processed
        let link = "";
        if (this.props.editToken) {
          link = env.protocol + env.env + "/api/public/whatson/events/UpdateEventWithToken";
        } else {
          link = env.protocol + env.env + "/api/secured/whatson/events/AddEvent";
        }

        if (isDraft) {
          this.publish(true, link);
        } else {
          let edited = Object.assign({}, this.state.beenEdit);
          edited = allObjTrue(edited);

          this.setState({ beenEdit: edited }, () => {
            if (this.allPassed) {
              this.publish(false, link);
            }
          });
        }
      }
    }
  };

  publish = (isDraft, link, data, config) => {
    // this.props.blurScreen(true);
    let vfiUser = sessionStorage.getItem("vfiUser");

    if (this.props.isFront) {
      this.newEvent.address.currentStatus = "published";
      vfiUser = this.props.userFront;
    }
    clearInterval(this.saveInterval);
    const contentToSend = data ?? {
      ...this.newEvent,
      currentStatus: isDraft ? "draft" : "published",
      vfiUser,
      canCreatePlace: true,
    };
    const configs = config ?? {
      headers: {
        "Content-Type": "text/json",
      },
    };
    axios
      .post(link, contentToSend, configs)
      .then((response) => {
        if (!isDraft) {
          const { setRouterStore } = useRouterStore.getState();
          setRouterStore({ route_intercept: false });
        }

        let newValue = response.data;

        // if (newValue.place_is_known === 1) {
        this.places.forEach((element) => {
          if (element.selectValue.value === newValue.place_id) {
            newValue.region = element.region;
            newValue.location = element.selectValue.label;
            newValue.submittedChangeId = null;
          }
        });

        if (this.changes.title.length > 0) {
          //If there are submitted changes, we want to merge them with this.newEvent
          //Then we want to remove the submitted changes
          this.axios
            .post(env.protocol + env.env + "/api/secured/deleteChanges", {
              id: this.newEvent.id,
              type: "event",
            })
            .then((response) => {
              newValue.submittedChangeId = null;
              this.props.updateOneAndGoToList(
                //Update list after we have deleted the changes
                this.newEvent.id,
                newValue
              );
            });
        } else {
          // Eru vit í backend skulu vit pusha til listan
          if (!this.props.isFront) {
            if (this.props.updateData !== undefined) {
              this.props.updateOneAndGoToList(
                this.newEvent.id,
                newValue,
                isDraft // Fall right back, if draft has been saved, else go to message.
              );
            } else this.props.pushOneAndGoToList(newValue, isDraft);

            this.props.blurScreen(false);

            if (this.props.index < 7) this.props.indexChange(1);
          } else {
            this.props.blurScreen(false);

            if (this.props.index < 7) this.props.indexChange(1);
            // this.setState({ frontFinnished: true });
          }
        }
      })
      .catch((error) => console.error(error));
  };

  updateEditedFields = (title) => {
    if (this.changes.titleEdited.indexOf(title) > -1) {
      this.changes.titleEdited.splice(this.changes.titleEdited.indexOf(title), 1);

      this.changes.categoriesEdited = this.changes.titleEdited.map((x) => {
        if (x.includes(".")) {
          return x.split(".")[0];
        } else {
          return x;
        }
      });
    }
  };

  editValues = (e) => {
    this.changes.newValues[this.changes.title.indexOf("address")].name = e;
    this.setState({});
  };

  onFrontInstaImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.newEvent.instagram.images[this.instaIndex] = {
        ...this.newEvent.instagram.images[this.instaIndex],
        media: {
          src: URL.createObjectURL(event.target.files[0]),
          newFile: event.target.files[0],
          name: event.target.files[0].name,
        },
      };
      this.setState({ image: URL.createObjectURL(event.target.files[0]) });
    }

    this.unuploadedMedia.instaImages = this.newEvent.instagram.images
      .filter((img) => img.media.newFile)
      .map((img) => img.media.newFile);
  };

  render() {
    const showEn = this.newEvent.textEn.showIt;
    const showFo = this.newEvent.textFo.showIt;
    const uploadHidden = this.state.addMediaDroppedDown ? "" : "hidden";
    let content = "";
    let changes = this.changes;
    let submitChange = this.props.submitChange === "true";
    let placeSuggested = changes.currentOrSuggested[changes.title.indexOf("address")] === "suggested" ? true : false;
    const addressText = placeSuggested
      ? changes.newValues[changes.title.indexOf("address")].name
      : this.newEvent.address.name;
    let contactSuggested =
      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] === "suggested" ? true : false;
    const beenEdit = this.state.beenEdit;

    if (this.state.frontFinnished) {
      //Submitta á frontend. So kemur tillukku tekstur framm
      content = (
        <div className="finnished">
          <h1>{this.language.register.header_register}</h1>
          <div className="new-edit">
            <div className="final-text">
              <FontAwesomeIcon icon={faChild} className="wooh" />
              <p>
                {this.user.firstname.toUpperCase() + " " + this.user.lastname.toUpperCase()},{" "}
                {this.language.register.final_text_registered}
              </p>
            </div>
          </div>{" "}
        </div>
      );
    } else {
      switch (this.props.index) {
        case 0:
          content = (
            <div className="new-content one">
              <WhatsonNavigation
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraft={this.addOrUpdateEvent}
                language={this.props.language}
              />
              <h1>{this.language.info.header}</h1>
              <p>{this.language.info.sub_text}</p>
              {this.props.isFront && this.props.submitChange === "true" ? (
                <i>
                  <p dangerouslySetInnerHTML={{ __html: this.submitDescription }} />
                </i>
              ) : (
                ""
              )}
              <div className="fill-form row">
                <div
                  className={
                    "col-lg-6 language-box english" +
                    (changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] === "suggested"
                      ? changes.newValues[changes.title.indexOf("textEn.showIt")]
                        ? " active"
                        : ""
                      : showEn
                        ? " active"
                        : "")
                  }
                >
                  {this.changes.title.includes("textEn.showIt") ? (
                    <div className="btn_current_submitted">
                      <span
                        className={
                          changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] === "current"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] = "current";
                          this.updateEditedFields("textEn.showIt");
                          this.setState({});
                        }}
                      >
                        Current
                      </span>
                      <span
                        className={
                          changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] === "suggested"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] = "suggested";
                          this.updateEditedFields("textEn.showIt");
                          this.setState({});
                        }}
                      >
                        Suggested changes
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <img className="flag" src={enFlag} alt="en-flag" />

                  <VfiCheckbox
                    className="l-include"
                    checked={
                      changes.currentOrSuggested[changes.title.indexOf("textEn.showIt")] === "suggested"
                        ? changes.newValues[changes.title.indexOf("textEn.showIt")]
                        : showEn
                    }
                    onChange={(e) => {
                      this.newEvent.textEn.showIt = e.target.checked;
                      if (submitChange && !this.submit.includes("textEn.showIt")) {
                        this.submit.push("textEn.showIt");
                      }
                      this.setState({});
                    }}
                  />

                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.headline") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.headline")) {
                            this.submit.push("textEn.headline");
                            this.submit.push("textEn.urlSlug");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.headline") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] = "current";
                            changes.currentOrSuggested[changes.title.indexOf("textEn.urlSlug")] = "current";
                            this.updateEditedFields("textEn.headline");
                            this.updateEditedFields("textEn.urlSlug");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] = "suggested";
                            changes.currentOrSuggested[changes.title.indexOf("textEn.urlSlug")] = "suggested";
                            this.updateEditedFields("textEn.headline");
                            this.updateEditedFields("textEn.urlSlug");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.headline_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={<div className={"tooltip-card"}>Keep it short and sweet.</div>}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>

                    {changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] === "suggested" ? (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={changes.newValues[changes.title.indexOf("textEn.headline")]}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textEn.headline")] = e.target.value;
                          changes.newValues[changes.title.indexOf("textEn.urlSlug")] = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_en}
                        errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                        hideError={!this.state.beenEdit.textEn.headline || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.headline") || !showEn : !showEn}
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textEn.urlSlug",
                            [changes.newValues[changes.title.indexOf("textEn.urlSlug")]],
                            "english"
                          );
                        }}
                        maxLength={50}
                      />
                    ) : (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={this.newEvent.textEn.headline}
                        onChange={(e) => {
                          this.newEvent.textEn.headline = e.target.value;
                          this.newEvent.textEn.urlSlug = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_en}
                        errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                        hideError={!this.state.beenEdit.textEn.headline || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.headline") || !showEn : !showEn}
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textEn.urlSlug",
                            [this.newEvent.textEn.urlSlug],
                            "english"
                          );
                        }}
                        maxLength={50}
                      />
                    )}
                  </div>
                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.shortDescription") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.shortDescription")) {
                            this.submit.push("textEn.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.shortDescription") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.shortDescription")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.shortDescription")] = "current";
                            this.updateEditedFields("textEn.shortDescription");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.shortDescription")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.shortDescription")] = "suggested";
                            this.updateEditedFields("textEn.shortDescription");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.excerpt_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the introduction before the description on the event page.
                            <br />
                            <br />
                            Give a short introduction with an overview that naturally flows into the description field
                            below.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    {changes.currentOrSuggested[changes.title.indexOf("textEn.shortDescription")] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_en}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textEn.shortDescription")] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={changes.newValues[changes.title.indexOf("textEn.shortDescription")]}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textEn.shortDescription || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.shortDescription") || !showEn : !showEn}
                        maxLength={80}
                      />
                    ) : (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_en}
                        onChange={(e) => {
                          this.newEvent.textEn.shortDescription = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={this.newEvent.textEn.shortDescription}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textEn.shortDescription || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.shortDescription") || !showEn : !showEn}
                        maxLength={80}
                      />
                    )}
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textEn.description") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.description")) {
                            this.submit.push("textEn.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.description") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.description")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.description")] = "current";
                            this.updateEditedFields("textEn.description");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textEn.description")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textEn.description")] = "suggested";
                            this.updateEditedFields("textEn.description");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.description_en}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the full event description on the event page.
                            <br />
                            <br />
                            It's a good idea to start by explaining your event in general, followed by details. Keep the
                            description light and engaging, and consider your audience's perspective and what could
                            affect their decision to attend the event.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    {changes.currentOrSuggested[changes.title.indexOf("textEn.description")] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_en}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textEn.description")] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={changes.newValues[changes.title.indexOf("textEn.description")]}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textEn.description || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.description") || !showEn : !showEn}
                        maxLength={3000}
                      />
                    ) : (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_en}
                        onChange={(e) => {
                          this.newEvent.textEn.description = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={this.newEvent.textEn.description}
                        errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                        hideError={!this.state.beenEdit.textEn.description || !showEn}
                        disabled={submitChange ? !this.submit.includes("textEn.description") || !showEn : !showEn}
                        maxLength={3000}
                      />
                    )}
                  </div>
                  <MapSummaryRow parent={this} showLang={showEn} langKey="textEn" />
                </div>
                <div
                  className={
                    "col-lg-6 language-box faroese" +
                    (changes.currentOrSuggested[changes.title.indexOf("textFo.showIt")] === "suggested"
                      ? changes.newValues[changes.title.indexOf("textFo.showIt")]
                        ? " active"
                        : ""
                      : showFo
                        ? " active"
                        : "")
                  }
                >
                  {this.changes.title.includes("textFo.showIt") ? (
                    <div className="btn_current_submitted">
                      <span
                        className={
                          changes.newValues[changes.title.indexOf("textFo.showIt")] === "current"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[changes.title.indexOf("textFo.showIt")] = "current";
                          this.updateEditedFields("textFo.showIt");
                          this.setState({});
                        }}
                      >
                        Current
                      </span>
                      <span
                        className={
                          changes.currentOrSuggested[changes.title.indexOf("textFo.showIt")] === "suggested"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[changes.title.indexOf("textFo.showIt")] = "suggested";
                          this.updateEditedFields("textFo.showIt");
                          this.setState({});
                        }}
                      >
                        Suggested changes
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={
                      changes.currentOrSuggested[changes.title.indexOf("textFo.showIt")] === "suggested"
                        ? changes.newValues[changes.title.indexOf("textFo.showIt")]
                        : showFo
                    }
                    onChange={(e) => {
                      this.newEvent.textFo.showIt = e.target.checked;
                      if (submitChange && !this.submit.includes("textFo.showIt")) {
                        this.submit.push("textFo.showIt");
                      }
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.headline") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.headline")) {
                            this.submit.push("textFo.headline");
                            this.submit.push("textFo.urlSlug");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.headline") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] = "current";
                            changes.currentOrSuggested[changes.title.indexOf("textFo.urlSlug")] = "current";
                            this.updateEditedFields("textFo.headline");
                            this.updateEditedFields("textFo.urlSlug");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] = "suggested";
                            changes.currentOrSuggested[changes.title.indexOf("textFo.urlSlug")] = "suggested";
                            this.updateEditedFields("textFo.headline");
                            this.updateEditedFields("textFo.urlSlug");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.headline_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={<div className={"tooltip-card"}>Keep it short and sweet.</div>}
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    {changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] === "suggested" ? (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={changes.newValues[changes.title.indexOf("textFo.headline")]}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textFo.headline")] = e.target.value;
                          changes.newValues[changes.title.indexOf("textFo.urlSlug")] = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_fo}
                        errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                        hideError={!this.state.beenEdit.textFo.headline || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.headline") || !showFo : !showFo}
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textFo.urlSlug",
                            [changes.newValues[changes.title.indexOf("textFo.urlSlug")]],
                            "faroese"
                          );
                        }}
                        maxLength={50}
                      />
                    ) : (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={this.newEvent.textFo.headline}
                        onChange={(e) => {
                          this.newEvent.textFo.headline = e.target.value;
                          this.newEvent.textFo.urlSlug = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_fo}
                        errorCheckSequence={[Verify.notEmpty, Verify.least3Char]}
                        hideError={!this.state.beenEdit.textFo.headline || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.headline") || !showFo : !showFo}
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textFo.urlSlug",
                            [this.newEvent.textFo.urlSlug],
                            "faroese"
                          );
                        }}
                        maxLength={50}
                      />
                    )}
                  </div>
                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.shortDescription") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.shortDescription")) {
                            this.submit.push("textFo.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.shortDescription") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.shortDescription")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.shortDescription")] = "current";
                            this.updateEditedFields("textFo.shortDescription");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.shortDescription")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.shortDescription")] = "suggested";
                            this.updateEditedFields("textFo.shortDescription");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.excerpt_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the introduction before the description on the event page.
                            <br />
                            <br />
                            Give a short introduction with an overview that naturally flows into the description field
                            below.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    {changes.currentOrSuggested[changes.title.indexOf("textFo.shortDescription")] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_fo}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textFo.shortDescription")] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={changes.newValues[changes.title.indexOf("textFo.shortDescription")]}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textFo.shortDescription || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.shortDescription") || !showFo : !showFo}
                        maxLength={80}
                      />
                    ) : (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_fo}
                        onChange={(e) => {
                          this.newEvent.textFo.shortDescription = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={this.newEvent.textFo.shortDescription}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textFo.shortDescription || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.shortDescription") || !showFo : !showFo}
                        maxLength={80}
                      />
                    )}
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={"enable_editing " + (this.submit.includes("textFo.description") && "enabled")}
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.description")) {
                            this.submit.push("textFo.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.description") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.description")] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.description")] = "current";
                            this.updateEditedFields("textFo.description");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[changes.title.indexOf("textFo.description")] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[changes.title.indexOf("textFo.description")] = "suggested";
                            this.updateEditedFields("textFo.description");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3 with-tooltip">
                      {this.language.info.description_fo}
                      <GlobalToolTipController
                        solid
                        title={""}
                        className={"tootlip-icon"}
                        priority={"bottom,right,top,left"}
                        toolTipElements={
                          <div className={"tooltip-card"}>
                            Your audience will see the full event description on the event page.
                            <br />
                            <br />
                            It's a good idea to start by explaining your event in general, followed by details. Keep the
                            description light and engaging, and consider your audience's perspective and what could
                            affect their decision to attend the event.
                          </div>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </GlobalToolTipController>
                    </div>
                    {changes.currentOrSuggested[changes.title.indexOf("textFo.description")] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_fo}
                        onChange={(e) => {
                          changes.newValues[changes.title.indexOf("textFo.description")] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={changes.newValues[changes.title.indexOf("textFo.description")]}
                        errorCheckSequence={[Verify.notEmpty, Verify.least10Char]}
                        hideError={!this.state.beenEdit.textFo.shortDescription || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.description") || !showFo : !showFo}
                        maxLength={3000}
                      />
                    ) : (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_fo}
                        onChange={(e) => {
                          this.newEvent.textFo.description = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={this.newEvent.textFo.description}
                        errorCheckSequence={[Verify.notEmpty, Verify.least70Char]}
                        hideError={!this.state.beenEdit.textFo.description || !showFo}
                        disabled={submitChange ? !this.submit.includes("textFo.description") || !showFo : !showFo}
                        maxLength={3000}
                      />
                    )}
                  </div>
                  <MapSummaryRow parent={this} showLang={showFo} langKey="textFo" />
                </div>
              </div>
            </div>
          );
          break;

        case 1:
          content = (
            <div className="new-content two">
              <WhatsonNavigation
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                isFront={this.props.isFront}
                saveDraft={this.addOrUpdateEvent}
                language={this.props.language}
              />
              <h1>{this.language.categories.header}</h1>
              <div className="with-tooltip-2">
                {this.language.categories.sub_text}
                <GlobalToolTipController
                  key={"tt1"}
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      Choose at least one – or at most three – categories. The categories can assist your audience in
                      finding your event.
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
              </div>

              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("categories") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("categories")}
                      onChange={(e) => {
                        if (!this.submit.includes("categories")) {
                          this.submit.push("categories");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("categories") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("categories")] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("categories")] = "current";
                      this.updateEditedFields("categories");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("categories")] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("categories")] = "suggested";
                      this.updateEditedFields("categories");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              <ul className="event-categories">
                {this.categories &&
                  this.categories
                    .sort((a, b) => {
                      if (a.category_en[0].toUpperCase() < b.category_en[0].toUpperCase()) return -1;
                      if (a.category_en[0].toUpperCase() > b.category_en[0].toUpperCase()) return 1;
                      return 0;
                    })
                    .map((element) => {
                      return (
                        <li
                          className={`group ${
                            this.newEvent.categories.length >= 3 &&
                            !this.newEvent.categories.find((el) => el.id_category === element.id)
                              ? "disabled"
                              : ""
                          }`}
                          key={element.id}
                        >
                          <label disabled={true}>
                            <VfiCheckbox
                              checked={
                                changes.currentOrSuggested[changes.title.indexOf("categories")] === "suggested"
                                  ? //Suggested change
                                    this.changes.newValues[changes.title.indexOf("categories")].find(
                                      (el) => el.id_category === element.id
                                    )
                                    ? true
                                    : false
                                  : //Current change
                                    this.newEvent.categories.find((el) => el.id_category === element.id)
                                    ? true
                                    : false
                              }
                              disabled={submitChange ? !this.submit.includes("categories") : false}
                              onChange={(e) => {
                                this.setState({
                                  beenEdit: {
                                    ...this.state.beenEdit,
                                    categories: true,
                                  },
                                });
                                if (e.target.checked) {
                                  this.newEvent.categories.push({
                                    id_category: element.id,
                                  });
                                } else {
                                  this.newEvent.categories = this.newEvent.categories.filter(
                                    (el) => el.id_category !== element.id
                                  );
                                }
                                this.setState({});
                              }}
                            />{" "}
                            {this.props.language === "english" ? (
                              <div className="text">
                                {element.category_en}&nbsp;&nbsp;
                                <span className={"fo-category-extra"}>{element.category_fo}</span>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.props.language === "faroese" ? (
                              <div className="text">
                                {element.category_fo}&nbsp;&nbsp;
                                <span className={"en-category-extra"}>{element.category_en}</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </label>
                        </li>
                      );
                    })}
              </ul>
              <div className="verify-errors">
                {this.newEvent.categories.length === 0 && <span>At least 1 must be selected</span>}
              </div>

              {!submitChange && !this.props.isFront && globalObject.vfiUser.superAdmin === "1" ? (
                <div>
                  <p>Add Category</p>
                  <div className="add-category">
                    <VfiInputText
                      className="en label"
                      value={this.state.tempCategoryEn}
                      onChange={(e) => this.setState({ tempCategoryEn: e.target.value })}
                      placeholder="New english category label"
                      disabled={submitChange ? !this.submit.includes("categories") : false}
                    />
                    <VfiInputText
                      className="fo label"
                      value={this.state.tempCategoryFo}
                      onChange={(e) => this.setState({ tempCategoryFo: e.target.value })}
                      placeholder="New faroese category label"
                      disabled={submitChange ? !this.submit.includes("categories") : false}
                    />
                    <div
                      className="add-button"
                      onClick={() => {
                        if (submitChange) {
                        } else {
                          this.props.blurScreen(true);
                          this.axios
                            .post(env.protocol + env.env + "/api/secured/whatson/events/AddEventCategory", {
                              enName: this.state.tempCategoryEn,
                              foName: this.state.tempCategoryFo,
                            })
                            .then((response) => {
                              if (response.data) {
                                this.categories.push(response.data);
                                this.setState({
                                  tempCategoryFo: "",
                                  tempCategoryEn: "",
                                });
                              }
                              this.props.blurScreen(false);
                            })
                            .catch((error) => console.error(error));
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;

        case 2:
          // var suggested = changes.currentOrSuggested[changes.title.indexOf("dates")] === "suggested" ? true : false;

          content = (
            <div className="new-content three">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateEvent}
              />
              <h1>{this.language.dates.header}</h1>
              <div className="sub-text-wrap">
                <p className={"dates-sub-text"}>{this.language.dates.sub_text}</p>
                <GlobalToolTipController
                  key={"tt2"}
                  solid
                  title={""}
                  className={"tootlip-icon"}
                  priority={"bottom,right,top,left"}
                  toolTipElements={
                    <div className={"tooltip-card"}>
                      Single date: Your event occurs only once on a single date.
                      <br />
                      <br />
                      Specific dates: Your event happens on specific dates on two or more occasions.
                      <br />
                      <br />
                      Recurring days: Your event takes place on two or more recurring days (i.e. repeating event
                      periodically).
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </GlobalToolTipController>
              </div>

              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("dates") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("dates")}
                      onChange={(e) => {
                        if (!this.submit.includes("dates")) {
                          this.submit.push("dates");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("dates") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("dates")] === "current" ? "choosen btn" : "btn"
                    }
                    onClick={() => {
                      this.changes.currentOrSuggested[changes.title.indexOf("dates")] = "current";
                      this.updateEditedFields("dates");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("dates")] === "suggested" ? "choosen btn" : "btn"
                    }
                    onClick={() => {
                      this.changes.currentOrSuggested[changes.title.indexOf("dates")] = "suggested";
                      this.updateEditedFields("dates");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              {changes.currentOrSuggested[changes.title.indexOf("dates")] === "suggested" ? (
                <Dates
                  disabled={submitChange ? !this.submit.includes("dates") : false}
                  defaultDateOccurence={changes.newValues[changes.title.indexOf("dates")].occurence}
                  defaultStartDate={changes.newValues[changes.title.indexOf("dates")].startDate}
                  defaultEndDate={changes.newValues[changes.title.indexOf("dates")].endDate}
                  defaultStartTime={changes.newValues[changes.title.indexOf("dates")].startTime}
                  defaultEndTime={changes.newValues[changes.title.indexOf("dates")].endTime}
                  defaultDays={changes.newValues[changes.title.indexOf("dates")].days}
                  defaultSpecificDates={changes.newValues[changes.title.indexOf("dates")].specificDates}
                  defaultDateVisible={changes.newValues[changes.title.indexOf("dates")].dateVisible}
                  onChange={this.dateChange}
                  language={this.props.language}
                  showErrors={beenEdit.dates}
                />
              ) : (
                <Dates
                  disabled={submitChange ? !this.submit.includes("dates") : false}
                  defaultDateOccurence={this.newEvent.dates.occurence}
                  defaultStartDate={this.newEvent.dates.startDate}
                  defaultEndDate={this.newEvent.dates.endDate}
                  defaultStartTime={this.newEvent.dates.startTime}
                  defaultEndTime={this.newEvent.dates.endTime}
                  defaultDays={this.newEvent.dates.days}
                  defaultSpecificDates={this.newEvent.dates.specificDates}
                  defaultDateVisible={this.newEvent.dates.dateVisible}
                  onChange={this.dateChange}
                  language={this.props.language}
                  showErrors={beenEdit.dates}
                />
              )}
            </div>
          );
          break;

        case 3:
          content = (
            <div className="new-content four">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateEvent}
              />
              <h1>{this.language.prices.header}</h1>
              <p>{this.language.prices.sub_text}</p>

              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("prices") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("prices") && this.submit.includes("isFree")}
                      onChange={(e) => {
                        if (!(this.submit.includes("prices") && this.submit.includes("isFree"))) {
                          this.submit.push("prices");
                          this.submit.push("isFree");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("prices") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("prices")] === "current" ? "choosen btn" : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("prices")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("isFree")] = "current";
                      this.updateEditedFields("prices");
                      this.updateEditedFields("isFree");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("prices")] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("prices")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("isFree")] = "suggested";
                      this.updateEditedFields("prices");
                      this.updateEditedFields("isFree");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="is-free">
                <label>
                  <VfiCheckbox
                    disabled={
                      submitChange ? !(this.submit.includes("isFree") && this.submit.includes("prices")) : false
                    }
                    checked={
                      changes.currentOrSuggested[changes.title.indexOf("prices")] === "suggested"
                        ? changes.newValues[changes.title.indexOf("isFree")]
                        : this.newEvent.isFree
                    } //suggested or current value
                    onChange={() => {
                      this.newEvent.isFree = !this.newEvent.isFree;
                      this.setState({
                        beenEdit: { ...this.state.beenEdit, isFree: true },
                      });
                    }}
                  />{" "}
                  <span className="text">{this.language.prices.price_free_text}</span>
                </label>
              </div>
              <Prices
                disabled={submitChange ? !(this.submit.includes("isFree") && this.submit.includes("prices")) : false}
                className={
                  changes.currentOrSuggested[changes.title.indexOf("prices")] === "suggested"
                    ? changes.newValues[changes.title.indexOf("isFree")]
                      ? "hidden"
                      : ""
                    : this.newEvent.isFree
                      ? "hidden"
                      : ""
                } //suggested or current value
                onChange={this.priceChange}
                defaultValue={
                  changes.currentOrSuggested[changes.title.indexOf("prices")] === "suggested"
                    ? changes.newValues[changes.title.indexOf("prices")]
                    : this.newEvent.prices
                }
                language={this.props.language}
              />
            </div>
          );
          break;

        case 4:
          const suggestChangeImages = submitChange ? !this.submit.includes("images") : false;
          content = (
            <div className="new-content five">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateEvent}
              />
              <h1>{this.language.media.header}</h1>
              <div className="with-tooltip-2">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ padding: 0, margin: 0, display: "flex", flexDirection: "row", gap: "16px" }}>
                    {this.language.media.sub_text}
                    <GlobalToolTipController
                      solid
                      title={""}
                      className={"tootlip-icon"}
                      priority={"bottom,right,top,left"}
                      toolTipElements={
                        <div className={"tooltip-card"}>
                          The first image will appear in the overview. Any additional images will appear on the event
                          page. You can drag them in the order you prefer.
                        </div>
                      }
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </GlobalToolTipController>
                  </div>
                  <div style={{ padding: 0, margin: 0 }}>{this.language.media.tip}</div>
                </div>
              </div>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("urls") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("images") && this.submit.includes("urls")}
                      onChange={(e) => {
                        if (!(this.submit.includes("images") && this.submit.includes("urls"))) {
                          this.submit.push("images");
                          this.submit.push("urls");
                          this.submit.push("instagram");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("images") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("images")] === "current" ? "choosen btn" : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("images")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("urls")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("instagram")] = "current";
                      this.updateEditedFields("images");
                      this.updateEditedFields("urls");
                      this.updateEditedFields("instagram");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("images")] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("images")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("urls")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("instagram")] = "suggested";
                      this.updateEditedFields("images");
                      this.updateEditedFields("urls");
                      this.updateEditedFields("instagram");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className={`upload-media-wrapper ${
                  (this.submit.includes("images") && this.submit.includes("urls")) || !submitChange ? "" : "disabled"
                }`}
              >
                <UploadMedia
                  default_elements={this.newEvent.images.mainImages.map((element) => {
                    return {
                      ...element,
                      // file: null,
                      url: element?.medium?.actual
                        ? element?.medium?.actual
                        : element?.elm
                          ? element.elm
                          : `${env.protocol}${env.env}/uploads/${element?.file_name}.${element?.file_extension}`,
                      text: {
                        en: element.enText,
                        fo: element.foText,
                      },
                    };
                  })}
                  auth={{
                    status: !this.props.isFront,
                    call: this.props.isFront
                      ? () => {}
                      : (addMediaElements) => {
                          /* 
                            I don't have time to completely rewrite the state management
                            and the way we handle the media overlay, so we have to rely on these
                            callbacks for now :/
                          */
                          const { whatsonSiteId } = useSitesStore.getState();
                          this.props.setSiteRef(whatsonSiteId);
                          this.props.openOverlay("mediaChoose", {
                            siteId: whatsonSiteId,
                            filter: { mediaType: ["images"] },
                            callbackArg: (new_media) => {
                              addMediaElements(
                                [...new_media].map((element) => {
                                  return {
                                    ...element,
                                    file: null,
                                    url: element?.medium?.actual ? element?.medium?.actual : element.elm,
                                    text: {
                                      en: "",
                                      fo: "",
                                    },
                                  };
                                })
                              );
                            },
                          });
                          this.mediaIs = "mainImage";
                          /**
                           *
                           */
                        },
                  }}
                  onElementsChange={(elements) => {
                    /**
                     * This is not ideal either, but optimizing and cleaning this would require a bigger rewrite
                     */

                    this.unuploadedMedia.mainImages = elements.filter((e) => !e.id).map((e) => e.file);

                    this.newEvent.images.mainImages = elements.map((element, index) => {
                      const { file, rest, url, text, id } = element;
                      return {
                        ...rest,
                        element: file,
                        key: index + 1,
                        name: file?.name,
                        elm: url,
                        id: id,
                        ...Object.fromEntries(
                          Object.entries(text).map((entry) => {
                            let [lang, text] = entry;
                            return [`${lang}Text`, text];
                          })
                        ),
                      };
                    });
                  }}
                />
              </div>
              <div className="edit-contents">
                <hr />

                <p
                  style={{
                    paddingTop: "35px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  {this.language.media.optional_content}
                </p>
                {/* <p
                  className="extra-video-link-text"
                  style={{
                    padding: "10px 0 0 0",
                    margin: "0 0 10px 0",
                    color: "rgba(100, 100, 100, 0.3)",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {this.language.media.video_link_text}
                </p>
                <p>{this.language.media.video_link_subtext}</p>
                {(changes.currentOrSuggested[changes.title.indexOf("urls")] === "suggested"
                  ? changes.newValues[changes.title.indexOf("urls")].extraVideoLinks
                  : this.newEvent.urls.extraVideoLinks
                ).map((element) => {
                  const isSuggested = changes.currentOrSuggested[changes.title.indexOf("urls")] === "suggested";
                  const extraVideoLinksData = isSuggested
                    ? changes.newValues[changes.title.indexOf("urls")].extraVideoLinks
                    : this.newEvent.urls.extraVideoLinks;

                  return (
                    <div key={element.key} className="video-links">
                      <VfiInputText
                        value={element.url}
                        onChange={(e) => {
                          let links = JSON.parse(JSON.stringify(extraVideoLinksData));
                          links.forEach((el, i) => {
                            if (el.key === element.key) {
                              links[i].url = e.target.value;
                            }
                          });
                          this.setState({
                            extraVideoLink: links,
                            beenEdit: {
                              ...beenEdit,
                              urls: { ...beenEdit.urls, extraVideoLinks: true },
                            },
                          });
                          if (isSuggested) {
                            changes.newValues[changes.title.indexOf("urls")].extraVideoLinks = links;
                          } else {
                            this.newEvent.urls.extraVideoLinks = links;
                          }
                        }}
                        placeholder={this.language.media.link_placeholder}
                        // errorCheckSequence={
                        //   beenEdit.urls.extraVideoLinks ? [Verify.notEmpty] : []
                        // }
                      />
                      <div className="trash-icon">
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => {
                            let links = JSON.parse(JSON.stringify(extraVideoLinksData));
                            links.forEach((el, i) => {
                              if (el.key === element.key) {
                                links.splice(i, 1);
                              }
                            });
                            this.setState({
                              extraVideoLink: links,
                              beenEdit: {
                                ...beenEdit,
                                urls: {
                                  ...beenEdit.urls,
                                  extraVideoLinks: true,
                                },
                              },
                            });
                            if (isSuggested) {
                              changes.newValues[changes.title.indexOf("urls")].extraVideoLinks = links;
                            } else {
                              this.newEvent.urls.extraVideoLinks = links;
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div
                  className="add-extra-video-link"
                  onClick={() => {
                    if (!submitChange || this.submit.includes("images")) {
                      let links = JSON.parse(JSON.stringify(this.newEvent.urls.extraVideoLinks));
                      let maxKey = 0;
                      this.newEvent.urls.extraVideoLinks.forEach((element) => {
                        if (element.key > maxKey) maxKey = element.key;
                      });
                      links.push({ key: maxKey + 1, url: "" });
                      this.setState({
                        extraVideoLink: links,
                        beenEdit: {
                          ...beenEdit,
                          urls: { ...beenEdit.urls, extraVideoLinks: true },
                        },
                      });
                      this.newEvent.urls.extraVideoLinks = links;
                    }
                  }}
                >
                  <div className="add-extra-video-link-wrap">
                    <span className="text">{this.language.media.video_link_button}</span>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div> */}
                <VideoLinks
                  videoLinks={this.newEvent.urls.extraVideoLinks}
                  change={(e) => {
                    this.newEvent.urls.extraVideoLinks = e;
                  }}
                />
              </div>
              <hr />
              <p className="instagram-headline">INSTAGRAM</p>
              <label className="instagram-enabled">
                <VfiCheckbox
                  className="instagram-active"
                  checked={this.newEvent.instagram.active}
                  onChange={() => {
                    this.newEvent.instagram.active = !this.newEvent.instagram.active;
                    this.setState({});
                  }}
                />{" "}
                {this.language.media.instagram_enabled}
              </label>
              {this.newEvent.instagram.active && (
                <InstagramInWhatson
                  data={
                    changes.currentOrSuggested[changes.title.indexOf("instagram")] === "suggested"
                      ? changes.newValues[changes.title.indexOf("instagram")]
                      : this.newEvent.instagram
                  }
                  onChange={(e) => {
                    this.newEvent.instagram = e;
                    this.setState({
                      beenEdit: { ...beenEdit, instagram: true },
                    });
                  }}
                  onMediaClick={(i) => {
                    this.mediaIs = "instagram";
                    this.instaIndex = i;
                  }}
                  setSiteRef={this.props.setSiteRef}
                  openOverlay={
                    !this.props.isFront
                      ? this.props.openOverlay
                      : (e) => {
                          this.setState(
                            {
                              imageInput: window.document.createElement("input"),
                            },
                            () => {
                              this.state.imageInput.type = "file";
                              this.state.imageInput.accept = "image/*";
                              this.state.imageInput.addEventListener("change", this.onFrontInstaImageChange);
                              this.state.imageInput.click();
                            }
                          );
                        }
                  }
                  defaultDragStart={this.defaultDragStart}
                  checkErrors={beenEdit.instagram}
                  disabled={suggestChangeImages}
                />
              )}
            </div>
          );
          break;

        case 5:
          let placeValues = [];
          if (this.places) {
            this.places.forEach((element) => {
              placeValues.push(element.selectValue);
            });
          }

          const zipCode = placeSuggested
            ? changes.newValues[changes.title.indexOf("address")].zipCode
            : this.newEvent.address.zipCode;
          const region = placeSuggested
            ? changes.newValues[changes.title.indexOf("address")].region
            : this.newEvent.address.region;

          content = (
            <div className="new-content six">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateEvent}
              />
              <h1>{this.language.place.header}</h1>
              <p>{this.language.place.sub_text}</p>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("address") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("address")}
                      onChange={(e) => {
                        if (!this.submit.includes("address")) {
                          this.submit.push("address");
                          this.submit.push("place");
                          this.submit.push("isPlace");
                          this.submit.push("placeIsHost");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("address") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("address")] === "current" ? "choosen btn" : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("address")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("place")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("isPlace")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("placeIsHost")] = "current";
                      this.updateEditedFields("address");
                      this.updateEditedFields("isPlace");
                      this.updateEditedFields("place");
                      this.updateEditedFields("placeIsHost");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("address")] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("address")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("place")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("isPlace")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("placeIsHost")] = "suggested";
                      this.updateEditedFields("address");
                      this.updateEditedFields("isPlace");
                      this.updateEditedFields("place");
                      this.updateEditedFields("placeIsHost");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}

              <div className="on-list-determine-parent">
                <label className="on-list-determine">
                  <VfiCheckbox
                    disabled={submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false}
                    className="not-located"
                    checked={
                      placeSuggested
                        ? !changes.newValues[changes.title.indexOf("isPlace")]
                        : this.state.placeIsNotInList
                    }
                    onChange={() => {
                      if (placeSuggested) {
                        changes.newValues[changes.title.indexOf("isPlace")] =
                          !changes.newValues[changes.title.indexOf("isPlace")];
                        if (changes.newValues[changes.title.indexOf("isPlace")] === false) {
                          changes.newValues[changes.title.indexOf("placeIsHost")] = false;
                        }
                        this.setState({});
                      } else {
                        this.setState(
                          {
                            placeIsNotInList: !this.state.placeIsNotInList,
                            beenEdit: { ...beenEdit, isPlace: true },
                          },
                          () => {
                            this.newEvent.isPlace = !this.state.placeIsNotInList;
                            if (this.newEvent.isPlace === false) {
                              this.newEvent.placeIsHost = false;
                            }
                          }
                        );
                      }
                    }}
                  />{" "}
                  <span className="not-in-list with-tooltip-2 no-margin">
                    {this.language.place.not_in_list}
                    <GlobalToolTipController
                      solid
                      title={""}
                      className={"tootlip-icon"}
                      priority={"bottom,right,top,left"}
                      toolTipElements={
                        <div className={"tooltip-card"}>
                          Choose the location on the map. The map should be able to identify it automatically. However,
                          double-check that it is correct.
                          <br />
                          <br />
                          If the location does not match, don't worry; just proceed by typing the postal code for the
                          area. Typing in the address manually is also an option here.
                        </div>
                      }
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </GlobalToolTipController>
                  </span>{" "}
                </label>
              </div>

              <div
                className={
                  placeSuggested
                    ? !changes.newValues[changes.title.indexOf("isPlace")]
                      ? "in-list hidden"
                      : "in-list"
                    : this.state.placeIsNotInList
                      ? "in-list hidden"
                      : "in-list"
                }
              >
                <div className="choose-place-text">{this.language.place.choose_place}</div>
                <VfiCustomSelect
                  options={placeValues}
                  onChange={(e) => {
                    if (placeSuggested) {
                      changes.newValues[changes.title.indexOf("place")] = e;
                      this.setState({});
                    } else {
                      this.newEvent.place = e;
                      this.setState({});
                    }
                  }}
                  value={placeSuggested ? changes.newValues[changes.title.indexOf("place")] : this.newEvent.place}
                  isDisabled={submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false}
                  placeholder="Choose place"
                />
              </div>
              <div
                className={
                  placeSuggested
                    ? !changes.newValues[changes.title.indexOf("isPlace")]
                      ? "in-list"
                      : "in-list hidden"
                    : this.state.placeIsNotInList
                      ? "not-in-list"
                      : "not-in-list hidden"
                }
              >
                <SearchBox
                  change={this.onAddressChange}
                  onChange={(e) => {
                    placeSuggested ? this.editValues(e) : (this.newEvent.address.name = e);
                    this.setState({});
                  }}
                  value={addressText}
                />
                <SimpleMap
                  marker={
                    placeSuggested
                      ? changes.newValues[changes.title.indexOf("address")].length > 0
                        ? changes.newValues[changes.title.indexOf("address")].mapMarker
                        : { lat: 0, lng: 0 }
                      : this.state.mapMarker
                  }
                  markerHidden={
                    placeSuggested
                      ? changes.newValues[changes.title.indexOf("address")].length === 0
                        ? !changes.newValues[changes.title.indexOf("address")].showMarker
                        : { lat: 0, lng: 0 }
                      : !this.state.showMarker
                  }
                  onClick={this.onMarkerChange}
                />
                <div className="more-values row">
                  <div className="region col-xl-6">
                    <p>{this.language.place.region}</p>
                    <VfiCustomSelect
                      isDisabled={
                        submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false
                      }
                      onChange={(e) => {
                        this.setState({ region: e.value });
                        if (placeSuggested) changes.newValues[changes.title.indexOf("address")].region = e;
                        else this.newEvent.address.region = e;
                      }}
                      options={this.regions}
                      value={region}
                      error={region === undefined}
                    />
                  </div>
                  <div className="zip-code col-xl-6">
                    <p>{this.language.place.zip_code}</p>
                    <VfiCustomSelect
                      isDisabled={
                        submitChange ? !this.submit.includes("address") && !this.submit.includes("place") : false
                      }
                      onChange={(e) => {
                        this.setState({ zipCode: e.value });
                        if (placeSuggested) {
                          changes.newValues[changes.title.indexOf("address")].zipCode = e;
                        } else {
                          this.newEvent.address.zipCode = e;
                        }
                      }}
                      options={this.zipCodes}
                      value={zipCode}
                      error={zipCode === undefined}
                    />
                  </div>
                </div>
              </div>
              <div className={"max-capacity " + (this.newEvent.isFree ? "hidden " : "")}>
                <p>{this.language.prices.max_capacity}</p>
                <VfiInputText
                  inputType="int"
                  placeholder={this.language.prices.max_capacity_placeholder}
                  defaultValue={this.newEvent.maxCapacity === 0 ? "" : this.newEvent.maxCapacity}
                  onChange={(e) => {
                    this.newEvent.maxCapacity = e.target.value;
                  }}
                />
              </div>
            </div>
          );
          break;
        case 6:
          const company = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].company
            : this.newEvent.contactDetails.company;
          const name = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].name
            : this.newEvent.contactDetails.name;
          const socialLink = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].socialLink
            : this.newEvent.contactDetails.socialLink;
          const website = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].website
            : this.newEvent.contactDetails.website;
          const email = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].email
            : this.newEvent.contactDetails.email;
          const countryCode = contactSuggested
            ? countryCodes.find(
                (e) => e.value === parseInt(changes.newValues[changes.title.indexOf("contactDetails")].countryCode)
              )
            : countryCodeReturn(
                countryCodes.find((e) => parseInt(this.newEvent.contactDetails.countryCode) === e.dial_code)
              );
          const phoneNumber = contactSuggested
            ? changes.newValues[changes.title.indexOf("contactDetails")].phoneNumber
            : this.newEvent.contactDetails.phoneNumber;
          content = (
            <div className="new-content seven">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdateEvent}
                customForwardText={
                  this.props.updateData === undefined
                    ? this.language.register.navigation_submit
                    : this.language.register.navigation_update
                }
                customForwardFunc={() =>
                  this.setState({ beenEdit: allObjTrue(beenEdit) }, () => {
                    this.addOrUpdateEvent(false);
                  })
                }
              />
              <h1>{this.language.organiser.header}</h1>
              <p>{this.language.organiser.sub_text}</p>
              {submitChange ? (
                <div className={"enable_editing_checkbox " + (this.submit.includes("contactDetails") && "enabled")}>
                  <label>
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("contactDetails")}
                      onChange={(e) => {
                        if (!this.submit.includes("contactDetails")) {
                          this.submit.push("contactDetails");
                          this.submit.push("placeIsHost");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </label>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("contactDetails") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] = "current";
                      changes.currentOrSuggested[changes.title.indexOf("placeIsHost")] = "current";
                      this.updateEditedFields("contactDetails");
                      this.updateEditedFields("placeIsHost");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] = "suggested";
                      changes.currentOrSuggested[changes.title.indexOf("placeIsHost")] = "suggested";
                      this.updateEditedFields("contactDetails");
                      this.updateEditedFields("placeIsHost");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              {this.newEvent.isPlace === true ? (
                <div className="who-is-host">
                  <label>
                    <VfiCheckbox2
                      checked={
                        contactSuggested
                          ? changes.newValues[changes.title.indexOf("placeIsHost")]
                          : this.newEvent.placeIsHost
                      }
                      onChange={(e) => {
                        this.newEvent.placeIsHost = !this.newEvent.placeIsHost;
                        this.setState({});
                      }}
                    />
                    <span className="text">{this.language.organiser.organizer_same}</span>
                  </label>
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  contactSuggested
                    ? changes.newValues[changes.title.indexOf("placeIsHost")] === false
                      ? "edit-contents"
                      : "edit-contents hidden"
                    : this.newEvent.placeIsHost === false
                      ? "edit-contents"
                      : "edit-contents hidden"
                }
              >
                <div className="contact-content">
                  <p className="">{this.language.organiser.company_name}:</p>
                  <VfiInputText
                    className="input"
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    onChange={(e) => {
                      if (contactSuggested) {
                        changes.newValues[changes.title.indexOf("contactDetails")].company = e.target.value;
                      } else {
                        this.newEvent.contactDetails.company = e.target.value;
                      }
                      this.setState({});
                    }}
                    value={company}
                    placeholder={this.language.organiser.placeholder.company}
                    errorCheckSequence={beenEdit.contactDetails.company ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.organiser.full_name}:</p>
                  <VfiInputText
                    className="input"
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    onChange={(e) => {
                      if (contactSuggested) {
                        changes.newValues[changes.title.indexOf("contactDetails")].name = e.target.value;
                      } else {
                        this.newEvent.contactDetails.name = e.target.value;
                      }
                      this.setState({});
                    }}
                    value={name}
                    placeholder={this.language.organiser.placeholder.name}
                    errorCheckSequence={beenEdit.contactDetails.name ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.organiser.social_link}:</p>
                  <VfiInputText
                    className="input"
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    onChange={(e) => {
                      if (contactSuggested) {
                        changes.newValues[changes.title.indexOf("contactDetails")].socialLink = e.target.value;
                      } else {
                        this.newEvent.contactDetails.socialLink = e.target.value;
                      }
                      this.setState({});
                    }}
                    value={socialLink}
                    placeholder={this.language.organiser.placeholder.social}
                    errorCheckSequence={beenEdit.contactDetails.socialLink ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.organiser.website}:</p>
                  <VfiInputText
                    className="input"
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    onChange={(e) => {
                      if (contactSuggested) {
                        changes.newValues[changes.title.indexOf("contactDetails")].website = e.target.value;
                      } else {
                        this.newEvent.contactDetails.website = e.target.value;
                      }
                      this.setState({});
                    }}
                    value={website}
                    placeholder={this.language.organiser.placeholder.web}
                    errorCheckSequence={beenEdit.contactDetails.website ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.organiser.email}:</p>
                  <VfiInputText
                    className="input"
                    disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                    onChange={(e) => {
                      if (contactSuggested) {
                        changes.newValues[changes.title.indexOf("contactDetails")].email = e.target.value;
                      } else {
                        this.newEvent.contactDetails.email = e.target.value;
                      }
                      this.setState({});
                    }}
                    value={email}
                    placeholder={this.language.organiser.placeholder.email}
                    errorCheckSequence={beenEdit.contactDetails.email ? [Verify.notEmpty, Verify.validEmail] : []}
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.organiser.phone_number}:</p>
                  <div className="input">
                    <VfiCustomSelect
                      className="input-40"
                      isDisabled={submitChange ? !this.submit.includes("contactDetails") : false}
                      onChange={(e) => {
                        if (contactSuggested) {
                          changes.newValues[changes.title.indexOf("contactDetails")].countryCode = e.value;
                        } else {
                          this.newEvent.contactDetails.countryCode = e.value;
                        }
                        this.setState({});
                      }}
                      options={countryCodes.map(countryCodeReturn)}
                      value={countryCode}
                      error={countryCode === undefined}
                    />
                    <VfiInputText
                      className="input-60"
                      disabled={submitChange ? !this.submit.includes("contactDetails") : false}
                      onChange={(e) => {
                        if (contactSuggested) {
                          changes.newValues[changes.title.indexOf("contactDetails")].phoneNumber = e.target.value;
                        } else {
                          this.newEvent.contactDetails.phoneNumber = e.target.value;
                        }
                        this.setState({});
                      }}
                      value={phoneNumber}
                      placeholder={this.language.organiser.placeholder.phone}
                      inputType="int"
                      errorCheckSequence={beenEdit.contactDetails.phoneNumber ? [Verify.notEmpty] : []}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case 7:
          content = (
            <div className="new-content eight">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={() => {} /* this.props.indexChange */}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraftDisabled={true}
                saveDraft={this.addOrUpdateEvent}
                customForwardText={this.props.isFront ? "Back to submit" : "To list"}
                customForwardFunc={this.props.goToList}
                frontStyle={this.props.isFront ? "whatson-front" : ""}
                returnToSFubmit={this.props.isFront ? this.props.returnToSubmit : null}
              />
              {this.props.submitChange ? (
                <>
                  <h3>
                    <b>Good old chap!</b>
                  </h3>
                  <p style={{ maxWidth: "700px", margin: "auto" }}>
                    Your suggested edit to this event will be published as soon as it has been approved. It is the
                    relevant regional tourist centers and event organizers who can approve your suggested edit.
                  </p>
                </>
              ) : (
                <>
                  <h3>
                    <b>Good old chap!</b>
                  </h3>
                  <p>Your event is registered.</p>
                </>
              )}
              <div className="goose-image">
                <img src={goose.thanksx2} alt="" />
              </div>
            </div>
          );
          break;
        default:
          content = "Should not get here...";
      }
    }

    // this.descriptionPassed = false;

    // this.descriptionPassed = passingVerify;
    const passedParams = {
      showEn,
      showFo,
      removeTags,
      beenEdit: this.state.beenEdit,
      newEvent: this.newEvent,
    };
    this.descriptionPassed = descriptionPassed(passedParams);
    this.categoriesPassed = categoriesPassed(passedParams);
    this.datesPassed = datesPassed(passedParams);
    this.pricesPassed = pricesPassed(passedParams);
    this.imagesPassed = imagesPassed(passedParams);
    this.placePassed = placePassed(passedParams);
    this.contactDetailsPassed = contactDetailsPassed(passedParams);
    this.allPassed =
      this.descriptionPassed &&
      this.categoriesPassed &&
      this.datesPassed &&
      this.pricesPassed &&
      this.imagesPassed &&
      this.placePassed &&
      this.contactDetailsPassed;

    return (
      <div className="new-event">
        <MyUnloadComponent />
        <div
          className="close-event"
          style={{ display: this.props.register ? "none" : "block" }}
          onClick={() => {
            const { addOverlay } = overlayStore.getState();
            addOverlay("card", (close) => {
              return (
                <DefaultPopup
                  text={
                    <>
                      Are you sure you want to close this event?
                      <br />
                      Unsaved content will be lost.
                    </>
                  }
                  okText="Yes, close event"
                  okButtonStyle={{ backgroundColor: "#cf323b" }}
                  okClick={() => {
                    this.axios
                      .post(env.protocol + env.env + "/api/secured/whatson/SessionEndForEdit.php", {
                        id: this.newEvent.id,
                        type: "event",
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                      .finally(() => {
                        this.props.closeEvent();
                        close();
                      });
                  }}
                  cancelClick={close}
                />
              );
            });
          }}
        >
          <FontAwesomeIcon icon={faTimes2} />
        </div>
        <div className="header-display-wrap">
          <div className="header-display" ref={this.headerRef}>
            {this.props.stepBubble(
              faInfo,
              this.language.bubbles.info,
              0,
              this.descriptionPassed,
              this.changes.categoriesEdited.includes("textFo") || this.changes.categoriesEdited.includes("textEn"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faList,
              this.language.bubbles.categories,
              1,
              this.categoriesPassed,
              this.changes.categoriesEdited.includes("categories"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faCalendarAlt,
              this.language.bubbles.time,
              2,
              this.datesPassed,
              this.changes.categoriesEdited.includes("dates"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faTag,
              this.language.bubbles.price,
              3,
              this.pricesPassed,
              this.changes.categoriesEdited.includes("prices") || this.changes.categoriesEdited.includes("isFree"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faImage,
              this.language.bubbles.media,
              4,
              this.imagesPassed,
              this.changes.categoriesEdited.includes("images"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faMapMarkerAlt,
              this.language.bubbles.place,
              5,
              this.placePassed,
              this.changes.categoriesEdited.includes("address") || this.changes.categoriesEdited.includes("place"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faBuilding,
              this.language.bubbles.organizer,
              6,
              this.contactDetailsPassed,
              this.changes.categoriesEdited.includes("contactDetails"),
              this.props.index === 7
            )}
            {this.props.stepBubble(
              faCheck,
              this.props.updateData === undefined ? this.language.bubbles.register : this.language.bubbles.update,
              7,
              true,
              false,
              true
            )}
          </div>
        </div>
        <div className="w-content">{content}</div>
        {this.state?.sessionTimestamp && !this.props.isFront && (
          <WhatsonSessionEditView
            sessionStarted={this.state.sessionTimestamp}
            sessionMaxAge={this.state.sessionMaxAge}
            iNeedMoreTimeClick={() =>
              this.axios
                .post(env.protocol + env.env + "/api/secured/whatson/SessionResetForEdit.php", {
                  id: this.newEvent.id,
                  type: "event",
                })
                .then((response) => {
                  this.setState({
                    sessionTimestamp: response.data.timestamp,
                    sessionMaxAge: response.data.MAX_AGE,
                  });
                })
                .catch(console.error)
            }
            onSessionTimeOut={this.props.closeEvent}
            typeName="event"
          />
        )}
      </div>
    );
  }
}

export default withUserStore(NewEvent);
