import styles from "./StepNavigation.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { createRef, forwardRef, useEffect, useRef } from "react";
import { getWhatsonStore, useWhatsonManager } from "../../stores/whatson-manager-store/WhatsonManagerStore";
import { useRouterStore } from "src/Components/Router/routerStore";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import WhatsOnViewManager from "../../WhatsOnViewManager";
import { INonUser } from "../../stores/GeneralViewManagerStore";

/**
 * Step interface
 */
export interface IStep {
  component: React.ComponentType<any>;
  title: string;
  subtitle?: JSX.Element;
  icon: IconDefinition;
}
/**
 * Step navigation interface
 */
export interface IStepNavigation {
  steps: IStep[];
}
/**
 * Main Component - Step Navigation
 */
export default function StepNavigation({ steps }: IStepNavigation) {
  /**
   * Step button refs
   */
  const stepButtonRefs = useRef(steps.map(() => createRef<HTMLButtonElement>()));
  /**
   * Store properties
   */
  const { currentStepIndex, setSteps, internal } = getWhatsonStore();

  useEffect(() => {
    /**
     * Set the steps if they change
     */
    setSteps([...steps]);
  }, [steps, setSteps]);

  /**
   * Scroll to the current step when it changes
   */
  useEffect(() => {
    /**
     * Current step ref
     */
    const currentStepRef = stepButtonRefs.current[currentStepIndex];
    /**
     * Scroll the current step into view
     */
    setTimeout(() => {
      if (currentStepRef && currentStepRef.current) {
        currentStepRef?.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
        // Scroll the current step into view, centering it in the container
      }
    }, 100);
  }, [currentStepIndex, stepButtonRefs]);
  /**
   * Render
   */
  return (
    <div className={styles.stepNavigation}>
      {/* Step Navigation Container */}
      <div className={styles.stepNavigationButtonContainer}>
        {/* Map Steps */}
        {steps.map((step, index) => (
          <StepButton
            key={index}
            index={index}
            title={step.title}
            icon={step.icon}
            ref={stepButtonRefs.current[index]}
            showErrorIndicator={false}
          />
        ))}
      </div>
      {/* Step Controls */}
      <StepNavigationControls steps={steps} />
      {internal ? (
        <button
          className={styles.close_submit}
          onClick={() => {
            useRouterStore.getState().navByLink("whatson/events");
          }}
        >
          <FontAwesomeIcon icon={faXmark as IconProp} />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
/**
 * Step Navigation Controls
 */
function StepNavigationControls({ steps }: IStepNavigation) {
  /**
   * Store properties
   */
  const { saving, internal, all_valid, submitted, currentStepIndex, goToNextStep, goToPreviousStep } =
    getWhatsonStore();
  /**
   * Determine the label for the next button
   */
  const nextButtonLabel = currentStepIndex === steps.length - 1 ? "Submit" : "Next";
  /**
   * Render
   */
  if (submitted) return <FinalButtons />;
  return (
    <div className={styles.StepNavigationControls}>
      {/* Back */}
      {currentStepIndex !== 0 && (
        <button
          onClick={goToPreviousStep}
          disabled={currentStepIndex === 0 || (submitted && currentStepIndex === steps.length - 1)}
          className={`${styles.StepNavigationControlsButton} ${styles.StepNavigationControlsButtonBack} ${
            currentStepIndex === 0 || (submitted && currentStepIndex === steps.length - 1)
              ? styles.StepNavigationControlsButtonDisabled
              : ""
          }`}
          aria-label="Go to Previous Step"
        >
          Back
        </button>
      )}
      {/* Next */}
      <button
        onClick={goToNextStep}
        disabled={
          (currentStepIndex === steps.length - 1 && !all_valid) ||
          ((saving.auto || saving.submit) && currentStepIndex === steps.length - 1) ||
          (currentStepIndex === steps.length - 1 && submitted)
        }
        className={`${styles.StepNavigationControlsButton} ${styles.StepNavigationControlsButtonNext} ${
          (currentStepIndex === steps.length - 1 && !all_valid) ||
          ((saving.auto || saving.submit) && currentStepIndex === steps.length - 1) ||
          (currentStepIndex === steps.length - 1 && submitted)
            ? styles.StepNavigationControlsButtonDisabled
            : ""
        }`}
        aria-label={nextButtonLabel}
      >
        {nextButtonLabel}
      </button>
    </div>
  );
}
/**
 * Step Button interface
 */
interface IStepButton {
  index: number;
  title: string;
  icon: IconDefinition;
  showErrorIndicator?: boolean;
}
/**
 * Step Button
 */
const StepButton = forwardRef<HTMLButtonElement, IStepButton>(({ index, title, icon, showErrorIndicator }, ref) => {
  /**
   * Store properties
   */
  const { type, errors, internal, submitted, currentStepIndex, setCurrentStepIndex } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <button
      ref={ref}
      onClick={() => {
        if (!submitted) setCurrentStepIndex(index);
      }}
      className={`${styles.stepButton} ${submitted ? styles.disabled : ""}`}
      disabled={submitted}
    >
      {/* Error Indicator */}
      <div className={`${styles.stepIcon} ${currentStepIndex === index ? styles.active : ""}`}>
        {icon && <FontAwesomeIcon icon={icon as IconProp} />}
        {!errors?.[type]?.[title]?.valid && <div className={styles.errorIndicator}>!</div>}
      </div>
      {/* Title */}
      <div className={`${styles.stepTitle} ${currentStepIndex === index ? styles.activeText : ""}`}>{title}</div>
    </button>
  );
});

function FinalButtons() {
  const { get_parameters } = useRouterStore();
  const { setStore, type } = useWhatsonManager();
  const { internal, nonUser } = getWhatsonStore();
  const { navByLink, setRouterStore } = useRouterStore.getState();

  useEffect(() => {
    setRouterStore({
      route_intercept: false,
    });
  }, []);

  /**
   * Render
   */
  return (
    <div className={styles.StepNavigationControls}>
      {internal ? (
        <button
          className={`${styles.StepNavigationControlsButton} ${styles.StepNavigationControlsButtonBack}`}
          aria-label="Go to Previous Step"
          onClick={() => {
            navByLink(`whatson`);
          }}
        >
          Back to list
        </button>
      ) : (
        <button
          className={`${styles.StepNavigationControlsButton} ${styles.StepNavigationControlsButtonBack}`}
          aria-label="Go to Previous Step"
          onClick={() => {
            if (get_parameters?.returnUrl) window.location.href = get_parameters.returnUrl;
          }}
        >
          Back to What's On
        </button>
      )}
      <button
        className={`${styles.StepNavigationControlsButton} ${styles.StepNavigationControlsButtonNext}`}
        aria-label="Go to Previous Step"
        onClick={() => {
          // window.location.reload();

          const nu = nonUser ? { ...nonUser } : null;
          const inte = internal;
          setStore(type);

          if (nu)
            setTimeout(() => {
              useWhatsonManager.getState().getStore().getState().setNonUser(nu);
              useWhatsonManager.getState().getStore().getState().setInternal(inte);
            }, 100);
        }}
      >
        Submit new event
      </button>
    </div>
  );
}
