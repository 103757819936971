import React, { Component } from "react";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import env from "../../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faImage, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { faImage as faImage2, faRemove, faEllipsisVertical, faTrash } from "@fortawesome/pro-regular-svg-icons";
import InstagramInWhatson from "../../../../assets/InstagramInWhatson";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import Verify from "../../../../assets/VerifyMethods";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import UploadMedia from "../common/media/upload-media/UploadMedia";
import { useSitesStore } from "src/Components/ZustandStores/sitesStore";

/**
 * Media form step
 *
 * This is the media insertion/editing form for the tour operator.
 * Used to set main images, instagram and additional optional media for the tour operator
 *
 * @returns 	{jsx} 												Media form element
 *
 * @author 					Pætur Mortensen
 */
class Media extends Component {
  /**
   * props:
   * @property 		{object} 		newTourOperator 					Tour operator information
   * @property 		{object} 		language 									Language object with strings
   * @property 		{function} 	saveChanges 							Save changes
   * @property 		{object} 		state 										NewTourOperator component state object
   * @property		{function} 	setState 									NewTourOperator setState method
   * @property 		{function} 	setSiteRef 								Function to set site reference
   * @property 		{function} 	openOverlay 							Function to open overlay (e.g. media insert)
   * @property		{component} whatsonNavigation 				WhatsonNavigation component
   */
  constructor(props) {
    super(props);

    this.state = {
      imageEditOverlay: null,
      fileTypeError: false,
    };

    // this.addOrUpdatePlace = this.props.addOrUpdatePlace;
    this.showEn = this.props.newTourOperator.textEn.showIt;
    this.showFo = this.props.newTourOperator.textFo.showIt;

    this.whatsonSiteId = useSitesStore.getState().whatsonSiteId;
  }

  /**
   * Check whether there are no empty optional media items
   *
   * @returns 	{bool} 													true: no empty items, false: there are empty items
   *
   * @author 					Pætur Mortensen
   */
  no_empty_optional_items() {
    let noEmpty = true;

    // Iterate over the optional media items
    this.props.newTourOperator.optionalMedia.map((medium) => {
      // If the value is falsey, flag noEmpty as false
      if (!medium.value) {
        noEmpty = false;
      }
    });

    // Return whether there are no empty fields
    return noEmpty;
  }

  /**
   * Get the next key to use with optional media
   *
   * @returns 	{int} 												Next key to use with optional media
   *
   * @author 					Pætur Mortensen
   */
  get_opt_media_next_key() {
    // Init maxKey as 0
    let maxKey = 0;

    // For each optional media...
    this.props.newTourOperator.optionalMedia.map((item) => {
      // If the item's key is more than max key, set new max
      if (item.key > maxKey) {
        maxKey = item.key;
      }
    });

    // Add one and return
    return maxKey + 1;
  }

  /**
   * Delete an item from optional media
   *
   * @param 	{int} 	key 								Medium key
   *
   * @sideeffects 												changes this.props.newTourOperator
   * 																			calls this.props.setState()
   *
   * @author 					Pætur Mortensen
   */
  delete_optional_medium(key) {
    // Get the index of the medium, remove it from newTourOperator and update the state
    const idx = this.props.newTourOperator.optionalMedia.findIndex((item) => item.key === key);
    this.props.newTourOperator.optionalMedia.splice(idx, 1);
    this.props.setState({});
  }

  toggle_img_cp(imageID) {
    let imageEditOverlay = imageID;
    if (this.state.imageEditOverlay === imageID) {
      imageEditOverlay = null;
    }
    this.setState({ imageEditOverlay });
  }

  /**
   * Close any open image edit overlay CPs
   *
   * @author 					Pætur Mortensen
   */
  close_img_cp() {
    // Set the image edit overlay ID to null
    this.setState({ imageEditOverlay: null });
  }

  /**
   * Set an image as the main image
   *
   * This method will set the image sortOrder to 1 and recount the others. Sort order 1 means
   * featured image
   *
   * @param 		{int} 	imgID 											TOImgID for image (Tour operator image ID)
   *
   * @author 					Pætur Mortensen
   */
  set_img_as_main(imgID) {
    // Init current order as 2 (this will be used as sortOrder for all other images)
    let currentOrder = 2;
    // For each image....
    for (const idx in this.props.newTourOperator.images.mainImages) {
      const image = this.props.newTourOperator.images.mainImages[idx];
      // If this is the image to set as main...
      if (image.TOImgID === imgID) {
        image.sortOrder = 1;
      } else {
        // This image should not be set as main, just set and increment sort order
        image.sortOrder = currentOrder;
        currentOrder += 1;
      }
    }

    // Close CP will also setState
    this.close_img_cp();
  }

  /**
   * Delete an image
   *
   * @param 		{int} 	imgID 												ID of image (TOImgID for image)
   *
   * @author 					Pætur Mortensen
   */
  delete_img(imgID) {
    // Splice the image out of the array
    this.props.newTourOperator.images.mainImages.splice(
      this.props.newTourOperator.images.mainImages.findIndex((image) => image.TOImgID === imgID),
      1
    );

    // Close CP will also setState
    this.close_img_cp();
  }

  /**
   * Get sort order value for new image
   *
   * @returns 	{int} 												New sort order value
   *
   * @author 					Pætur Mortensen
   */
  get_new_sort_order() {
    // Init max sort order as 0
    let maxSortOrder = 0;
    // For each image...
    for (const idx in this.props.newTourOperator.images.mainImages) {
      const currentOrder = this.props.newTourOperator.images.mainImages[idx].sortOrder;
      // If the image sort order is greater than max...
      if (currentOrder > maxSortOrder) {
        maxSortOrder = currentOrder;
      }
    }

    // Return the max +1 for the next image
    return maxSortOrder + 1;
  }

  /**
   * Render the image upload block
   *
   * @returns 	{jsx} 											Upload block
   *
   * @author 					Pætur Mortensen
   */
  render_image_upload_block() {
    return (
      <div
        className="image-upload-block"
        onClick={() => {
          this.props.setSiteRef(this.whatsonSiteId);
          this.props.openOverlay("mediaChoose", {
            siteId: this.whatsonSiteId,
            filter: { mediaType: ["images"] },
            afterFunc: (e) => {
              e.map((image) => {
                // Callback after inserting image
                // Build new image for insertion into the newTourOperator
                const newSortOrder = this.get_new_sort_order();
                // Only allow png and jpg
                const allowedExt = ["png", "jpg", "jpeg"];
                if (!allowedExt.includes(image.fileExtension)) {
                  this.setState({ fileTypeError: true });
                  return;
                }
                const newImg = {
                  key: image.id,
                  file_name: image.file_name,
                  file_extension: image.fileExtension,
                  enText: "",
                  foText: "",
                  sortOrder: newSortOrder,
                };
                // Push to main images
                this.props.newTourOperator.images.mainImages.push(newImg);
                this.props.setState({
                  beenEdit: {
                    ...this.props.state.beenEdit,
                    images: { mainImages: true },
                  },
                });
                this.setState({ fileTypeError: false });
                this.props.saveChanges();
              });
            },
          });
          this.mediaIs = "mainImage";
        }}
      >
        <div className="upload-box">
          <div className="image-container">
            <FontAwesomeIcon className="pen" icon={faPlus} />
            <FontAwesomeIcon className="before-image" icon={faImage2} />
            {this.state.fileTypeError && (
              <div className="img-error-cont">{this.props.language.media.allowed_file_ext}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render images
   *
   * @returns 	{jsx} 									images
   */
  render_images() {
    // Start by ordering the images by their sortorder
    this.props.newTourOperator.images.mainImages.sort((a, b) => a.sortOrder - b.sortOrder);

    /**
     * Render an image description field for one language
     *
     * @param 		{string} 	language 									Selected language ("English" or "Faroese")
     * @param 		{object} 	element 									Media element
     * @param 		{int} 		idx 											Element array index
     *
     * @returns 	{jsx}
     *
     * @author 					Pætur Mortensen
     */
    const render_image_desc_field = (language, element, idx) => {
      // Get class and object key string based on language
      const langClassStr = language === "english" ? "text-en" : "text-fo";
      const langKeyStr = language === "english" ? "enText" : "foText";

      return (
        <div className={element.focused ? "text-wrap " + langClassStr : `text-wrap ${langClassStr} hidden`}>
          <VfiTextarea
            onChange={(e) => (this.props.newTourOperator.images.mainImages[idx][langKeyStr] = e)}
            defaultValue={this.props.newTourOperator.images.mainImages[idx][langKeyStr]}
            onFocus={() => {
              this.props.newTourOperator.images.mainImages[idx].focused = true;
              this.props.setState({});
            }}
            onBlur={() => {
              this.props.newTourOperator.images.mainImages[idx].focused = false;
              this.props.setState({});
            }}
            placeholder={this.props.language.media[language + "_text_placeholder"]}
            errorCheckSequence={
              [
                /* Verify.notEmpty */
              ]
            }
          />
        </div>
      );
    };

    /**
     * Render the image CP
     *
     * This function renders the image overlay CP with "set as main" and "delete image" buttons
     *
     * @param 		{int} 	imageID 							ID of image for CP (TOImgID: tour operator image ID)
     *
     * @returns 	{jsx} 												The image CP overlay element
     *
     * @author 					Pætur Mortensen
     */
    const render_image_cp = (imageID) => {
      return (
        <div className="img-cp">
          <div
            onClick={() => {
              this.set_img_as_main(imageID);
            }}
          >
            <FontAwesomeIcon icon={faImage} />
            {this.props.language.media.set_as_main}
          </div>
          <div
            className="delete-img"
            onClick={() => {
              this.delete_img(imageID);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            {this.props.language.media.delete_image}
          </div>
        </div>
      );
    };

    return this.props.newTourOperator.images.mainImages.map((element, i) => {
      const imageID = element.TOImgID || i;
      return (
        <div
          className={
            "one-image" +
            (this.props.state.indexImageDrag === i ? " dragging" : "") +
            (this.props.state.indexImageDragOn === i ? " dragging-on" : "")
          }
          key={imageID}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            setTimeout(() => {
              this.props.setState({ indexImageDragOn: i });
            }, 0);
          }}
          onDragLeave={(e) => {
            this.props.setState({ indexImageDragOn: undefined });
          }}
          onDrop={(e) => {
            let images = JSON.parse(JSON.stringify(this.props.newTourOperator.images.mainImages));
            let image = images[this.props.state.indexImageDrag];
            images.splice(this.props.state.indexImageDrag, 1);
            images.splice(i, 0, image);
            this.props.newTourOperator.images.mainImages = images;
          }}
          draggable="false"
        >
          <div className="media-wrap">
            <div className="image-desc">
              {i === 0 ? this.props.language.media.featured_image : this.props.language.media.gallery + " " + i}
            </div>
            <div
              className="edit-btn"
              onClick={() => {
                this.toggle_img_cp(imageID);
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
            {this.state.imageEditOverlay === imageID && render_image_cp(imageID)}
            <img
              src={env.protocol + env.env + "/uploads/" + element.file_name + "_medium." + element.file_extension}
              alt="upload"
              draggable="false"
            />
          </div>

          {this.showEn && render_image_desc_field("english", element, i)}
          {this.showFo && render_image_desc_field("faroese", element, i)}
        </div>
      );
    });
  }

  /**
   * Render the media uploader
   *
   * @returns 		{jsx} 								Media uploader
   */
  render_media_uploader() {
    return (
      <div className="uploaded-images upload-media-wrapper">
        <UploadMedia
          default_elements={this.props.newTourOperator.images.mainImages.map((element, index) => {
            return {
              ...element,
              // file: null,
              url: element?.medium?.actual
                ? element?.medium?.actual
                : element?.elm
                  ? element.elm
                  : `${env.protocol}${env.env}/uploads/${element?.file_name}.${element?.file_extension}`,
              text: {
                en: element.enText,
                fo: element.foText,
              },
              sortOrder: index,
              key: element.id,
            };
          })}
          auth={{
            status: true,
            call: (addMediaElements) => {
              /* 
                I don't have time to completely rewrite the state management
                and the way we handle the media overlay, so we have to rely on these
                callbacks for now :/
              */
              this.props.setSiteRef(this.whatsonSiteId);
              this.props.openOverlay("mediaChoose", {
                siteId: this.whatsonSiteId,
                filter: { mediaType: ["images"] },
                callbackArg: (new_media) => {
                  addMediaElements(
                    [...new_media].map((element, index) => {
                      return {
                        ...element,
                        file: null,
                        url: element?.medium?.actual ? element?.medium?.actual : element.elm,
                        text: {
                          en: "",
                          fo: "",
                        },
                        sortOrder: index,
                        key: element.id,
                      };
                    })
                  );
                },
              });
              this.mediaIs = "mainImage";
              /**
               *
               */
            },
          }}
          onElementsChange={(elements) => {
            /**
             * This is not ideal either, but optimizing and cleaning this would require a bigger rewrite
             */

            // this.unuploadedMedia.mainImages = elements
            //   .filter((e) => !e.id)
            //   .map((e) => e.file);

            this.props.newTourOperator.images.mainImages = elements.map((element, index) => {
              const { file, rest, url, text, id } = element;
              return {
                ...rest,
                element: file,
                key: id,
                name: file?.name,
                elm: url,
                id: id,
                sortOrder: index,
                ...Object.fromEntries(
                  Object.entries(text).map((entry) => {
                    let [lang, text] = entry;
                    return [`${lang}Text`, text];
                  })
                ),
              };
            });
          }}
        />
        {/* {this.render_images()}
        {this.render_image_upload_block()} */}
      </div>
    );
  }

  /**
   * Render a single optional media field
   *
   * @param 	{object} 	medium 								Medium data to render
   *
   * @returns 	{jsx} 												Optional media field
   *
   * @author 					Pætur Mortensen
   */
  render_optional_medium_field(medium) {
    return (
      <VfiInputText
        key={medium.key}
        placeholder={this.props.language.media.put_your_link_here}
        onChange={(e) => {
          this.props.newTourOperator.optionalMedia[
            this.props.newTourOperator.optionalMedia.findIndex((item) => item.key === medium.key)
          ].value = e.target.value;
          this.props.setState({});
        }}
        value={medium.value}
        className="opt-media-input"
        customRightSide={
          medium.value && (
            <div
              className="delete-btn"
              onClick={() => {
                this.delete_optional_medium(medium.key);
              }}
            >
              <FontAwesomeIcon className="icon" icon={faRemove} />
            </div>
          )
        }
      />
    );
  }

  /**
   * Render the fields for optional media
   *
   * @returns 	{jsx} 										Optional media fields
   *
   * @author 					Pætur Mortensen
   */
  render_optional_content_fields() {
    // Add an empty field if there are no optional media
    if (this.props.newTourOperator.optionalMedia.length === 0) {
      this.props.newTourOperator.optionalMedia.push({ key: 1, value: "" });
    }

    return (
      <div className="optional-content">
        <p className="header">{this.props.language.media.optional_content}</p>
        <p className="sub-header">{this.props.language.media.youtube_vimeo_or_soundcloud}</p>
        <p>{this.props.language.media.optional_content_description}</p>
        {this.props.newTourOperator.optionalMedia.map((medium) => this.render_optional_medium_field(medium))}
        {this.no_empty_optional_items() && (
          <div
            className="add-more-btn"
            onClick={() => {
              const key = this.get_opt_media_next_key();
              this.props.newTourOperator.optionalMedia.push({ key, value: "" });
              this.props.setState({});
            }}
          >
            {this.props.language.media.add_more}
            <FontAwesomeIcon className="plus-icon" icon={faPlus} />
          </div>
        )}
      </div>
    );
  }

  /**
   * Render instagram form
   *
   * @returns 		{jsx} 							Instagram form
   *
   * @author 					Pætur Mortensen
   */
  render_instagram() {
    return (
      <div className="instagram-section">
        <p className="instagram-header">{this.props.language.media.instagram}</p>
        <label className="instagram-enabled">
          <VfiCheckbox
            className="instagram-active"
            checked={this.props.newTourOperator.instagram.active}
            onChange={() => {
              this.props.newTourOperator.instagram.active = !this.props.newTourOperator.instagram.active;
              this.props.setState({
                beenEdit: { ...this.props.state.beenEdit, instagram: true },
              });
            }}
          />
          &nbsp;
          {this.props.language.media.instagram_enabled}
        </label>
        {this.props.newTourOperator.instagram.active && (
          <>
            <InstagramInWhatson
              data={this.props.newTourOperator.instagram}
              onChange={(e) => {
                this.props.newTourOperator.instagram = e;
                this.props.setState({
                  beenEdit: { ...this.props.state.beenEdit, instagram: true },
                });
              }}
              onMediaClick={(i) => {
                this.mediaIs = "instagram";
                // this.instaIndex = i;
              }}
              setSiteRef={this.props.setSiteRef}
              openOverlay={(e, args, key) => {
                this.props.openOverlay(e, {
                  ...args,
                  afterFunc: (media) => {
                    this.props.newTourOperator.instagram.images[
                      this.props.newTourOperator.instagram.images.findIndex((image) => image.key === key)
                    ].media = media;
                    this.props.setState({});
                  },
                });
              }}
              defaultDragStart={this.defaultDragStart}
              checkErrors={this.props.state.beenEdit.instagram}
            />
          </>
        )}
      </div>
    );
  }

  /**
   * Render media upload form
   *
   * @returns 	{jsx} 								Media upload form
   *
   * @author 					Pætur Mortensen
   */
  render() {
    return (
      <div className="new-content five">
        {/* Render the top navigation and control panel */}
        {this.props.whatsonNavigation()}

        <h1>{this.props.language.media.header}</h1>
        <div className="sub-header with-tooltip-2">
          {this.props.language.media.sub_text.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          {/* <GlobalToolTipController
            solid
            title={""}
            className={"tootlip-icon"}
            priority={"bottom,right,top,left"}
            toolTipElements={
              <div className={"tooltip-card"}>
                The first image will appear in the tour operator page. You can drag them in the order you prefer.
              </div>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </GlobalToolTipController> */}
        </div>

        {/* Render the media uploader */}
        {this.render_media_uploader()}

        <hr />

        {/* Render the Optional content field */}
        {this.render_optional_content_fields()}

        <hr />

        {/* Render the instagram form */}
        {this.render_instagram()}
      </div>
    );
  }
}

export default Media;
