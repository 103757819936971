import Checkbox from "src/Components/Checkbox/Checkbox";
import styles from "./Description.module.scss";

import enFlag from "../../../../../../src/assets/images/en.png";
import foFlag from "../../../../../../src/assets/images/fo.png";
import { Fragment, useEffect } from "react";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import VfiInput from "src/assets/VfiInput/VfiInput";
import VfiInputField from "src/assets/VfiInputField/VfiInputField";
import { DescriptionState } from "src/views/WhatsOn/stores/description-store/DescriptionStore";
import Layout from "../Layout";
import { inputConfigs } from "./utils";
import { getWhatsonStore, useWhatsonManager } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

const LANGUAGES = {
  en: {
    img: <img src={enFlag} alt="british flag" />,
    long: "english",
  },
  fo: {
    img: <img src={foFlag} alt="faroese flag" />,
    long: "føroyskt",
  },
} as const;

export type TLanguages = keyof typeof LANGUAGES;
export type TLangAsKeys<T> = Record<TLanguages, T>;

export default function Description() {
  const { validateDescription } = getWhatsonStore();

  const { type, stores } = useWhatsonManager();

  // return (
  //   <div className={styles.container}>
  //     <div className={styles.headlineContainer}>
  //       <h1 className={styles.headline}>Event Description</h1>
  //       <p className={styles.subheadline}>
  //         Some people might not have heard about your event. Get them excited by providing a detailed description of all
  //         fun that's going to happen!
  //       </p>
  //     </div>
  //     <div className={styles.content}>
  //       <DescriptionInput icon={LANGUAGES["en"]} language="en" />
  //       <div className={styles.divider}></div>
  //       <DescriptionInput icon={LANGUAGES["fo"]} language="fo" />
  //     </div>
  //   </div>
  // );

  return (
    <Layout
      title={"Event Description"}
      subtitle={
        "Some people might not have heard about your event. Get them excited by providing a detailed description of all fun that's going to happen!"
      }
    >
      <div className={styles.content}>
        <DescriptionInput icon={LANGUAGES["en"]} language="en" />
        <div className={styles.divider}></div>
        <DescriptionInput icon={LANGUAGES["fo"]} language="fo" />
      </div>
    </Layout>
  );
}

function DescriptionInput({ icon, language }: { icon: { img: JSX.Element; long: string }; language: TLanguages }) {
  // const enabled = getWhatsonStore((state) => state.enabled[language]);
  // const setEnabled = getWhatsonStore((state) => state.setEnabled);

  // const updateDescription = getWhatsonStore((state) => state.updateDescription);
  // const description = getWhatsonStore((state) => state.description[language]);

  const {
    type,
    errors,
    enabled: ena,
    description: des,
    setEnabled,
    updateDescription,
    validateDescription,
  } = getWhatsonStore();

  const enabled = ena[language];
  const description = des[language];

  const handleInputChange = (key: keyof DescriptionState, value: string) => {
    if (description.hasOwnProperty(key)) {
      updateDescription(language, key, { ...description[key], value: value, error: "" });
      if (!errors?.[type]?.Description.valid) validateDescription();
    }
  };
  return (
    <div className={styles.descriptionInputContainer}>
      <div className={styles.toggleContainer}>
        <div className={styles.icon}>{icon.img}</div>
        <Checkbox
          children={Fragment}
          checked={enabled}
          onChange={() => {
            setEnabled(language, !enabled);
            if (!errors?.[type]?.Description.valid) validateDescription();
          }}
        />
      </div>
      {enabled && (
        <div className={styles.descriptionInputContent}>
          {Object.keys(inputConfigs).map((key) => {
            const config = inputConfigs[key];
            const safeKey = key as keyof DescriptionState;
            return (
              <InputWrapper
                key={key}
                headline={config.placeholder}
                tooltip={config.tooltip}
                textLimit={config.textLimit}
                characterCount={description[safeKey]?.characterCount}
              >
                {config.inputType === "text" ? (
                  <VfiInput
                    placeholder={config.placeholder}
                    onChange={(e) => handleInputChange(safeKey, e.target.value)}
                    value={description[safeKey]?.value || ""}
                    error={description[safeKey]?.error || ""}
                    maxLength={config.textLimit || 1000}
                  />
                ) : (
                  <VfiInputField
                    placeholder={config.placeholder}
                    onChange={(e) => handleInputChange(safeKey, e.target.value)}
                    value={description[safeKey]?.value || ""}
                    error={description[safeKey]?.error || ""}
                    maxLength={config.textLimit || 1000}
                    height={config.height || 100}
                  />
                )}
              </InputWrapper>
            );
          })}
        </div>
      )}
    </div>
  );
}

function InputWrapper({
  children,
  headline,
  tooltip,
  textLimit,
  characterCount,
}: {
  children: JSX.Element;
  headline: string;
  tooltip?: string;
  textLimit?: number;
  characterCount?: number;
}) {
  const { isTablet, isMobile } = getWhatsonStore();

  const tablet = isTablet();

  const mobile = isMobile();

  return (
    <div className={styles.InputWrapper}>
      <div className={styles.inputHeadlineContainer}>
        <h2 className={styles.inputHeadline}>{headline}</h2>
        <GlobalToolTipController
          solid
          title={""}
          className={styles.tooltipIcon}
          priority={"bottom,right,top,left"}
          toolTipElements={
            <div
              // className={"tooltip-card"}
              style={{
                padding: "8px",
                ...(tablet || mobile ? { fontSize: "14px !important" } : {}),
              }}
            >
              {tooltip}
            </div>
          }
        >
          <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
        </GlobalToolTipController>
      </div>
      <div className={`${styles.descriptionInputElement} ${!textLimit ? styles.textLimitIndicatorPadding : ""}`}>
        {children}
        {textLimit && (
          <div className={styles.textLimitIndicator}>
            <span>{characterCount}</span>/<span>{textLimit}</span>
          </div>
        )}
      </div>
    </div>
  );
}
