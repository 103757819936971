import { useEffect, useState } from "react";
import OneMedia from "../../../assets/PageModules/OneMedia/OneMedia";
import VfiCKeditor from "../../../assets/VfiCKeditor/VfiCKeditor";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../assets/VfiTextarea/VfiTextarea";
import noImage from "../../../assets/images/empty.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { capitalize, showDate } from "../../../assets/helperFunctions";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";
import { whatWhatson2permCheck } from "../WhatsonDisplay/WhatsonDisplay";

export default function GeneralOptions({
  data: d,
  whatWhatson,
  changeValue = (key, value, inWhatTable) => void 0,
  onChange = (data) => void 0,
  approvalStatusChange = (id, value) => void 0,
  className,
  selectedLanguage,
  disabled = false,
}) {
  const [data, setData] = useState(d);
  const [subOption, setSubOption] = useState("");
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const featureImage = data?.images.main ? data?.images.main[0] : undefined;
  const language = selectedLanguage === "en" ? "english" : "faroese";
  const { permCheckFunc } = usePermCheckStore();

  useEffect(() => {
    setData(d);
  }, [d]);

  if (data === undefined) return "";
  let content;

  const blurFunc = (key, value) => {
    if (value !== d.text[language][key]) {
      changeValue(key, value, "text");
      const changeVal = {
        ...data,
        text: {
          ...data.text,
          [language]: {
            ...data.text[language],
            [key]: value,
          },
        },
      };
      onChange(changeVal);
      setData(changeVal);
    }
  };

  switch (subOption) {
    case "current_status":
      const statuses = ["draft", "published"];
      content = (
        <div>
          <div className="small-label top-gap">CURRENT STATUS</div>
          {statuses.map((e) => (
            <label key={e} className="encapsulate status top-gap">
              <div className="text">{capitalize(e)}</div>
              <input
                disabled={inputsDisabled}
                type={"radio"}
                className="right-point"
                readOnly={true}
                checked={data.data.current_status === e}
                onClick={() => {
                  setInputsDisabled(true);
                  changeValue("current_status", e, "");
                }}
              />
            </label>
          ))}
        </div>
      );
      break;
    default:
      content = (
        <div className={"wrap-general-options" + (disabled ? " disabled" : "")}>
          <div>
            <div className="small-label top-gap">{whatWhatson.toUpperCase()} HEADLINE</div>
            <VfiInputText
              value={data.text[language].headline}
              maxLength={50}
              onChange={(e) => {
                setData({
                  ...data,
                  text: {
                    ...data.text,
                    [language]: {
                      ...data.text[language],
                      headline: e.target.value,
                    },
                  },
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                blurFunc("headline", value);
                setInputsDisabled(true);
              }}
              disabled={disabled || inputsDisabled}
            />
          </div>
          <div>
            <div className="small-label top-gap">EXCERPT</div>
            <VfiTextarea
              className="excerpt"
              defaultValue={data.text[language].excerpt}
              maxLength={100}
              onBlur={(e) => {
                blurFunc("excerpt", e);
                setInputsDisabled(true);
              }}
              disabled={disabled || inputsDisabled}
            />
          </div>
          <div>
            <div className="small-label top-gap">FEATURE IMAGE</div>
            <OneMedia
              className={"feature-image"}
              media={{
                src: featureImage ? featureImage.medium : noImage,
              }}
              alt={featureImage ? featureImage.alt : "Missing media"}
              disableChange={true}
            />
          </div>
          {/* {whatWhatson !== "hiking" && (
            <div>
              <div className="small-label top-gap">APPROVAL STATUS</div>
              {data && data.data && (
                <WhatsonApprovalStatusButtons
                  element={{
                    id: data.data.id,
                    approvalStatus: data.data.approval_status,
                  }}
                  whatWhatson={whatWhatson}
                  onChange={(e) => {
                    approvalStatusChange(e.id, e.approval_status);
                  }}
                />
              )}
            </div>
          )} */}
          {/* Approval has been disabled. */}
          {data &&
            data.data &&
            permCheckFunc({ type: whatWhatson2permCheck[whatWhatson], id: data.data.id, minimumRole: "Publisher" }) && (
              <div
                className="encapsulate status top-gap"
                onClick={() => {
                  setSubOption("current_status");
                }}
              >
                <div className="small-label title">CURRENT STATUS</div>
                <div className="text">{capitalize(data.data.current_status)}</div>
                <div className="small-label date">{showDate(data.data.reg_date)}</div>
                <FontAwesomeIcon className="right-point" icon={faChevronRight} />
              </div>
            )}
        </div>
      );
  }

  return (
    <div className={"general-options " + (className ?? "")}>
      <div className={"option-title" + (subOption ? " clickable" : "")} onClick={() => setSubOption("")}>
        {subOption && <FontAwesomeIcon icon={faChevronLeft} />}General
      </div>
      {content}
    </div>
  );
}
