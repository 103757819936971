import { createRef, useEffect, useRef } from "react";
import { create } from "zustand";
import CloseBtn from "./closeBtn";

import "./toolTip.scss";

/* --- --- --- ---- --- --- --- */
/* --- Global Tooltip Store --- */
/* --- --- --- ---- --- --- --- */

// TODO: Create an inital-states-constant, so we easily can reset the tooltip states

var toolTipIsOpen = false;
var activeElement = null;

export const useToolTipStore = create((set) => ({
  toolTipElements: <></>,
  isOpen: false,
  mouseInElement: false,
  click: false,
  solid: false,
  position: { x: 0, y: 0 },
  activeElement: <></>,
  elementSize: { width: 0, height: 0 },
  elementPosition: { x: 0, y: 0 },
  toolTipSize: { width: 0, height: 0 },
  toolTipPos: { x: 0, y: 0 },
  toolTip: null,
  delay: 0,
  opacity: 1,
  side: "",
  title: "",
  set: (states) => {
    set((state) => ({ ...state, ...states }));
  },
}));

/* --- --- --- ---  --- --- --- --- */
/* --- Global Tooltip Container --- */
/* --- --- --- ---  --- --- --- --- */

export function GlobalToolTip() {
  const {
    isOpen,
    toolTipElements,
    mouseInElement,
    click,
    solid,
    side,
    title,
    opacity,
    toolTipSize,
    toolTipPos,
    delay,
    elementSize,
    elementPosition,
    set,
  } = useToolTipStore((state) => state);

  // References for tooltip and exitbtn
  const toolTipRef = useRef(null);
  const exitBtnRef = createRef(null);

  useEffect(() => {
    let toolTip = toolTipRef.current;
    set({
      toolTip: toolTip,
    });
  }, []);

  useEffect(() => {
    let toolTip = toolTipRef.current;

    set({
      toolTipSize: {
        width: toolTip.offsetWidth,
        height: toolTip.offsetHeight,
      },
    });

    const handleOutsideClick = (e) => {
      if (toolTipRef.current && !toolTipRef.current.contains(e.target)) {
        if (!mouseInElement) {
          set({ isOpen: false });
          toolTipIsOpen = false;
        }
      }
    };

    const handleScroll = (e) => {
      if (toolTipRef.current && !toolTipRef.current.contains(e.target)) {
        set({ isOpen: false });
        toolTipIsOpen = false;
      }
    };

    // Add event-listeners
    if (click) {
      window.addEventListener("click", handleOutsideClick);
      window.addEventListener("wheel", handleScroll, false);
      window.addEventListener("touchstart", handleScroll, false);
      window.addEventListener("scroll", handleScroll, false);
    } else {
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("wheel", handleScroll, false);
      window.removeEventListener("touchstart", handleScroll, false);
      window.removeEventListener("scroll", handleScroll, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mouseInElement, elementSize, elementPosition, toolTipElements]);

  return (
    <>
      <div
        style={{
          top: toolTipPos.y,
          left: toolTipPos.x,
          display: `${isOpen ? "flex" : "none"}`,
          visibility: "hidden",
          animationDuration: `${click ? 0 : delay / 1000}s`,
          animationName: isOpen ? "show" : "",
          opacity: opacity,
        }}
        ref={toolTipRef}
        className={`${"easy-tooltip"} ${click ? "click" : "hover"}`}
      >
        {title === "" ? (
          <></>
        ) : (
          <>
            <div className="top-container">
              <h1 className={"title"}>{title}</h1>
              {click ? (
                <div
                  ref={exitBtnRef}
                  className={"close-btn"}
                  onClick={() => {
                    set({ isOpen: false });
                    toolTipIsOpen = false;
                  }}
                >
                  <CloseBtn width={18} height={18} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}

        {solid ? (
          <div
            style={{
              display: side == "" ? "none" : "block",
              top:
                side === "top"
                  ? toolTipPos.y + toolTipSize.height - 5
                  : side === "bottom"
                    ? toolTipPos.y - 5
                    : toolTipPos.y + toolTipSize.height / 2 - 5,
              left:
                side === "left"
                  ? toolTipPos.x + toolTipSize.width - 5
                  : side === "right"
                    ? toolTipPos.x - 5
                    : toolTipPos.x + toolTipSize.width / 2 - 5,
            }}
            className={`arrow ${side}`}
          ></div>
        ) : (
          <></>
        )}

        <div className={`${"tooltip-body"} ${click ? "click" : "hover"}`}>{toolTipElements}</div>
      </div>
    </>
  );
}

/* --- --- --- -- --- --- --- */
/* --- Tooltip Controller --- */
/* --- --- --- -- --- --- --- */

// You wrap the element, with this controller component, that you want a tooltip effect on.

export function GlobalToolTipController({
  children,
  className = "",
  style = {},
  offset = { x: 0, y: 0 },
  toolTipElements = <></>,
  click = false,
  solid = false,
  priority = "right,bottom,left,top",
  delay = 0,
  opacity = 1,
  title = "Tooltip",
  onClick = () => {},
}) {
  const elements = toolTipElements;

  const { toolTip, set } = useToolTipStore((state) => state);

  const elementRef = useRef(null);

  // Function that calculates, checks and sets tooltip position
  function setTooltipPosition(elementSize, elementPosition, toolTipSize) {
    let order = priority.split(",");

    let x1 = elementPosition.x + elementSize.width / 2 - toolTipSize.width / 2;
    let x2 = x1 + toolTipSize.width;

    let y1 = elementPosition.y + elementSize.height / 2 - toolTipSize.height / 2;
    let y2 = y1 + toolTipSize.height;

    for (let i = 0; i < 4; i++) {
      switch (order[i]) {
        case "bottom":
          if (y2 + elementSize.height / 2 + toolTipSize.height / 2 + offset.y >= window.innerHeight) continue;

          set({
            side: order[i],
            toolTipPos: {
              x:
                x1 + offset.x <= 0
                  ? 10
                  : x1 + offset.x + toolTipSize.width >= window.innerWidth
                    ? window.innerWidth - toolTipSize.width - 10
                    : x1 + offset.x,
              y: y1 + elementSize.height / 2 + offset.y + toolTipSize.height / 2 + 10,
            },
          });
          return;
        case "top":
          if (y1 - elementSize.height / 2 - toolTipSize.height / 2 - offset.y <= 0) continue;

          set({
            side: order[i],
            toolTipPos: {
              x:
                x1 + offset.x <= 0
                  ? 10
                  : x1 + offset.x + toolTipSize.width >= window.innerWidth
                    ? window.innerWidth - toolTipSize.width - 10
                    : x1 + offset.x,
              y: y1 - elementSize.height / 2 - offset.y - toolTipSize.height / 2 - 10,
            },
          });
          return;
        case "left":
          if (x1 - elementSize.width / 2 - toolTipSize.width / 2 - offset.x <= 0) continue;

          set({
            side: order[i],
            toolTipPos: {
              x: x1 - elementSize.width / 2 - offset.x - toolTipSize.width / 2 - 10,
              y:
                y1 + offset.y <= 0
                  ? 10
                  : y1 + offset.y + toolTipSize.height >= window.innerHeight
                    ? window.innerHeight - toolTipSize.height - 10
                    : y1 + offset.y,
            },
          });
          return;
        case "right":
          if (x2 + elementSize.width / 2 + toolTipSize.width / 2 + offset.x >= window.innerWidth) continue;

          set({
            side: order[i],
            toolTipPos: {
              x: x1 + elementSize.width / 2 + offset.x + toolTipSize.width / 2 + 10,
              y:
                y1 + offset.y <= 0
                  ? 10
                  : y1 + offset.y + toolTipSize.height >= window.innerHeight
                    ? window.innerHeight - toolTipSize.height - 10
                    : y1 + offset.y,
            },
          });
          return;
        default:
          set({
            side: "",
            toolTipPos: {
              x: 10,
              y: 10,
            },
          });
      }
    }
  }

  useEffect(() => {
    // Get the element that controls the tooltip
    let element = elementRef.current;

    // Event handlers
    const handleClick = (e) => {
      let elementSize = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
      let rect = element.getBoundingClientRect();
      let elementPosition = { x: rect.left, y: rect.top };

      // Set ToolTip State Attributes

      set({
        click: click,
        solid: solid,
        title: title,
        delay: delay,
        opacity: opacity,
      });

      // First open or close tooltip and set elements based on isOpen state
      if (toolTipIsOpen) {
        set({
          toolTipElements: <></>,
          isOpen: false,
          activeElement: <></>,
        });
        toolTipIsOpen = false;
        activeElement = null;
      } else {
        set({
          toolTipElements: elements,
          isOpen: true,
          activeElement: element,
        });
        toolTipIsOpen = true;
        activeElement = element;
      }

      // After we've set the tooltip elements we can get the tooltip size and calculate its position
      let toolTipSize = {
        width: toolTip.offsetWidth,
        height: toolTip.offsetHeight,
      };

      if (solid) {
        setTooltipPosition(elementSize, elementPosition, toolTipSize);
      } else {
        let x =
          e.clientX + offset.x + toolTipSize.width >= window.innerWidth
            ? e.clientX - offset.x - toolTipSize.width
            : e.clientX + offset.x <= 0
              ? 0
              : e.clientX + offset.x;

        let y =
          e.clientY + offset.y + toolTipSize.height >= window.innerHeight
            ? e.clientY - offset.y - toolTipSize.height
            : e.clientY + offset.y <= 0
              ? 0
              : e.clientY + offset.y;

        set({ toolTipPos: { x: x, y: y } });
      }
    };

    const handleEnter = (e) => {
      let elementSize = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
      let rect = element.getBoundingClientRect();
      let elementPosition = { x: rect.left, y: rect.top };

      if (toolTipIsOpen) return; // Don't do anything if another tooltip is open!

      activeElement = element;
      set({
        activeElement: element,
        elementSize: {
          width: element.offsetWidth,
          height: element.offsetHeight,
        },
        click: click,
        solid: solid,
        delay: delay,
        title: title,
        toolTipElements: elements,
        isOpen: true,
        opacity: opacity,
      });

      toolTipIsOpen = true;

      let toolTipSize = {
        width: toolTip.offsetWidth,
        height: toolTip.offsetHeight,
      };

      if (solid) {
        setTooltipPosition(elementSize, elementPosition, toolTipSize);
      } else {
        let x =
          e.clientX + offset.x + toolTipSize.width >= window.innerWidth
            ? e.clientX - offset.x - toolTipSize.width
            : e.clientX + offset.x <= 0
              ? 0
              : e.clientX + offset.x;

        let y =
          e.clientY + offset.y + toolTipSize.height >= window.innerHeight
            ? e.clientY - offset.y - toolTipSize.height
            : e.clientY + offset.y <= 0
              ? 0
              : e.clientY + offset.y;

        // setToolTipPos({ x: x, y: y });
        set({ toolTipPos: { x: x, y: y } });
      }
    };

    const handleMousemove = (e) => {
      let toolTipSize = {
        width: toolTip.offsetWidth,
        height: toolTip.offsetHeight,
      };

      let x =
        e.clientX + offset.x + toolTipSize.width >= window.innerWidth
          ? e.clientX - offset.x - toolTipSize.width
          : e.clientX + offset.x <= 0
            ? 0
            : e.clientX + offset.x;

      let y =
        e.clientY + offset.y + toolTipSize.height >= window.innerHeight - 10
          ? window.innerHeight - toolTipSize.height - 10
          : e.clientY + offset.y <= 0
            ? 0
            : e.clientY + offset.y;

      if (toolTip) {
        toolTip.style.left = x + "px";
        toolTip.style.top = y + "px";
      }
    };

    const handleLeave = (e) => {
      // This might need to check which element is active, in case a clicked element is activated
      // Can be fixed by creating another global variable whithin the scope of this file called active element
      // that compares the current element with the active element.

      activeElement = null;

      toolTipIsOpen = false;

      set({
        isOpen: false,
        title: "",
        toolTipElements: <></>,
      });
    };

    // -- Common handlers --

    const handleEnterCommon = (e) => {
      if (!toolTipIsOpen) {
        let rect = element.getBoundingClientRect();
        set({
          mouseInElement: true,
          elementPosition: { x: rect.left, y: rect.top },
          elementSize: {
            width: element.offsetWidth,
            height: element.offsetHeight,
          },
        });
      }
    };

    const handleLeaveCommon = (e) => {
      set({ mouseInElement: false });
    };

    // Type based event listeners

    if (click) {
      element.addEventListener("click", handleClick);
    } else {
      element.addEventListener("mouseenter", handleEnter);
      if (!solid) element.addEventListener("mousemove", handleMousemove);
      element.addEventListener("mouseleave", handleLeave);
    }

    // Common event listeners
    element.addEventListener("mouseenter", handleEnterCommon);
    element.addEventListener("mouseleave", handleLeaveCommon);

    return () => {
      // Remove all event listeners
      element.removeEventListener("click", handleClick);
      element.removeEventListener("mouseenter", handleEnter);
      element.removeEventListener("mousemove", handleMousemove);
      element.removeEventListener("mouseleave", handleLeave);
      element.removeEventListener("mouseenter", handleEnterCommon);
      element.removeEventListener("mouseleave", handleLeaveCommon);

      if (activeElement == element) {
        activeElement = null;
        set({
          isOpen: false,
          toolTipElements: <></>,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={elementRef} className={className} style={style} onClick={onClick}>
      {children}
    </div>
  );
}
