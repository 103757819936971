import { faImage } from "@fortawesome/pro-light-svg-icons";
import OneMedia from "../PageModules/OneMedia";
import VfiCustomSelect from "../VfiCustomSelect";
import VfiInputText from "../VfiInputText/VfiInputText";
import env from "../../environment.json";
import json_languages from "./language.json";
import { cloneDeep } from "lodash";
import { useState } from "react";
import "./InstagramInWhatson.scss";
import VerifyMethods from "../VerifyMethods";
import { useSitesStore } from "src/Components/ZustandStores/sitesStore";
import { Medium } from "src/Components/MediaCenter/types";
import { TSystemKeyLanguages } from "src/types/types/TSystemLanguage";
import { getLocalStorageLanguage } from "src/utils/systemLanguageUtils";
const language = getLocalStorageLanguage();

interface IJsonLanguage {
  bubble_text: string;
  header: string;
  sub_text: string;
  description: string;
  hashtag: string;
  profile_link: string;
  image_amount: string;
  images: string;
  image_headline: string;
}

type TJsonLanguages = TSystemKeyLanguages<IJsonLanguage>;

interface image {
  instaLink: string;
  key: number;
  media: Partial<Medium>;
}
interface data {
  active: boolean;
  profile: string;
  hashtag: string;
  images: image[];
}
interface props {
  data: data;
  onChange: (data: data) => void;
  onMediaClick: (i: number) => void;
  setSiteRef: () => void;
  openOverlay: (e: any, args: any, key: number) => void;
  defaultDragStart: (e: any) => void;
  checkErrors: boolean;
  disabled: boolean;
  className: string;
}

export default function InstagramInWhatson({
  data,
  onChange = () => void 0,
  onMediaClick = () => void 0,
  setSiteRef = () => void 0,
  openOverlay = () => void 0,
  defaultDragStart = () => void 0,
  checkErrors = false,
  disabled = false,
  className = "",
}: props) {
  const languages: TJsonLanguages = json_languages;
  const theLanguage = languages[language];
  const [instagramImageAmount, setInstagramImageAmount] = useState(data.images.length);
  const [indexImageDrag, setIndexImageDrag] = useState<undefined | number>(undefined);
  const [indexImageDragOn, setIndexImageDragOn] = useState<undefined | number>(undefined);

  return (
    <div className={"instagram-in-whatson " + className}>
      <div className="upper-wrap">
        <p className="instagram-description">{theLanguage.description}</p>
        <div className="row">
          <div className="hashtag col-lg-6">
            <p>{theLanguage.hashtag}</p>
            <VfiInputText
              value={data.hashtag}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...data, hashtag: e.target.value })}
              errorCheckSequence={checkErrors ? [VerifyMethods.notEmpty] : []}
              disabled={disabled}
            />
          </div>
          <div className="profile-link col-lg-6">
            <p>{theLanguage.profile_link}</p>
            <VfiInputText
              value={data.profile}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...data, profile: e.target.value })}
              errorCheckSequence={checkErrors ? [VerifyMethods.notEmpty, VerifyMethods.isInstagramLink] : []}
              disabled={disabled}
            />
          </div>
        </div>
        <p className="image-count-text">{theLanguage.image_amount}</p>
        <VfiCustomSelect
          isDisabled={disabled}
          options={[
            { label: "3 " + theLanguage.images, value: 3 },
            { label: "5 " + theLanguage.images, value: 5 },
            { label: "9 " + theLanguage.images, value: 9 },
          ]}
          onChange={(e: any) => {
            const copyData = cloneDeep(data);

            copyData.images = copyData.images.slice(0, e.value);

            while (copyData.images.length < e.value) {
              copyData.images.push({
                instaLink: "",
                media: {},
                key: copyData.images.length,
              });
            }

            onChange(copyData);
            setInstagramImageAmount(e.value);
          }}
          value={
            instagramImageAmount > 0
              ? {
                  label: instagramImageAmount + " " + theLanguage.images,
                  value: instagramImageAmount,
                }
              : undefined
          }
          error={checkErrors ? instagramImageAmount === 0 : false}
        />
      </div>
      <div className="instagram-image-links">
        {data.images.map((element, i) => {
          return (
            <div
              className={
                "insta" + (indexImageDrag === i ? " dragging" : "") + (indexImageDragOn === i ? " dragging-on" : "")
              }
              key={element.key}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragEnter={(e) => {
                setTimeout(() => {
                  setIndexImageDragOn(i);
                }, 0);
              }}
              onDragLeave={(e) => {
                setIndexImageDragOn(undefined);
              }}
              onDrop={(e) => {
                if (indexImageDrag !== undefined) {
                  let images = Object.assign([], data.images);
                  let image = images[indexImageDrag];
                  images.splice(indexImageDrag, 1);
                  images.splice(i, 0, image);
                  data.images = images;
                  onChange(data);
                }
              }}
              draggable="false"
            >
              <div className="insta-image">
                <OneMedia
                  // TODO: Handle ts-ignore below. Eighter by making OneMedia to typescript, or defining the correct type.
                  //@ts-ignore
                  siteRef={useSitesStore.getState().whatsonSiteId}
                  setSiteRef={setSiteRef}
                  openOverlay={(e: any, args: any) => openOverlay(e, args, element.key)} // TODO: Figure out what `e` and `args` are.
                  whatMedia={["images"]}
                  media={element.media}
                  onClick={() => {
                    onMediaClick(i);
                  }}
                  faIcon={faImage}
                  draggable={!disabled}
                  onDragStart={(e: React.DragEvent<HTMLElement>) => {
                    defaultDragStart(e);
                    setIndexImageDrag(i);

                    if (element.media) {
                      var img = document.createElement("img");
                      img.src =
                        env.protocol +
                        env.env +
                        "/uploads/" +
                        element.media.fileName +
                        "_thumbnail." +
                        element.media.fileExtension;
                      e.dataTransfer.setDragImage(img, 0, 0);
                    }
                  }}
                  onDragEnd={() => {
                    setIndexImageDrag(undefined);
                    setIndexImageDragOn(undefined);
                  }}
                  disableChange={disabled}
                />
                <div className="drag-element" />
              </div>
              <div className="insta-link-wrap">
                <VfiInputText
                  className="insta-link"
                  value={element.instaLink}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const copyData = cloneDeep(data);
                    copyData.images[i].instaLink = e.target.value;
                    onChange(copyData);
                  }}
                  placeholder="https://www.instagram.com/p/CoqQLdEs..."
                  errorCheckSequence={checkErrors ? [VerifyMethods.notEmpty, VerifyMethods.isInstagramLink] : []}
                  disabled={disabled}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
