type BaseInputConfig = {
  placeholder: string;
  tooltip?: string;
  textLimit?: number;
};

type TextInputConfig = BaseInputConfig & {
  inputType: "text";
};

type TextAreaInputConfig = BaseInputConfig & {
  inputType: "textarea";
  height?: number;
};

type InputConfig = TextInputConfig | TextAreaInputConfig;

export const inputConfigs: { [key: string]: InputConfig } = {
  headlineTextInput: {
    inputType: "text",
    placeholder: "Headline",
    tooltip: "Keep it short and sweet.",
    textLimit: 50,
  },
  shortSummaryTextInput: {
    inputType: "textarea",
    placeholder: "Short Summary",
    tooltip: "Keep it short and sweet.",
    textLimit: 300,
    height: 100,
  },
  descriptionTextInput: {
    inputType: "textarea",
    placeholder: "Description",
    tooltip: "Tell us more about your event.",
    textLimit: 1500,
    height: 260,
  },
  mapSummaryTextInput: {
    inputType: "textarea",
    placeholder: "Map Summary",
    tooltip: "Keep it short and sweet.",
    textLimit: 300,
    height: 100,
  },
};
