import WhatsonNavigation from "src/Components/Content/WhatsonDisplay/WhatsonNavigation";
import styles from "./SubmitEntity.module.scss";
import React, { useEffect, useState } from "react";
import { TWhatsonType } from "src/types/types/Whatson";
import { faFile, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { getLocalStorageLanguage, language2short } from "src/utils/systemLanguageUtils";
import SuccessView from "./SuccessView";
import { usePermCheckStore } from "src/Components/PermCheck/PermCheckStore";
import { whatsonType2PermissionType } from "src/utils/whatsonUtils";
import languageObj from "./language.json";
import { TLanguageObj } from "./TLanguage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ISubmit_message_1_non_autoapprovalVariants {
  [key: string]: string; // Giving this optional value tells the system that there could be other keys. We need to handle them, if there is more.
  "{whatson_type}": TWhatsonType;
  "{send_status}": string;
}
interface IVerifyCriteria {
  label: string;
  passed: boolean;
}
interface props {
  //   readyToSubmitText: React.ReactNode;
  id?: number;
  type: TWhatsonType;
  isFront: boolean;
  regionId?: number;
  customBackText?: React.ReactNode;
  verifyCriterias: IVerifyCriteria[];
  customForwardText?: React.ReactNode;
  initial_submitted?: boolean;
  hideNavigationButtons?: boolean;
  onMounted?: () => void;
  enableEdit?: () => void;
  indexChange: (add: number) => void;
  saveDraftFunc: () => void;
  onSubmitAction: () => boolean;
  customBackFunc?: () => void;
  customForwardFunc?: () => void;
}
export default function SubmitEntity({
  // readyToSubmitText,
  id = 0,
  type,
  isFront,
  regionId,
  customBackText,
  verifyCriterias,
  customForwardText,
  initial_submitted = false,
  hideNavigationButtons = false,
  onMounted,
  enableEdit = () => {},
  indexChange,
  saveDraftFunc,
  onSubmitAction,
  customBackFunc,
}: props) {
  const [submitted, setSubmitted] = useState<boolean>(initial_submitted);
  const [navigationButtonsDisabled, setNavigationButtonsDisabled] = useState<boolean | undefined>(undefined);
  const [forwardText, setForwardText] = useState<React.ReactNode | undefined>(undefined);
  const language = getLocalStorageLanguage();

  const short = language2short(language);
  useEffect(() => {
    if (onMounted) onMounted();
  }, []);

  useEffect(() => {
    setSubmitted(initial_submitted);
  }, [initial_submitted]);

  const { permCheckFunc } = usePermCheckStore();
  const languageText: TLanguageObj = languageObj;
  const hasAutoApprovalRights =
    permCheckFunc({ type: whatsonType2PermissionType(type), minimumRole: "Publisher", id }) ||
    permCheckFunc({ type: "regions", minimumRole: "Publisher", id: regionId });
  const verifyNotPassed = verifyCriterias.find((vc) => !vc.passed) !== undefined;
  const messages = languageText.messages;

  const submit_message_1_non_autoapprovalVariants: ISubmit_message_1_non_autoapprovalVariants = {
    "{whatson_type}": type,
    "{send_status}": id ? "updated" : "published",
  } as const;

  return (
    <div className={styles["submit-entity"]}>
      {!submitted ? (
        <>
          {verifyNotPassed ? (
            <>
              <h3 style={{ marginBottom: "30px" }}>
                <b>{messages.holdon_header[short]}</b>
              </h3>
              <p>
                {messages.holdon_message_1[short]}
                <br />
                <br />
                <div className={styles["hold-on-bullets"]}>
                  {verifyCriterias
                    .filter((vc) => !vc.passed)
                    .map((vc) => (
                      <i>
                        - {vc.label}
                        <br />
                      </i>
                    ))}
                </div>
                <br />
                {messages.holdon_message_2[short]}
              </p>
            </>
          ) : (
            <>
              <h3 style={{ marginBottom: "30px" }}>
                <b>{messages.submit_header[short]}</b>
              </h3>
              <p>
                {(hasAutoApprovalRights
                  ? messages.submit_message_1_autoapproval
                  : messages.submit_message_1_non_autoapproval)[short]
                  .replaceAll(/{whatson_type}|{send_status}/gi, function (matched: string) {
                    return submit_message_1_non_autoapprovalVariants[matched] ?? "MESSAGE IS MISSING!";
                  })
                  .replaceAll("_", " ")}
                <br />
                <br />
                {messages.submit_message_2[short]}
              </p>
            </>
          )}
          <div className={styles["bottom-submit-wrap"]}>
            {/* <button className={styles["preview-whatson"]}>Preview</button> */}
            <WhatsonNavigation
              className={styles["whatson-navigation-in-submit"]}
              language={language}
              indexChange={indexChange}
              isFront={isFront}
              undoAndRedoDisabled={true}
              forwardDisabled={navigationButtonsDisabled ?? verifyNotPassed}
              backwardDisabled={navigationButtonsDisabled ?? false}
              saveDraftDisabled={navigationButtonsDisabled ?? false}
              hideNavigationButtons={hideNavigationButtons}
              saveDraft={saveDraftFunc}
              customForwardText={forwardText ?? customForwardText}
              customBackwardText={customBackText}
              customForwardFunc={async () => {
                setNavigationButtonsDisabled(true);
                setForwardText(<FontAwesomeIcon icon={faSpinner as IconProp} spin />);
                const success = await onSubmitAction();
                setSubmitted(success);
              }}
              customBackwardFunc={customBackFunc}
              previewListOptions={[
                { is: "choice", text: "EN", onClick: () => {}, icon: faFile },
                { is: "choice", text: "FO", onClick: () => {}, icon: faFile },
              ]}
            />
          </div>
        </>
      ) : (
        <SuccessView
          type={type}
          language={language}
          whatsonReferId={id}
          indexChange={indexChange}
          enableEdit={enableEdit}
          internal={!isFront}
          hideNavigationButtons={hideNavigationButtons}
          hasAutoApproval={hasAutoApprovalRights}
        />
      )}
    </div>
  );
}
