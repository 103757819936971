import { StateCreator } from "zustand";
import { ValidateDates } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

export type TDateType = "single" | "various" | "dateRange" | "recurring" | null;
export type TCheckedDays = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export interface IDate {
  date: Date | null;
  time: {
    start: TTime;
    end: TTime;
  };
  allDay: boolean;
}

export type TTime = {
  hour: string | null;
  minute: string | null;
};

export interface DateSlice {
  dateType: TDateType;
  startDate: Date | null;
  endDate: Date | null;
  allDay: boolean;
  startTime: TTime;
  endTime: TTime;
  dateWhenVisible: Date | null;
  checkedDays: TCheckedDays[];

  datesValid: boolean;

  various: IDate[];

  /**
   * setDateSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setDateSlice({ dateType: "single", startDate: new Date(), endDate: null, allDay: false, startTime: { hour: "12", minute: "00" }, endTime: { hour: "12", minute: "00" }, dateWhenVisible: new Date(), checkedDays: ["monday"] })
   *
   * @returns void
   */
  setDateSlice: (partials: Partial<DateSlice>) => void;
  /**
   * set the date type
   * @param dateType
   *
   * @example
   * setDateType("single")
   * setDateType("dateRange")
   * setDateType("recurring")
   *
   * @returns void
   */
  setDateType: (dateType: TDateType) => void;
  /**
   * Set the start date
   *
   * @param startDate
   *
   * @example
   * setStartDate(new Date())
   * setStartDate(null)
   *
   * @returns void
   */
  setStartDate: (startDate: Date | null) => void;
  /**
   * Set the end date
   *
   * @param endDate
   *
   * @example
   * setEndDate(new Date())
   * setEndDate(null)
   *
   * @returns void
   */
  setEndDate: (endDate: Date | null) => void;
  /**
   * Set the all day flag
   *
   * @param allDay
   *
   * @example
   * setAllDay(true)
   * setAllDay(false)
   *
   * @returns void
   */
  setAllDay: (allDay: boolean) => void;
  /**
   * Set the start time
   *
   * @param startTime
   *
   * @example
   * setStartTime({ hour: "12", minute: "00" })
   * setStartTime({ hour: "00", minute: "00" })
   *
   * @returns void
   */
  setStartTime: (startTime: TTime) => void;
  /**
   * Set the end time
   *
   * @param endTime
   *
   * @example
   * setEndTime({ hour: "12", minute: "00" })
   * setEndTime({ hour: "00", minute: "00" })
   *
   * @returns void
   */
  setEndTime: (endTime: TTime) => void;
  /**
   * Set the date when visible
   *
   * @param dateWhenVisible
   *
   * @example
   * setDateWhenVisible(new Date())
   * setDateWhenVisible(null)
   *
   * @returns void
   */
  setDateWhenVisible: (dateWhenVisible: Date | null) => void;
  /**
   * Set various dates
   *
   * @param various
   *
   * @example
   * setVarious([{ date: new Date(), time: { hour: "12", minute: "00" }, all_day: false }])
   *
   * @returns void
   */
  setVarious: (various: IDate[]) => void;
  /**
   * Check a day
   *
   * @param day
   *
   * @example
   * checkDay("monday")
   * checkDay("tuesday")
   *
   * @returns void
   */
  checkDay: (day: TCheckedDays) => void;
  /**
   * Uncheck a day
   *
   * @param day
   *
   * @example
   * uncheckDay("monday")
   * uncheckDay("tuesday")
   *
   * @returns void
   */
  uncheckDay: (day: TCheckedDays) => void;
  /**
   * Validate the date slice
   */
  validateDates: () => boolean;
  /**
   * Reset the date slice
   */
  resetDateSlice: () => void;

  // allData: () => {
  //   dataType: TDateType;
  //   startDate: string | null;
  //   endDate: string | null;
  //   allDay: boolean;
  //   startTime: TTime;
  //   endTime: TTime;
  //   dateWhenVisible: string | null;
  //   checkedDays: TCheckedDays[] | null;
  // };
}

/**
 * Initial state
 */
const INITIAL_STATES = () => ({
  dateType: null,
  startDate: null,
  endDate: null,
  allDay: false,
  startTime: {
    hour: null,
    minute: null,
  },
  endTime: {
    hour: null,
    minute: null,
  },
  dateWhenVisible: null,
  checkedDays: [],

  various: [
    {
      date: null,
      time: {
        start: {
          hour: null,
          minute: null,
        },
        end: {
          hour: null,
          minute: null,
        },
      },
      allDay: false,
    },
  ],

  datesValid: true,
});

/**
 * Create a date slice
 */
export const createDateSlice: StateCreator<DateSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters
   */

  setDateSlice: (partials) => set({ ...partials }),

  setDateType: (dateType) => {
    set({ dateType });

    if (dateType === "single") {
      set({ endDate: null });
    }
    if (dateType !== "recurring") {
      set({ checkedDays: [] });
    }
  },
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setDateWhenVisible: (dateWhenVisible) => set({ dateWhenVisible }),
  setAllDay: (allDay) => {
    set({ allDay });
    set({ startTime: { hour: allDay ? "00" : null, minute: allDay ? "00" : null } });
    set({ endTime: { hour: allDay ? "24" : null, minute: allDay ? "00" : null } });
  },
  setStartTime: (startTime) => {
    set({ startTime });
    if (!startTime.minute) {
      set({ startTime: { ...startTime, minute: "00" } });
    }
  },
  setEndTime: (endTime) => {
    set({ endTime });
    if (!endTime.minute) {
      set({ endTime: { ...endTime, minute: "00" } });
    }
  },
  setVarious: (various) => set({ various }),
  checkDay: (day) =>
    set((state) => ({
      checkedDays: [...state.checkedDays, day],
    })),
  uncheckDay: (day) =>
    set((state) => ({
      checkedDays: state.checkedDays.filter((d) => d !== day),
    })),
  validateDates: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, date_object } = ValidateDates({
      dateType: state.dateType,
      startDate: state.startDate,
      endDate: state.endDate,
      startTime: state.startTime,
      endTime: state.endTime,
      allDay: state.allDay,
      various: state.various,
      checkedDays: [...state.checkedDays],
    });
    const { setError } = whatson_store.getState();
    setError("Date(s)", valid);
    set(() => {
      return {
        datesValid: valid,
        ...date_object,
      };
    });
    return valid;
  },
  resetDateSlice: () => {
    set(() => ({ ...INITIAL_STATES() }));
  },
  // Getters
  // allData: () => {
  //   const formatDate = (date: Date | null) => {
  //     if (date === null) return null;
  //     // Format the date as YYYY-MM-DD
  //     return date.toISOString().split('T')[0];
  //   };

  //   return {
  //     dataType: get().dateType,
  //     startDate: formatDate(get().startDate),
  //     endDate: formatDate(get().endDate),
  //     allDay: get().allDay,
  //     startTime: get().startTime,
  //     endTime: get().endTime,
  //     dateWhenVisible: formatDate(get().dateWhenVisible),
  //     checkedDays: get().checkedDays,
  //   }
  // },
});
