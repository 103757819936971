import { IDate, TCheckedDays, TDateType, TTime } from "./DateStore";

interface IDateValidationObject {
  dateType: TDateType;
  startDate: Date | null;
  endDate: Date | null;
  startTime: TTime;
  endTime: TTime;
  allDay: boolean;
  various: IDate[];
  checkedDays: TCheckedDays[];
}

export function ValidateDates(date_object: IDateValidationObject): {
  valid: boolean;
  date_object: IDateValidationObject;
} {
  let new_date_object = { ...date_object };
  let valid = true;

  if (date_object.dateType === null) {
    valid = false;
  }

  /* Handle single */
  if (date_object.dateType === "single") {
    if (date_object.startDate === null) {
      valid = false;
    }
    if (!date_object.allDay) {
      if (date_object.startTime.hour === null || date_object.startTime.minute === null) {
        valid = false;
      }
    }
  }

  /* Handle date range */
  if (date_object.dateType === "dateRange") {
    if (date_object.startDate === null || date_object.endDate === null) {
      valid = false;
    }
    if (!date_object.allDay) {
      if (date_object.startTime.hour === null || date_object.startTime.minute === null) {
        valid = false;
      }
      if (date_object.endTime.hour === null || date_object.endTime.minute === null) {
        valid = false;
      }
    }
  }

  /* Handle recurring */
  if (date_object.dateType === "recurring") {
    if (date_object.startDate === null) {
      valid = false;
    }
    if (!date_object.allDay) {
      if (date_object.startTime.hour === null || date_object.startTime.minute === null) {
        valid = false;
      }
      if (date_object.endTime.hour === null || date_object.endTime.minute === null) {
        valid = false;
      }
    }
    if (date_object.checkedDays.length === 0) {
      valid = false;
    }
  }

  if (date_object.dateType === "various") {
    date_object.various.forEach((date) => {
      if (date.date === null) {
        valid = false;
      }
      if (!date.allDay) {
        if (date.time.start.hour === null || date.time.start.minute === null) {
          valid = false;
        }
        if (date.time.end.hour === null || date.time.end.minute === null) {
          valid = false;
        }
      }
    });
  }

  return {
    valid: valid,
    date_object: { ...new_date_object },
  };
}
